export const PROJECTS_GET_LIST = 'PROJECTS_GET_LIST';
export const PROJECTS_GET_LIST_SUCCESS = 'PROJECTS_GET_LIST_SUCCESS';
export const PROJECTS_GET_LIST_ERROR = 'PROJECTS_GET_LIST_ERROR';
export const PROJECTS_GET_LIST_WITH_FILTER = 'PROJECTS_GET_LIST_WITH_FILTER';
export const PROJECTS_GET_LIST_WITH_ORDER = 'PROJECTS_GET_LIST_WITH_ORDER';
export const PROJECTS_GET_LIST_SEARCH = 'PROJECTS_GET_LIST_SEARCH';


export const PROJECTS_DELETE_ITEMS = 'PROJECTS_DELETE_ITEMS';
export const PROJECTS_DELETE_ITEMS_SUCCESS = 'PROJECTS_DELETE_ITEMS_SUCCESS';
export const PROJECTS_DELETE_ITEMS_ERROR = 'PROJECTS_DELETE_ITEMS_ERROR';


export const PROJECTS_SELECTED_ITEMS_CHANGE = 'PROJECTS_SELECTED_ITEMS_CHANGE';
