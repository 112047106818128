import axios from "axios";
import { servicePath } from 'constants/defaultValues';
import { getAccessToken } from 'helpers/Firebase';
import { getCurrentUser } from 'helpers/Utils';
import moment from 'moment';
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    NOTIFICATION_FETCH_ITEM,
    NOTIFICATION_ADD_ITEM,
    NOTIFICATION_DELETE_ITEM
} from './actions_def';
import {
    getNotificationItemSuccess,
    getNotificationItemError,
    addNotificationItemSuccess,
    addNotificationItemError,
    deleteNotificationItemSuccess,
    deleteNotificationItemError


} from './actions';


const apiUrl = servicePath;


const getNotificationItemRequest = async () => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success) => {
        const instance = axios.create({
            method: 'get',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });
        instance.get('/notifications/', { crossdomain: true })
            .then(response => success(response.data ?
                response.data.map(item => (
                    {
                        id: item.rfc_id,
                        title: item.rfc_title,
                        date: moment(item.date_created).format("Do MMM YYYY"),
                        change_date: moment(item.date_modified).format("Do MMM YYYY (hh:mm)"),
                        end_date: moment(item.rfc_implementation_end_date).format("Do MMM YYYY (hh:mm)"),
                        status: item.rfc_status,
                        statusColor: 'primary'
                    }
                )
                )
                :
                []));
    })
        .then((response) => response)
        .catch((error) => error);
};

function* getNotificationItem() {
    try {
        const response = yield call(getNotificationItemRequest);
        yield put(getNotificationItemSuccess(response));
    } catch (error) {
        yield put(getNotificationItemError(error));
    }
}


const addNotificationItemRequest = async (item) => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success, fail) => {

        const instance = axios.create({
            method: 'post',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });


        instance.post('/notifications/',
            item,
            { crossdomain: true })
            .then(response => {
                if (response.data) {
                    if (response.data.status === "fail") {
                        fail(response.data);
                    }
                    else if (response.data.status === "success") {
                        success(response.data);
                    }
                    else {
                        console.log('Inconsistent behaviour', response.data.status)
                    }
                }
            }).catch((error) => {
                console.log(error)
            });;
    })
        .then((response) => response)
        .catch((error) => error);
};

function* addNotificationItem({ payload }) {
    console.log('addNotificationItem')

    try {
        const response = yield call(addNotificationItemRequest, payload);
        console.log('Response :', response, response.status === "fail")
        if (response.status !== "fail") {
            yield put(addNotificationItemSuccess(response));
        }
        else {
            yield put(addNotificationItemError(response));
        }
    } catch (error) {
        yield put(addNotificationItemError(error));
    }
}


const deleteNotificationRequest = async (item) => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success, fail) => {
        console.log(item)
        const instance = axios.create({
            method: 'delete',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });

        instance.delete('/notifications/' + item + '/',
            { crossdomain: true })
            .then(response => {
                if (response.data) {
                    if (response.data.status === "fail") {
                        fail(response.data);
                    }
                    else if (response.data.status === "success" || response.data.status === "ok") {
                        success(response.data);
                    }
                    else {
                        console.log('Inconsistent behaviour', response.data.status);
                    }
                }
            }).catch((error) => {
                console.log(error);
            });
    })
        .then((response) => response)
        .catch((error) => error);
};

function* deleteNotificationItem({ payload }) {
    // console.log(payload);
    try {
        const response = yield call(deleteNotificationRequest, payload);
        console.log('Response :', response, response.status === "fail")
        if (response.status !== "fail") {
            yield put(deleteNotificationItemSuccess(response));
        }
        else {
            yield put(deleteNotificationItemError(response));
        }
    } catch (error) {
        yield put(addNotificationItemError(error));
    }
}

// export function* watchResetItem() {
//     yield takeLatest(NOTIFICATION_RESET_ITEM, resetNotificationItem);
// }
export function* watchFetchItem() {
    yield takeLatest(NOTIFICATION_FETCH_ITEM, getNotificationItem);
}

export function* watchAddItem() {
    yield takeLatest(NOTIFICATION_ADD_ITEM, addNotificationItem);
}
export function* watchDeleteItem() {
    yield takeLatest(NOTIFICATION_DELETE_ITEM, deleteNotificationItem);
}


export default function* rootSaga() {
    yield all([
        // fork(watchResetItem),
        fork(watchAddItem),
        fork(watchFetchItem),
        fork(watchDeleteItem),
    ]);
}
