import axios from "axios";
import { servicePath, UserRole } from 'constants/defaultValues';
import firebase from 'firebase/app';
import { getAccessToken } from 'helpers/Firebase';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { adminRoot, currentUser } from '../../constants/defaultValues';
import { auth } from '../../helpers/Firebase';
import { getCurrentUser, setCurrentUser } from '../../helpers/Utils';
import {
    RESET_ACTIONS,
    FORGOT_PASSWORD, LOGIN_USER,
    LOGIN_USER_PROVIDER,
    LOGOUT_USER, REGISTER_USER,
    RESET_PASSWORD,
    FETCH_USER_PROFILE,
    FETCH_LOCAL_USER_PROFILE,
    SET_USER_PROFILE,
    SAVE_USER_PROFILE,
    CREATE_USER_PROFILE,
    DELETE_USER_PROFILE
} from './actions_def';
import {
    fetchUserProfileError,
    fetchUserProfileSuccess,
    fetchLocalUserProfileError,
    fetchLocalUserProfileSuccess,
    forgotPasswordError, forgotPasswordSuccess, loginUserError, loginUserSuccess,
    registerUserError, registerUserSuccess,
    resetPasswordError, resetPasswordSuccess,
    saveUserProfileError, saveUserProfileSuccess,
    createUserProfileError, createUserProfileSuccess,
    deleteUserProfileError, deleteUserProfileSuccess,
    setUserProfileError, setUserProfileSuccess
} from './actions';


const apiUrl = servicePath;

const loginWithEmailPasswordAsync = async (email, password) =>
    // eslint-disable-next-line no-return-await
    await auth
        .signInWithEmailAndPassword(email, password)
        .then((user) => user)
        .catch((error) => error);

function* loginWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload;
    try {
        const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
        if (!loginUser.message) {
            const item = { uid: loginUser.user.uid, ...currentUser };
            setCurrentUser(item);
            yield put(loginUserSuccess(item));
            history.push(adminRoot);
        } else {
            yield put(loginUserError(loginUser.message));
        }
    } catch (error) {
        yield put(loginUserError(error));
    }
}

const loginWithProviderAsync = async (provider) => {
    var g_provider = new firebase.auth.GoogleAuthProvider();
    g_provider.addScope('profile');
    g_provider.addScope('email');

    return await auth.signInWithPopup(g_provider).then(function (result) {
        // var token = result.credential.accessToken;
        // var user = result.user;
        // // // console.log(token, user);
        // fetchUserProfile()

        return result;
    });
}

function* loginWithAuthProvider({ payload }) {
    const { provider } = payload;
    const { history } = payload;
    try {
        const loginUser = yield call(loginWithProviderAsync, provider);

        if (!loginUser.message) {
            const item =
            {
                ...getCurrentUser(),
                uid: loginUser.user.uid,
                role: UserRole.Guest,
                accessToken: loginUser.user._lat,
                expirationTime: loginUser.user._lat,
            };

            if (loginUser.operationType === 'signin') {
                const response = yield call(fetchUserProfileRequest, item);
                console.log('fetchUserProfile :', response, response.status === "fail");
                yield put(fetchUserProfileSuccess(response));
            }

            // // console.log('storing', item)

            setCurrentUser(item);
            yield put(loginUserSuccess(item));
            history.push(adminRoot);

        } else {
            // catch throw
            // // console.log('login failed :', loginUser.message)
        }
    } catch (error) {
        // catch throw
        // // console.log('login error : ', error)
    }
}


const getHeader = async (user) => {
    return axios.create({
        method: 'get',
        baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
        timeout: 10000,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.accessToken
        },
    });
};

const fetchUserProfileRequest = async (user) => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success, fail) => {
        // // console.log(item)
        const instance = axios.create({
            method: 'get',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.accessToken
            },
        });

        instance.interceptors.response.use(response => {
            return response;
        }, error => {
            if (error.response) {
                if (error.response.status === 401) {
                    // console.log("Token failed - renewing");
                    firebase.auth().onAuthStateChanged(user => {
                        // console.log(user);
                        setCurrentUser(
                            {
                                ...getCurrentUser(),
                                accessToken: user._lat,
                                role: UserRole.Editor,
                                isLoaded: true
                            });
                        const instance = getHeader(user);

                        instance.get('/users/fetch_own_profile/',
                            { crossdomain: true })
                            .then(response => {
                                if (response.data) {
                                    success(response.data);
                                }
                                else {
                                    // // console.log('invalid response', response.data);
                                    fail(response.data);
                                }
                            }).catch((error) => {
                                // console.log(error);
                                fail(error);
                            });
                    });
                }
                if (error.response.status === 403) {
                    // console.log("Not authorized against backend");
                    firebase.auth().onAuthStateChanged(user => {
                        // console.log(user);
                        setCurrentUser(
                            {
                                ...getCurrentUser(),
                                accessToken: user._lat,
                                role: UserRole.Editor,
                                isLoaded: true
                            });
                        const instance = getHeader(user);

                        instance.get('/users/fetch_own_profile/',
                            { crossdomain: true })
                            .then(response => {
                                if (response.data) {
                                    success(response.data);
                                }
                                else {
                                    // // console.log('invalid response', response.data);
                                    fail(response.data);
                                }
                            }).catch((error) => {
                                // console.log(error);
                                fail(error);
                            });
                    });
                    fail(error);
                }
                if (error.response.status === 404) {
                    // console.log("User not found - please register");
                    var _user = {
                        ...getCurrentUser(),
                        accessToken: user._lat,
                        role: UserRole.Guest,
                        isLoaded: true
                    };
                    setCurrentUser(_user);
                    success(_user);

                }
                if (error.response.status === 500) {
                    // console.log("Error in backend");
                    setCurrentUser(
                        {
                            ...getCurrentUser(),
                            isLoaded: true,
                            hasError: true
                        });

                    fail(error);
                }
            }

        });


        instance.get('/users/fetch_own_profile/',
            { crossdomain: true })
            .then(response => {
                if (response && response.data) {
                    success(response.data);
                }
                else {
                    // // console.log('invalid response', response.data);
                    fail(response);
                }
            }).catch((error) => {
                // console.log(error);
                fail(error);
            });
    })
        .then((response) => response)
        .catch((error) => {
            // console.log(error);
            return error;
        });
};

function* fetchUserProfile({ payload }) {
    // console.log('fetchUserProfile :', payload);
    try {
        const response = yield call(fetchUserProfileRequest, payload.user);
        // console.log('fetchUserProfile :', payload, response);
        if (String(response).startsWith('Error')) {
            yield put(fetchUserProfileError(response));
        }
        else {
            yield put(fetchUserProfileSuccess(response));
        }
    } catch (error) {
        yield put(fetchUserProfileError(error));
    }
}

function* fetchLocalUserProfile() {
    try {
        const _user = getCurrentUser();
        // console.log('fetchLocalUserProfile :', _user);
        yield put(fetchLocalUserProfileSuccess(_user));
    } catch (error) {
        yield put(fetchLocalUserProfileError(error));
    }
}


function* setUserProfile(user) {
    // // console.log(user);
    try {
        yield put(setUserProfileSuccess(user));
    } catch (error) {
        yield put(setUserProfileError(error));
    }
}


const saveUserProfileRequest = async (item) => {
    // // console.log(item);
    // eslint-disable-next-line no-return-await
    return await new Promise((success, fail) => {
        var _user = JSON.parse(localStorage.getItem("user"));
        const instance = axios.create({
            method: 'post',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });

        instance.interceptors.response.use(response => {
            return response;
        }, error => {
            if (error.response) {
                if (error.response.status === 401) {
                    // console.log("Token failed - renewing");
                    firebase.auth().onAuthStateChanged(user => {
                        // console.log(user);
                        setCurrentUser(
                            {
                                ...getCurrentUser(),
                                accessToken: user._lat,
                                role: UserRole.Editor,
                                isLoaded: true
                            });
                        const instance = getHeader(user);

                        instance.get('/users/fetch_own_profile/',
                            { crossdomain: true })
                            .then(response => {
                                if (response.data) {
                                    success(response.data);
                                }
                                else {
                                    // // console.log('invalid response', response.data);
                                    fail(response.data);
                                }
                            }).catch((error) => {
                                // console.log(error);
                                fail(error);
                            });
                    });
                }
                if (error.response.status === 403) {
                    // console.log("Not authorized against backend");
                    firebase.auth().onAuthStateChanged(user => {
                        // console.log(user);
                        setCurrentUser(
                            {
                                ...getCurrentUser(),
                                accessToken: user._lat,
                                role: UserRole.Editor,
                                isLoaded: true
                            });
                        const instance = getHeader(user);

                        instance.get('/users/fetch_own_profile/',
                            { crossdomain: true })
                            .then(response => {
                                if (response.data) {
                                    success(response.data);
                                }
                                else {
                                    // // console.log('invalid response', response.data);
                                    fail(response.data);
                                }
                            }).catch((error) => {
                                // console.log(error);
                                fail(error);
                            });
                    });
                }
                if (error.response.status === 500) {
                    // console.log("Error in backend");
                    setCurrentUser(
                        {
                            ...getCurrentUser(),
                            isLoaded: true,
                            hasError: true
                        });
                }
            }
            fail(error);
        });



        // // console.log(item)
        let _sendingPayload = {
            displayName: item.name,
            email: item.email,
            avatar: item.photoURL,
            cover: item.cover
        };

        // // console.log(_sendingPayload);
        instance.put('/save_own_profile/',
            _sendingPayload,
            { crossdomain: true })
            .then(response => {
                // // console.log(response);
                if (response.data) {
                    if (response.status === "fail") {
                        fail(response.data);
                    }
                    else {
                        success(response.data);
                    }

                }
            }).catch((error) => {
                // // console.log(error)
                fail(error);
            });;
    })
        .then((response) => response)
        .catch((error) => error);
};

function* saveUserProfile({ payload }) {
    try {
        // // // // console.log(payload);;

        const response = yield call(saveUserProfileRequest, payload.user);
        // console.log('saveUserProfile :', payload);
        // console.log('saveUserProfile :', response);

        if (String(response).startsWith('Error')) {
            // console.log("saveUserProfileError")

            yield put(saveUserProfileError(response));
        }
        else {
            yield put(saveUserProfileSuccess(response));
        }
    } catch (error) {
        yield put(saveUserProfileError(error));
    }
}


const createUserProfileRequest = async (item) => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success, fail) => {

        const instance = axios.create({
            method: 'post',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });

        instance.interceptors.response.use(response => {
            return response;
        }, error => {
            if (error.response) {
                if (error.response.status === 401 || error.response.status === 403) {
                    // // console.log("Not authorized against backend");
                    firebase.auth().onAuthStateChanged(user => {
                        setCurrentUser(
                            {
                                ...user,
                                isLoaded: true,
                                hasError: true
                            });
                    });
                }
                if (error.response.status === 500) {
                    // // console.log("Error in backend");
                    setCurrentUser(
                        {
                            ...getCurrentUser(),
                            isLoaded: true,
                            hasError: true
                        });
                }
            }
            fail(error);
        });



        // // console.log(item)
        let _sendingPayload = {
            // data: {
            displayName: item.displayName,
            email: item.email,
            avatar: item.photoURL,
            cover: item.cover
            // }
        };

        console.log(_sendingPayload);
        instance.post('/users/create_own_profile/',
            _sendingPayload,
            { crossdomain: true })
            .then(response => {
                // // console.log(response);
                if (response.data) {
                    if (response.status === "fail") {
                        fail(response.data);
                    }
                    else {
                        success(response.data);
                    }

                }
            }).catch((error) => {
                // // console.log(error)
                fail(error);
            });;
    })
        .then((response) => response)
        .catch((error) => error);
};

function* createUserProfile({ payload }) {
    try {
        // // console.log(payload);;

        const response = yield call(createUserProfileRequest, payload.user);
        // console.log('createUserProfile :', payload);
        // console.log('createUserProfile :', response);

        if (String(response).startsWith('Error')) {
            // console.log("createUserProfileError")

            yield put(createUserProfileError(response));
        }
        else {
            yield put(createUserProfileSuccess(response));
        }
    } catch (error) {
        yield put(createUserProfileError(error));
    }
}




const deleteUserProfileRequest = async (item) => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success, fail) => {

        const instance = axios.create({
            method: 'delete',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });

        instance.interceptors.response.use(response => {
            return response;
        }, error => {
            if (error.response) {
                if (error.response.status === 500) {
                    setCurrentUser(
                        {
                            ...getCurrentUser(),
                            isLoaded: true,
                            hasError: true
                        });
                }
            }
            fail(error);
        });

        instance.delete('/users/delete_own_profile/')
            .then(response => {
                // // console.log(response);
                if (response.data) {
                    if (response.status === "fail") {
                        fail(response.data);
                    }
                    else {
                        success(response.data);
                    }

                }
            }).catch((error) => {
                // // console.log(error)
                fail(error);
            });;
    })
        .then((response) => response)
        .catch((error) => error);
};

function* deleteUserProfile({ payload }) {
    try {
        const response = yield call(deleteUserProfileRequest, payload.user);

        if (String(response).startsWith('Error')) {
            yield put(deleteUserProfileError(response));
        }
        else {
            yield put(deleteUserProfileSuccess(response));
        }
    } catch (error) {
        yield put(deleteUserProfileError(error));
    }
}





const registerWithEmailPasswordAsync = async (email, password) =>
    // eslint-disable-next-line no-return-await
    await auth
        .createUserWithEmailAndPassword(email, password)
        .then((user) => user)
        .catch((error) => error);

function* registerWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload;
    try {
        const registerUser = yield call(
            registerWithEmailPasswordAsync,
            email,
            password
        );
        if (!registerUser.message) {
            const item = { uid: registerUser.user.uid, ...currentUser };
            // // console.log('storing', item)

            setCurrentUser(item);
            yield put(registerUserSuccess(item));
            history.push(adminRoot);
        } else {
            yield put(registerUserError(registerUser.message));
        }
    } catch (error) {
        yield put(registerUserError(error));
    }
}

const logoutAsync = async (history) => {
    // // console.log('Logging out')
    await auth
        .signOut()
        .then((user) => user)
        .catch((error) => error);
    history.push(adminRoot);
};

function* logout({ payload }) {
    const { history } = payload;
    // // console.log('storing', 'item')

    setCurrentUser();
    yield call(logoutAsync, history);
}

const forgotPasswordAsync = async (email) => {
    // eslint-disable-next-line no-return-await
    return await auth
        .sendPasswordResetEmail(email)
        .then((user) => user)
        .catch((error) => error);
};

function* forgotPassword({ payload }) {
    const { email } = payload.forgotUserMail;
    try {
        const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
        if (!forgotPasswordStatus) {
            yield put(forgotPasswordSuccess('success'));
        } else {
            yield put(forgotPasswordError(forgotPasswordStatus.message));
        }
    } catch (error) {
        yield put(forgotPasswordError(error));
    }
}

export function* watchLoginUser() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchLoginUserWithProvider() {
    yield takeEvery(LOGIN_USER_PROVIDER, loginWithAuthProvider);
}
export function* watchRegisterUser() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

export function* watchLogoutUser() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchForgotPassword() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

export function* watchFetchUserProfile() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(FETCH_USER_PROFILE, fetchUserProfile);
}

export function* watchFetchLocalUserProfile() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(FETCH_LOCAL_USER_PROFILE, fetchLocalUserProfile);
}

export function* watchSetUserProfile() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(SET_USER_PROFILE, setUserProfile);
}

export function* watchSaveUserProfile() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(SAVE_USER_PROFILE, saveUserProfile);
}

export function* watchCreateUserProfile() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(CREATE_USER_PROFILE, createUserProfile);
}
export function* watchDeleteUserProfile() {
    yield takeEvery(DELETE_USER_PROFILE, deleteUserProfile);
}

export function* watchResetPassword() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(RESET_PASSWORD, resetPassword);
}
export function* watchResetActions() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(RESET_ACTIONS, resetActions);
}

function* resetActions() {
    yield console.log("Resetting actions");
}



const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
    // eslint-disable-next-line no-return-await
    return await auth
        .confirmPasswordReset(resetPasswordCode, newPassword)
        .then((user) => user)
        .catch((error) => error);
};

function* resetPassword({ payload }) {
    const { newPassword, resetPasswordCode } = payload;
    try {
        const resetPasswordStatus = yield call(
            resetPasswordAsync,
            resetPasswordCode,
            newPassword
        );
        if (!resetPasswordStatus) {
            yield put(resetPasswordSuccess('success'));
        } else {
            yield put(resetPasswordError(resetPasswordStatus.message));
        }
    } catch (error) {
        yield put(resetPasswordError(error));
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLoginUserWithProvider),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchForgotPassword),
        fork(watchResetPassword),
        fork(watchFetchUserProfile),
        fork(watchFetchLocalUserProfile),
        fork(watchSetUserProfile),
        fork(watchSaveUserProfile),
        fork(watchCreateUserProfile),
        fork(watchDeleteUserProfile)

    ]);
}
