import {
    EXPERTS_ADD_ITEM,
    EXPERTS_ADD_ITEM_SUCCESS,
    EXPERTS_ADD_ITEM_ERROR,
    EXPERTS_FETCH_ITEM,
    EXPERTS_FETCH_ITEM_SUCCESS,
    EXPERTS_FETCH_ITEM_ERROR,
    EXPERTS_DELETE_ITEM,
    EXPERTS_DELETE_ITEM_SUCCESS,
    EXPERTS_DELETE_ITEM_ERROR,
} from './actions_def';

import moment from 'moment'
const INIT_STATE = {
    expert: null,
    errors: [],
    filter: null,
    isLoaded: false,
    isExpertDetailSaving: false,

};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case EXPERTS_FETCH_ITEM:
            return { ...state, isLoaded: false };

        case EXPERTS_FETCH_ITEM_SUCCESS:
            return {
                ...state,
                isLoaded: true,
                allExpertsItems: action.payload,
                expert: action.payload,
            };

        case EXPERTS_FETCH_ITEM_ERROR:
            return { ...state, isLoaded: true, error: action.payload };

        case EXPERTS_ADD_ITEM:
            return { ...state, saving: true };

        case EXPERTS_ADD_ITEM_SUCCESS:
            // console.log("Success", [...state.allExpertsItems, {
            //   id: action.payload.instance.rfc_id,
            //   title: action.payload.instance.rfc_title,
            //   date: moment(action.payload.instance.date_created).format("Do MMM YYYY"),
            //   change_date: moment(action.payload.instance.date_modified).format("Do MMM YYYY (hh:mm)"),
            //   end_date: moment(action.payload.instance.rfc_implementation_end_date).format("Do MMM YYYY (hh:mm)"),
            //   status: action.payload.instance.rfc_status,
            //   statusColor: 'primary'
            // }])

            return {
                ...state,
                isLoaded: true,
                saving: false,
                errors: [],
                allExpertsItems: [...state.allExpertsItems, {
                    id: action.payload.instance.rfc_id,
                    title: action.payload.instance.rfc_title,
                    date: moment(action.payload.instance.date_created).format("Do MMM YYYY"),
                    change_date: moment(action.payload.instance.date_modified).format("Do MMM YYYY (hh:mm)"),
                    end_date: moment(action.payload.instance.rfc_implementation_end_date).format("Do MMM YYYY (hh:mm)"),
                    status: action.payload.instance.rfc_status,
                    statusColor: 'primary'
                }],
                expertsItems: [...state.expertsItems,
                {
                    id: action.payload.instance.rfc_id,
                    title: action.payload.instance.rfc_title,
                    date: moment(action.payload.instance.date_created).format("Do MMM YYYY"),
                    change_date: moment(action.payload.instance.date_modified).format("Do MMM YYYY (hh:mm)"),
                    end_date: moment(action.payload.instance.rfc_implementation_end_date).format("Do MMM YYYY (hh:mm)"),
                    status: action.payload.instance.rfc_status,
                    statusColor: 'primary'
                }],
            };

        case EXPERTS_ADD_ITEM_ERROR:
            console.log("Failed", action.payload)
            return {
                ...state,
                isLoaded: true,
                saving: false,
                errors: action.payload.errors && action.payload.errors.map(error => (
                    {
                        field: error.field,
                        error_code: error.code
                    }
                ))
            };

        case EXPERTS_DELETE_ITEM:
            return { ...state, saving: true };

        case EXPERTS_DELETE_ITEM_SUCCESS:
            console.log("Success", state.allExpertsItems.filter(item => item && item.id !== action.payload.rfc_id))

            return {
                ...state,
                isLoaded: true,
                saving: false,
                saved: true,
                errors: [],

                allExpertsItems: state.allExpertsItems.filter(item => item && item.id !== action.payload.rfc_id),
                expertsItems: state.expertsItems.filter(item => item && item.id !== action.payload.rfc_id),
            };

        case EXPERTS_DELETE_ITEM_ERROR:
            console.log("Failed", action.payload)
            return {
                ...state,
                isLoaded: false,
                saving: false,
                saved: true,
                errors: action.payload.errors.map(error => (
                    {
                        field: error.field,
                        error_code: error.code
                    }
                ))
            };

        default:
            return { ...state };
    }
};
