
import { combineReducers } from 'redux';
import details from './details/reducer';
import list from './list/reducer';


const reducers = combineReducers({
    details,
    list
});

export default reducers;