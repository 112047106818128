import axios from "axios";
import { servicePath } from 'constants/defaultValues';
import { getAccessToken } from 'helpers/Firebase';
import { getCurrentUser } from 'helpers/Utils';
import moment from 'moment';
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    WORK_FETCH_ITEM,
    WORK_ADD_ITEM,
    WORK_DELETE_ITEM
} from './actions_def';
import {
    getWorkItemSuccess,
    getWorkItemError,
    addWorkItemSuccess,
    addWorkItemError,
    deleteWorkItemSuccess,
    deleteWorkItemError


} from './actions';


const apiUrl = servicePath;


const getWorkItemRequest = async () => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success) => {
        const instance = axios.create({
            method: 'get',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });
        instance.get('/work/', { crossdomain: true })
            .then(response => success(response.data ?
                response.data.map(item => (
                    {
                        id: item.rfc_id,
                        title: item.rfc_title,
                        date: moment(item.date_created).format("Do MMM YYYY"),
                        change_date: moment(item.date_modified).format("Do MMM YYYY (hh:mm)"),
                        end_date: moment(item.rfc_implementation_end_date).format("Do MMM YYYY (hh:mm)"),
                        status: item.rfc_status,
                        statusColor: 'primary'
                    }
                )
                )
                :
                []));
    })
        .then((response) => response)
        .catch((error) => error);
};

function* getWorkItem() {
    try {
        const response = yield call(getWorkItemRequest);
        yield put(getWorkItemSuccess(response));
    } catch (error) {
        yield put(getWorkItemError(error));
    }
}


const addWorkItemRequest = async (item) => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success, fail) => {

        const instance = axios.create({
            method: 'post',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });


        instance.post('/work/',
            item,
            { crossdomain: true })
            .then(response => {
                if (response.data) {
                    if (response.data.status === "fail") {
                        fail(response.data);
                    }
                    else if (response.data.status === "success") {
                        success(response.data);
                    }
                    else {
                        console.log('Inconsistent behaviour', response.data.status)
                    }
                }
            }).catch((error) => {
                console.log(error)
            });;
    })
        .then((response) => response)
        .catch((error) => error);
};

function* addWorkItem({ payload }) {
    console.log('addWorkItem')

    try {
        const response = yield call(addWorkItemRequest, payload);
        console.log('Response :', response, response.status === "fail")
        if (response.status !== "fail") {
            yield put(addWorkItemSuccess(response));
        }
        else {
            yield put(addWorkItemError(response));
        }
    } catch (error) {
        yield put(addWorkItemError(error));
    }
}


const deleteWorkRequest = async (item) => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success, fail) => {
        console.log(item)
        const instance = axios.create({
            method: 'delete',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });

        instance.delete('/work/' + item + '/',
            { crossdomain: true })
            .then(response => {
                if (response.data) {
                    if (response.data.status === "fail") {
                        fail(response.data);
                    }
                    else if (response.data.status === "success" || response.data.status === "ok") {
                        success(response.data);
                    }
                    else {
                        console.log('Inconsistent behaviour', response.data.status);
                    }
                }
            }).catch((error) => {
                console.log(error);
            });
    })
        .then((response) => response)
        .catch((error) => error);
};

function* deleteWorkItem({ payload }) {
    // console.log(payload);
    try {
        const response = yield call(deleteWorkRequest, payload);
        console.log('Response :', response, response.status === "fail")
        if (response.status !== "fail") {
            yield put(deleteWorkItemSuccess(response));
        }
        else {
            yield put(deleteWorkItemError(response));
        }
    } catch (error) {
        yield put(addWorkItemError(error));
    }
}

// export function* watchResetItem() {
//     yield takeLatest(WORK_RESET_ITEM, resetWorkItem);
// }
export function* watchFetchItem() {
    yield takeLatest(WORK_FETCH_ITEM, getWorkItem);
}

export function* watchAddItem() {
    yield takeLatest(WORK_ADD_ITEM, addWorkItem);
}
export function* watchDeleteItem() {
    yield takeLatest(WORK_DELETE_ITEM, deleteWorkItem);
}


export default function* rootSaga() {
    yield all([
        // fork(watchResetItem),
        fork(watchAddItem),
        fork(watchFetchItem),
        fork(watchDeleteItem),
    ]);
}
