// eslint-disable-next-line import/no-cycle
import {
  PROJECTS_GET_STATUSES_LIST,
  PROJECTS_GET_STATUSES_LIST_SUCCESS,
  PROJECTS_GET_STATUSES_LIST_ERROR,
} from './actions_def';

export const getProjectsStatusesList = () => ({
  type: PROJECTS_GET_STATUSES_LIST,
});

export const getProjectsStatusesListSuccess = (items) => ({
  type: PROJECTS_GET_STATUSES_LIST_SUCCESS,
  payload: items,
});

export const getProjectsStatusesListError = (error) => ({
  type: PROJECTS_GET_STATUSES_LIST_ERROR,
  payload: error,
});
