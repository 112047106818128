export const PROJECTS_RESET_ITEM = 'PROJECTS_RESET_ITEM';

export const PROJECTS_FETCH_ITEM = 'PROJECTS_FETCH_ITEM';
export const PROJECTS_FETCH_ITEM_SUCCESS = 'PROJECTS_FETCH_ITEM_SUCCESS';
export const PROJECTS_FETCH_ITEM_ERROR = 'PROJECTS_FETCH_ITEM_ERROR';

export const PROJECTS_ADD_ITEM = 'PROJECTS_ADD_ITEM';
export const PROJECTS_ADD_ITEM_SUCCESS = 'PROJECTS_ADD_ITEM_SUCCESS';
export const PROJECTS_ADD_ITEM_ERROR = 'PROJECTS_ADD_ITEM_ERROR';

export const PROJECTS_SAVE_ITEM = 'PROJECTS_SAVE_ITEM';
export const PROJECTS_SAVE_ITEM_SUCCESS = 'PROJECTS_SAVE_ITEM_SUCCESS';
export const PROJECTS_SAVE_ITEM_ERROR = 'PROJECTS_SAVE_ITEM_ERROR';

export const PROJECTS_CREATE_ITEM = 'PROJECTS_CREATE_ITEM';
export const PROJECTS_CREATE_ITEM_SUCCESS = 'PROJECTS_CREATE_ITEM_SUCCESS';
export const PROJECTS_CREATE_ITEM_ERROR = 'PROJECTS_CREATE_ITEM_ERROR';

export const PROJECTS_DELETE_ITEM = 'PROJECTS_DELETE_ITEM';
export const PROJECTS_DELETE_ITEM_SUCCESS = 'PROJECTS_DELETE_ITEM_SUCCESS';
export const PROJECTS_DELETE_ITEM_ERROR = 'PROJECTS_DELETE_ITEM_ERROR';
