import {
    WORK_ADD_ITEM,
    WORK_RESET_ITEM,
    WORK_ADD_ITEM_SUCCESS,
    WORK_ADD_ITEM_ERROR,
    WORK_FETCH_ITEM,
    WORK_FETCH_ITEM_SUCCESS,
    WORK_FETCH_ITEM_ERROR,
    WORK_DELETE_ITEM,
    WORK_DELETE_ITEM_SUCCESS,
    WORK_DELETE_ITEM_ERROR,
    WORK_SAVE_ITEM,
    WORK_SAVE_ITEM_SUCCESS,
    WORK_SAVE_ITEM_ERROR,
} from './actions_def';

import moment from 'moment'
const INIT_STATE = {
    work: null,
    errors: [],
    isLoading: false,
    isLoaded: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case WORK_FETCH_ITEM:
            return { ...state, isLoading: false };

        case WORK_FETCH_ITEM_SUCCESS:
            return {
                ...state,
                isLoading: true,
                allWorkItems: action.payload,
                workItems: action.payload,
            };
        case WORK_RESET_ITEM:
            return {
                INIT_STATE
            };
        case WORK_FETCH_ITEM_ERROR:
            return { ...state, isLoading: true, error: action.payload };

        case WORK_ADD_ITEM:
            return { ...state, saving: true };

        case WORK_ADD_ITEM_SUCCESS:
            // console.log("Success", [...state.allWorkItems, {
            //   id: action.payload.instance.rfc_id,
            //   title: action.payload.instance.rfc_title,
            //   date: moment(action.payload.instance.date_created).format("Do MMM YYYY"),
            //   change_date: moment(action.payload.instance.date_modified).format("Do MMM YYYY (hh:mm)"),
            //   end_date: moment(action.payload.instance.rfc_implementation_end_date).format("Do MMM YYYY (hh:mm)"),
            //   status: action.payload.instance.rfc_status,
            //   statusColor: 'primary'
            // }])

            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                errors: [],
                work: action.payload.instance,
            };

        case WORK_ADD_ITEM_ERROR:
            console.log("Failed", action.payload)
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                errors: action.payload.errors && action.payload.errors.map(error => (
                    {
                        field: error.field,
                        error_code: error.code
                    }
                ))
            };

        case WORK_DELETE_ITEM:
            return { ...state, saving: true };

        case WORK_DELETE_ITEM_SUCCESS:
            console.log("Success", state.allWorkItems.filter(item => item && item.id !== action.payload.rfc_id))

            return {
                ...state,
                isLoading: true,
                saving: false,
                saved: true,
                errors: [],

                allWorkItems: state.allWorkItems.filter(item => item && item.id !== action.payload.rfc_id),
                workItems: state.workItems.filter(item => item && item.id !== action.payload.rfc_id),
            };

        case WORK_DELETE_ITEM_ERROR:
            console.log("Failed", action.payload)
            return {
                ...state,
                isLoading: false,
                saving: false,
                saved: true,
                errors: action.payload.errors.map(error => (
                    {
                        field: error.field,
                        error_code: error.code
                    }
                ))
            };


        case WORK_SAVE_ITEM_SUCCESS:
            // console.log("Success", [...state.allWorkItems, {
            //   id: action.payload.instance.rfc_id,
            //   title: action.payload.instance.rfc_title,
            //   date: moment(action.payload.instance.date_created).format("Do MMM YYYY"),
            //   change_date: moment(action.payload.instance.date_modified).format("Do MMM YYYY (hh:mm)"),
            //   end_date: moment(action.payload.instance.rfc_implementation_end_date).format("Do MMM YYYY (hh:mm)"),
            //   status: action.payload.instance.rfc_status,
            //   statusColor: 'primary'
            // }])

            return {
                ...state,
                isLoading: true,
                saving: false,
                errors: [],
                allWorkItems: [...state.allWorkItems, {
                    id: action.payload.instance.rfc_id,
                    title: action.payload.instance.rfc_title,
                    date: moment(action.payload.instance.date_created).format("Do MMM YYYY"),
                    change_date: moment(action.payload.instance.date_modified).format("Do MMM YYYY (hh:mm)"),
                    end_date: moment(action.payload.instance.rfc_implementation_end_date).format("Do MMM YYYY (hh:mm)"),
                    status: action.payload.instance.rfc_status,
                    statusColor: 'primary'
                }],
                workItems: [...state.workItems,
                {
                    id: action.payload.instance.rfc_id,
                    title: action.payload.instance.rfc_title,
                    date: moment(action.payload.instance.date_created).format("Do MMM YYYY"),
                    change_date: moment(action.payload.instance.date_modified).format("Do MMM YYYY (hh:mm)"),
                    end_date: moment(action.payload.instance.rfc_implementation_end_date).format("Do MMM YYYY (hh:mm)"),
                    status: action.payload.instance.rfc_status,
                    statusColor: 'primary'
                }],
            };

        case WORK_SAVE_ITEM_ERROR:
            console.log("Failed", action.payload)
            return {
                ...state,
                isLoading: true,
                saving: false,
                errors: action.payload.errors && action.payload.errors.map(error => (
                    {
                        field: error.field,
                        error_code: error.code
                    }
                ))
            };

        case WORK_SAVE_ITEM:
            return { ...state, saving: true };

        default:
            return { ...state };
    }
};
