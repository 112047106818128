import axios from "axios";
import { servicePath } from 'constants/defaultValues';
import { getAccessToken } from 'helpers/Firebase';
import { getCurrentUser } from 'helpers/Utils';
import moment from 'moment';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    SURVEYS_FETCH_ITEM,
    SURVEYS_ADD_ITEM,
    SURVEYS_DELETE_ITEM
} from './actions_def';
import {
    getSurveyItemSuccess,
    getSurveyItemError,
    addSurveyItemSuccess,
    addSurveyItemError,
    deleteSurveyItemSuccess,
    deleteSurveyItemError


} from './actions';


const apiUrl = servicePath;


const getSurveyItemRequest = async (payload) => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success) => {
        const instance = axios.create({
            method: 'get',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });
        instance.get('/projects/' + payload.projectId + '/surveys/' + payload.surveyId, { crossdomain: true })
            .then(response => success(response.data));
    })
        .then((response) => response)
        .catch((error) => error);
};

function* getSurveyItem({ payload }) {
    try {
        const response = yield call(getSurveyItemRequest, payload);
        yield put(getSurveyItemSuccess(response));
    } catch (error) {
        yield put(getSurveyItemError(error));
    }
}


const addSurveyItemRequest = async (item) => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success, fail) => {

        // success(notificationListData.data);
        var _user = getCurrentUser(); // JSON.parse(localStorage.getItem("user"));
        const instance = axios.create({
            method: 'post',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });

        // console.log('Sending :', {
        //   rfc_title: item.title,
        //   rfc_type: item.type ? item.type : 1,
        //   rfc_summary: item.detail ? item.detail : ' ',
        // })
        let _status = 1;
        switch (item.status) {
            case "PENDING": _status = 1; break;
            default: _status = 1;
        };

        // let _sendingPayload = {
        //     Title: item.Title,
        //     SurveyType: item.SurveyType ? item.SurveyType : 1,
        //     Description: item.Description ? item.Description : ' ',
        //     DueDate: item.DueDate ? item.DueDate : new Date(),
        //     ReleaseDate: item.ReleaseDate ? item.ReleaseDate : new Date(),
        //     Status: _status,
        //     CreatorUserName: item.CreatorUserName ? item.CreatorUserName : _user.email,
        //     AssignedUserName: item.AssignedUserName ? item.AssignedUserName : _user.email,
        //     OwnerUserName: item.OwnerUserName ? item.OwnerUserName : _user.email,
        //     ProjectId: item.ProjectId ? item.ProjectId : 0,
        //     CategoryId: item.CategoryId ? item.CategoryId : 0,
        //     MilestoneId: item.MilestoneId ? item.MilestoneId : 0,
        // };

        // console.log(_sendingPayload, getAccessToken());
        instance.post('/projects/' + item.projectId + '/surveys/',
            item,
            { crossdomain: true })
            .then(response => {
                if (response.data) {
                    if (response.status > 204) {
                        fail(response.data);
                    }
                    else if (response.status === 200 || response.status === 204) {
                        success(response.data);
                    }
                    else {
                        console.log('Inconsistent behaviour', response)
                    }
                }
            }).catch((error) => {
                console.log(error)
            });;
    })
        .then((response) => response)
        .catch((error) => error);
};

function* addSurveyItem({ payload }) {
    console.log(payload);
    try {
        const response = yield call(addSurveyItemRequest, payload);

        if (response.status !== "fail") {
            yield put(addSurveyItemSuccess(response));
        }
        else {
            yield put(addSurveyItemError(response));
        }

    } catch (error) {
        yield put(addSurveyItemError(error));
    }

}


const deleteSurveyRequest = async (item) => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success, fail) => {
        console.log(item)
        const instance = axios.create({
            method: 'delete',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });

        instance.delete('/surveys/' + item + '/',
            { crossdomain: true })
            .then(response => {
                if (response.data) {
                    if (response.data.status === "fail") {
                        fail(response.data);
                    }
                    else if (response.data.status === "success" || response.data.status === "ok") {
                        success(response.data);
                    }
                    else {
                        console.log('Inconsistent behaviour', response.data.status);
                    }
                }
            }).catch((error) => {
                console.log(error);
            });
    })
        .then((response) => response)
        .catch((error) => error);
};

function* deleteSurveyItem({ payload }) {
    // console.log(payload);
    try {
        const response = yield call(deleteSurveyRequest, payload);
        console.log('Response :', response, response.status === "fail")
        if (response.status !== "fail") {
            yield put(deleteSurveyItemSuccess(response));
        }
        else {
            yield put(deleteSurveyItemError(response));
        }
    } catch (error) {
        yield put(addSurveyItemError(error));
    }
}


export function* watchFetchItem() {
    yield takeEvery(SURVEYS_FETCH_ITEM, getSurveyItem);
}

export function* watchAddItem() {
    yield takeEvery(SURVEYS_ADD_ITEM, addSurveyItem);
}
export function* watchDeleteItem() {
    yield takeEvery(SURVEYS_DELETE_ITEM, deleteSurveyItem);
}


export default function* rootSaga() {
    yield all([
        fork(watchAddItem),
        fork(watchFetchItem),
        fork(watchDeleteItem),
    ]);
}
