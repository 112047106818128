import {
    TODOS_ADD_ITEM,
    TODOS_ADD_ITEM_SUCCESS,
    TODOS_ADD_ITEM_ERROR,
    TODOS_FETCH_ITEM,
    TODOS_FETCH_ITEM_SUCCESS,
    TODOS_FETCH_ITEM_ERROR,
    TODOS_DELETE_ITEM,
    TODOS_DELETE_ITEM_SUCCESS,
    TODOS_DELETE_ITEM_ERROR,
} from './actions_def';

import moment from 'moment'
const INIT_STATE = {
    allToDoItems: null,
    todosItems: [],
    errors: [],
    filter: null,
    searchKeyword: '',
    orderColumn: null,
    isLoaded: false,
    isLoading: false,
    hasErrors: false,
    labels: [
        { label: 'EDUCATION', color: 'secondary' },
        { label: 'NEW FRAMEWORK', color: 'primary' },
        { label: 'PERSONAL', color: 'info' },
    ],
    orderColumns: [
        { column: 'title', label: 'Title' },
        { column: 'category', label: 'Category' },
        { column: 'status', label: 'Status' },
        { column: 'label', label: 'Label' },
    ],
    categories: ['Flexbox', 'Sass', 'React'],
    selectedItems: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case TODOS_FETCH_ITEM:
            return { ...state, isLoading: false };

        case TODOS_FETCH_ITEM_SUCCESS:
            return {
                ...state,
                isLoading: true,
                allToDoItems: action.payload,
                todosItems: action.payload,
            };

        case TODOS_FETCH_ITEM_ERROR:
            return { ...state, isLoading: true, error: action.payload };

        case TODOS_ADD_ITEM:
            return {
                ...state,
                isLoaded: false,
                isLoading: true,
            };

        case TODOS_ADD_ITEM_SUCCESS:

            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                errors: [],
            };

        case TODOS_ADD_ITEM_ERROR:
            console.log("Failed", action.payload)
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                hasErrors: true,
                errors: action.payload.errors && action.payload.errors.map(error => (
                    {
                        field: error.field,
                        error_code: error.code
                    }
                ))
            };

        case TODOS_DELETE_ITEM:
            return { ...state, saving: true };

        case TODOS_DELETE_ITEM_SUCCESS:
            console.log("Success", state.allToDoItems.filter(item => item && item.id !== action.payload.rfc_id))

            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                errors: [],
            };

        case TODOS_DELETE_ITEM_ERROR:
            console.log("Failed", action.payload)
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                hasErrors: true,
                errors: action.payload.errors.map(error => (
                    {
                        field: error.field,
                        error_code: error.code
                    }
                ))
            };

        default:
            return { ...state };
    }
};
