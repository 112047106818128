// eslint-disable-next-line import/no-cycle
import {
    CATEGORIES_DELETE_ITEMS,
    CATEGORIES_DELETE_ITEMS_ERROR, CATEGORIES_DELETE_ITEMS_SUCCESS, CATEGORIES_GET_LIST,
    CATEGORIES_GET_LIST_ERROR,
    CATEGORIES_GET_LIST_SEARCH, CATEGORIES_GET_LIST_SUCCESS,
    CATEGORIES_GET_LIST_WITH_FILTER,
    CATEGORIES_GET_LIST_WITH_ORDER,
    CATEGORIES_INITIALIZE_LIST,
    CATEGORIES_INITIALIZE_LIST_SUCCESS,
    CATEGORIES_INITIALIZE_LIST_ERROR,
    CATEGORIES_SELECTED_ITEMS_CHANGE
} from './actions_def';
export const getCategoriesList = (payload) => ({
    type: CATEGORIES_GET_LIST,
    payload: payload,
});

export const getCategoriesListSuccess = (items) => ({
    type: CATEGORIES_GET_LIST_SUCCESS,
    payload: items,
});

export const getCategoriesListError = (error) => ({
    type: CATEGORIES_GET_LIST_ERROR,
    payload: error,
});

export const getCategoriesListWithFilter = (column, value) => ({
    type: CATEGORIES_GET_LIST_WITH_FILTER,
    payload: { column, value },
});

export const getCategoriesListWithOrder = (column) => ({
    type: CATEGORIES_GET_LIST_WITH_ORDER,
    payload: column,
});

export const getCategoriesListSearch = (keyword) => ({
    type: CATEGORIES_GET_LIST_SEARCH,
    payload: keyword,
});

export const selectedCategoryItemsChange = (selectedItems) => ({
    type: CATEGORIES_SELECTED_ITEMS_CHANGE,
    payload: selectedItems,
});




export const initializeCategoriesList = (projectId) => ({
    type: CATEGORIES_INITIALIZE_LIST,
    payload: projectId,
});


export const initializeCategoriesListSuccess = (projectId) => ({
    type: CATEGORIES_INITIALIZE_LIST_SUCCESS,
    payload: projectId,
});


export const initializeCategoriesListError = (projectId) => ({
    type: CATEGORIES_INITIALIZE_LIST_ERROR,
    payload: projectId,
});



export const deleteCategoriesList = (items) => ({
    type: CATEGORIES_DELETE_ITEMS,
    payload: items,
});

export const deleteCategoriesListSuccess = (items) => ({
    type: CATEGORIES_DELETE_ITEMS_SUCCESS,
    payload: items,
});

export const deleteCategoriesListError = (error) => ({
    type: CATEGORIES_DELETE_ITEMS_ERROR,
    payload: error,
});
