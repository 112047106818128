import { all } from 'redux-saga/effects';
import issuesSagas from 'projects/redux/issues/sagas';
import workSagas from 'projects/redux/work/sagas';
import milestonesSagas from 'projects/redux/milestones/sagas';
import surveysSagas from 'projects/redux/surveys/sagas';
import projectsSagas from 'projects/redux/projects/sagas';
import documentsSagas from 'projects/redux/documents/sagas';
export default function* rootSaga(getState) {
    yield all([
        issuesSagas(),
        workSagas(),
        milestonesSagas(),
        surveysSagas(),
        projectsSagas(),
        documentsSagas(),
    ]);
}
