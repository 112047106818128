export const ISSUES_RESOLUTIONS_RESET_ITEM = 'RESOLUTIONS_RESET_ITEM';

export const ISSUES_RESOLUTIONS_FETCH_ITEM = 'RESOLUTIONS_FETCH_ITEM';
export const ISSUES_RESOLUTIONS_FETCH_ITEM_SUCCESS = 'RESOLUTIONS_FETCH_ITEM_SUCCESS';
export const ISSUES_RESOLUTIONS_FETCH_ITEM_ERROR = 'RESOLUTIONS_FETCH_ITEM_ERROR';

export const ISSUES_RESOLUTIONS_ADD_ITEM = 'RESOLUTIONS_ADD_ITEM';
export const ISSUES_RESOLUTIONS_ADD_ITEM_SUCCESS = 'RESOLUTIONS_ADD_ITEM_SUCCESS';
export const ISSUES_RESOLUTIONS_ADD_ITEM_ERROR = 'RESOLUTIONS_ADD_ITEM_ERROR';

export const ISSUES_RESOLUTIONS_DELETE_ITEM = 'RESOLUTIONS_DELETE_ITEM';
export const ISSUES_RESOLUTIONS_DELETE_ITEM_SUCCESS = 'RESOLUTIONS_DELETE_ITEM_SUCCESS';
export const ISSUES_RESOLUTIONS_DELETE_ITEM_ERROR = 'RESOLUTIONS_DELETE_ITEM_ERROR';

export const ISSUES_RESOLUTIONS_SAVE_ITEM = 'RESOLUTIONS_DELETE_ITEM_ERROR';
export const ISSUES_RESOLUTIONS_SAVE_ITEM_SUCCESS = 'RESOLUTIONS_DELETE_ITEM_ERROR';
export const ISSUES_RESOLUTIONS_SAVE_ITEM_ERROR = 'RESOLUTIONS_DELETE_ITEM_ERROR';