import { all } from 'redux-saga/effects';
import list from './list/saga';
import details from './details/saga';

import issue_statuses from './issue_statuses/sagas';
import issue_types from './issue_types/sagas';
import issue_resolutions from './issue_resolutions/sagas';
import issue_priorities from './issue_priorities/sagas';
import issue_categories from './issue_categories/sagas';


export default function* rootSaga(getState) {
    yield all([
        list(),
        details(),
        issue_statuses(),
        issue_types(),
        issue_resolutions(),
        issue_priorities(),
        issue_categories(),
    ]);
}
