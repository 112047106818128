import axios from "axios";
import { servicePath } from 'constants/defaultValues';
import { getAccessToken } from 'helpers/Firebase';
import { getCurrentColor } from "helpers/Utils";
import moment from 'moment';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    deleteProjectsListError, deleteProjectsListSuccess,

    getProjectsListError,
    getProjectsListSuccess
} from './actions';
import {
    PROJECTS_DELETE_ITEMS, PROJECTS_GET_LIST
} from './actions_def';
import { getCurrentUser } from 'helpers/Utils';

const apiUrl = servicePath;

const getProjectsListRequest = async (activeOnly) => {
    // eslint-disable-next-line no-return-await
    var _user = getCurrentUser();

    console.log("getProjectsListRequest");

    return await new Promise((success, fail) => {
        const instance = axios.create({
            method: 'get',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + _user.accessToken
            },
        });

        let _url = '/projects/';
        if (!activeOnly) {
            _url = '/projects/all';
        }

        instance.get(_url,
            { crossdomain: true })
            .then(response => {
                if (response && response.data) {
                    // console.log('success', response.data);
                    success(response.data);
                }
                else {
                    // console.log('invalid response', response.data);
                    fail(response);
                }
            }).catch((error) => {
                // console.log(error);
                fail(error);
            });
    })
        .then((response) => response)
        .catch((error) => {
            // console.log(error);
            return error;
        });



};

function* getProjectsList() {

    try {
        const response = yield call(getProjectsListRequest);
        yield put(getProjectsListSuccess(response));
    } catch (error) {
        yield put(getProjectsListError(error));
    }
}

const deleteProjectsListRequest = async (items) => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success, fail) => {

        // success(notificationListData.data);
        // var _user = JSON.parse(localStorage.getItem("user"));
        const instance = axios.create({
            method: 'delete',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });

        // console.log('Sending :', {
        //   rfc_title: item.title,
        //   rfc_type: item.type ? item.type : 1,
        //   rfc_summary: item.detail ? item.detail : ' ',
        // })
        let _status = 1;
        switch (items.status) {
            case "PENDING": _status = 1; break;
            default: _status = 1;
        };

        items.map(item => {
            // console.log(item)
            instance.delete('/projects/' + item + '/',
                { crossdomain: true })
                .then(response => {
                    if (response.data) {
                        if (response.data.status === "fail") {
                            fail(response.data);
                        }
                        else if (response.data.status === "success" || response.data.status === "ok") {
                            success(response.data);
                        }
                        else {
                            // console.log('Inconsistent behaviour', response.data.status)
                        }
                    }
                }).catch((error) => {
                    // console.log(error)
                });
        });
    })
        .then((response) => response)
        .catch((error) => error);
};

function* deleteProjectsList({ payload }) {
    // // // console.log(payload);;

    try {
        const response = yield call(deleteProjectsListRequest, payload);
        yield put(deleteProjectsListSuccess(response));
    } catch (error) {
        yield put(deleteProjectsListError(error));
    }
}



export function* watchGetList() {
    yield takeEvery(PROJECTS_GET_LIST, getProjectsList);
}

export function* watchDeleteItems() {
    yield takeEvery(PROJECTS_DELETE_ITEMS, deleteProjectsList);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetList),
        fork(watchDeleteItems),
    ]);
}
