// eslint-disable-next-line import/no-cycle
import {
    TODOS_DELETE_ITEMS,
    TODOS_DELETE_ITEMS_ERROR,
    TODOS_DELETE_ITEMS_SUCCESS,
    TODOS_GET_LIST,
    TODOS_GET_LIST_ERROR,
    TODOS_GET_LIST_SEARCH,
    TODOS_GET_LIST_SUCCESS,
    TODOS_GET_LIST_WITH_FILTER,
    TODOS_GET_LIST_WITH_ORDER,
    TODOS_SELECTED_ITEMS_CHANGE
} from './actions_def';
export const getToDoList = () => ({
    type: TODOS_GET_LIST,
});

export const getToDoListSuccess = (items) => ({
    type: TODOS_GET_LIST_SUCCESS,
    payload: items,
});

export const getToDoListError = (error) => ({
    type: TODOS_GET_LIST_ERROR,
    payload: error,
});

export const getToDoListWithFilter = (column, value) => ({
    type: TODOS_GET_LIST_WITH_FILTER,
    payload: { column, value },
});

export const getToDoListWithOrder = (column) => ({
    type: TODOS_GET_LIST_WITH_ORDER,
    payload: column,
});

export const getToDoListSearch = (keyword) => ({
    type: TODOS_GET_LIST_SEARCH,
    payload: keyword,
});

export const selectedToDoItemsChange = (selectedItems) => ({
    type: TODOS_SELECTED_ITEMS_CHANGE,
    payload: selectedItems,
});



export const deleteToDosList = (items) => ({
    type: TODOS_DELETE_ITEMS,
    payload: items,
});

export const deleteToDosListSuccess = (items) => ({
    type: TODOS_DELETE_ITEMS_SUCCESS,
    payload: items,
});

export const deleteToDosListError = (error) => ({
    type: TODOS_DELETE_ITEMS_ERROR,
    payload: error,
});
