// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import {
    ISSUES_TYPES_RESET_ITEM,
    ISSUES_TYPES_FETCH_ITEM,
    ISSUES_TYPES_FETCH_ITEM_SUCCESS,
    ISSUES_TYPES_FETCH_ITEM_ERROR,
    ISSUES_TYPES_SAVE_ITEM,
    ISSUES_TYPES_SAVE_ITEM_SUCCESS,
    ISSUES_TYPES_SAVE_ITEM_ERROR,
    ISSUES_TYPES_ADD_ITEM,
    ISSUES_TYPES_ADD_ITEM_SUCCESS,
    ISSUES_TYPES_ADD_ITEM_ERROR,
    ISSUES_TYPES_DELETE_ITEM,
    ISSUES_TYPES_DELETE_ITEM_SUCCESS,
    ISSUES_TYPES_DELETE_ITEM_ERROR,
} from './actions_def';


export const resetIssuesTypeItem = (item) => ({
    type: ISSUES_TYPES_RESET_ITEM,
    payload: item,
});


export const saveIssuesTypeItem = (item) => ({
    type: ISSUES_TYPES_SAVE_ITEM,
    payload: item,
});


export const saveIssuesTypeItemSuccess = (items) => ({
    type: ISSUES_TYPES_SAVE_ITEM_SUCCESS,
    payload: items,
});

export const saveIssuesTypeItemError = (error) => ({
    type: ISSUES_TYPES_SAVE_ITEM_ERROR,
    payload: error,
});


export const getIssuesTypeItem = (item) => ({
    type: ISSUES_TYPES_FETCH_ITEM,
    payload: item,
});

export const getIssuesTypeItemSuccess = (items) => ({
    type: ISSUES_TYPES_FETCH_ITEM_SUCCESS,
    payload: items,
});

export const getIssuesTypeItemError = (error) => ({
    type: ISSUES_TYPES_FETCH_ITEM_ERROR,
    payload: error,
});


export const addIssuesTypeItem = (item) => ({
    type: ISSUES_TYPES_ADD_ITEM,
    payload: item,
});

export const addIssuesTypeItemSuccess = (items) => ({
    type: ISSUES_TYPES_ADD_ITEM_SUCCESS,
    payload: items,
});

export const addIssuesTypeItemError = (error) => ({
    type: ISSUES_TYPES_ADD_ITEM_ERROR,
    payload: error,
});



export const deleteIssuesTypeItem = (item) => ({
    type: ISSUES_TYPES_DELETE_ITEM,
    payload: item,
});

export const deleteIssuesTypeItemSuccess = (items) => ({
    type: ISSUES_TYPES_DELETE_ITEM_SUCCESS,
    payload: items,
});

export const deleteIssuesTypeItemError = (error) => ({
    type: ISSUES_TYPES_DELETE_ITEM_ERROR,
    payload: error,
});

