import {
    ISSUES_STATUSES_RESET_ITEM,
    ISSUES_STATUSES_ADD_ITEM,
    ISSUES_STATUSES_ADD_ITEM_SUCCESS,
    ISSUES_STATUSES_ADD_ITEM_ERROR,
    ISSUES_STATUSES_FETCH_ITEM,
    ISSUES_STATUSES_FETCH_ITEM_SUCCESS,
    ISSUES_STATUSES_FETCH_ITEM_ERROR,
    ISSUES_STATUSES_DELETE_ITEM,
    ISSUES_STATUSES_DELETE_ITEM_SUCCESS,
    ISSUES_STATUSES_DELETE_ITEM_ERROR,
} from './actions_def';

import moment from 'moment'
const INIT_STATE = {
    allIssuesStatusItems: null,
    statusItems: [],
    errors: [],
    filter: null,
    searchKeyword: '',
    orderColumn: null,
    isLoading: false,
    isLoaded: false,
    saving: false,
    labels: [
        { label: 'EDUCATION', color: 'secondary' },
        { label: 'NEW FRAMEWORK', color: 'primary' },
        { label: 'PERSONAL', color: 'info' },
    ],
    orderColumns: [
        { column: 'title', label: 'Title' },
        { column: 'status', label: 'IssuesStatus' },
        { column: 'status', label: 'IssuesStatus' },
        { column: 'label', label: 'Label' },
    ],
    statuses: ['Flexbox', 'Sass', 'React'],
    selectedItems: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ISSUES_STATUSES_RESET_ITEM:
            return INIT_STATE;
            break;

        case ISSUES_STATUSES_FETCH_ITEM:
            return { ...state, isLoading: true, isLoaded: false, };

        case ISSUES_STATUSES_FETCH_ITEM_SUCCESS:
            return {
                ...state,
                isLoading: false, isLoaded: true,
                allIssuesStatusItems: action.payload,
                statusItems: action.payload,
            };

        case ISSUES_STATUSES_FETCH_ITEM_ERROR:
            return {
                ...state,
                isLoading: false, isLoaded: false,
                error: action.payload
            };

        case ISSUES_STATUSES_ADD_ITEM:
            return { ...state, saving: true };

        case ISSUES_STATUSES_ADD_ITEM_SUCCESS:
            // console.log("Success", [...state.allIssuesStatusItems, {
            //   id: action.payload.instance.rfc_id,
            //   title: action.payload.instance.rfc_title,
            //   date: moment(action.payload.instance.date_created).format("Do MMM YYYY"),
            //   change_date: moment(action.payload.instance.date_modified).format("Do MMM YYYY (hh:mm)"),
            //   end_date: moment(action.payload.instance.rfc_implementation_end_date).format("Do MMM YYYY (hh:mm)"),
            //   status: action.payload.instance.rfc_status,
            //   statusColor: 'primary'
            // }])

            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                saving: false,
                errors: [],
                allIssuesStatusItems: [...state.allIssuesStatusItems, {
                    id: action.payload.instance.rfc_id,
                    title: action.payload.instance.rfc_title,
                    date: moment(action.payload.instance.date_created).format("Do MMM YYYY"),
                    change_date: moment(action.payload.instance.date_modified).format("Do MMM YYYY (hh:mm)"),
                    end_date: moment(action.payload.instance.rfc_implementation_end_date).format("Do MMM YYYY (hh:mm)"),
                    status: action.payload.instance.rfc_status,
                    statusColor: 'primary'
                }],
                statusItems: [...state.statusItems,
                {
                    id: action.payload.instance.rfc_id,
                    title: action.payload.instance.rfc_title,
                    date: moment(action.payload.instance.date_created).format("Do MMM YYYY"),
                    change_date: moment(action.payload.instance.date_modified).format("Do MMM YYYY (hh:mm)"),
                    end_date: moment(action.payload.instance.rfc_implementation_end_date).format("Do MMM YYYY (hh:mm)"),
                    status: action.payload.instance.rfc_status,
                    statusColor: 'primary'
                }],
            };

        case ISSUES_STATUSES_ADD_ITEM_ERROR:
            console.log("Failed", action.payload)
            return {
                ...state,
                isLoading: true,
                saving: false,
                errors: action.payload.errors && action.payload.errors.map(error => (
                    {
                        field: error.field,
                        error_code: error.code
                    }
                ))
            };

        case ISSUES_STATUSES_DELETE_ITEM:
            return { ...state, saving: true };

        case ISSUES_STATUSES_DELETE_ITEM_SUCCESS:
            console.log("Success", state.allIssuesStatusItems.filter(item => item && item.id !== action.payload.rfc_id))

            return {
                ...state,
                isLoading: true,
                saving: false,
                saved: true,
                errors: [],

                allIssuesStatusItems: state.allIssuesStatusItems.filter(item => item && item.id !== action.payload.rfc_id),
                statusItems: state.statusItems.filter(item => item && item.id !== action.payload.rfc_id),
            };

        case ISSUES_STATUSES_DELETE_ITEM_ERROR:
            console.log("Failed", action.payload)
            return {
                ...state,
                isLoading: false,
                saving: false,
                saved: true,
                errors: action.payload.errors.map(error => (
                    {
                        field: error.field,
                        error_code: error.code
                    }
                ))
            };

        default:
            return { ...state };
    }
};
