// eslint-disable-next-line import/no-cycle
import {
    ISSUES_PRIORITIES_DELETE_ITEMS,
    ISSUES_PRIORITIES_DELETE_ITEMS_ERROR, ISSUES_PRIORITIES_DELETE_ITEMS_SUCCESS, ISSUES_PRIORITIES_GET_LIST,
    ISSUES_PRIORITIES_GET_LIST_ERROR,
    ISSUES_PRIORITIES_GET_LIST_SEARCH, ISSUES_PRIORITIES_GET_LIST_SUCCESS,
    ISSUES_PRIORITIES_GET_LIST_WITH_FILTER,
    ISSUES_PRIORITIES_GET_LIST_WITH_ORDER,
    ISSUES_PRIORITIES_INITIALIZE_LIST,
    ISSUES_PRIORITIES_INITIALIZE_LIST_SUCCESS,
    ISSUES_PRIORITIES_INITIALIZE_LIST_ERROR,
    ISSUES_PRIORITIES_SELECTED_ITEMS_CHANGE
} from './actions_def';
export const getIssuesPrioritiesList = (payload) => ({
    type: ISSUES_PRIORITIES_GET_LIST,
    payload: payload,
});

export const getIssuesPrioritiesListSuccess = (items) => ({
    type: ISSUES_PRIORITIES_GET_LIST_SUCCESS,
    payload: items,
});

export const getIssuesPrioritiesListError = (error) => ({
    type: ISSUES_PRIORITIES_GET_LIST_ERROR,
    payload: error,
});

export const getIssuesPrioritiesListWithFilter = (column, value) => ({
    type: ISSUES_PRIORITIES_GET_LIST_WITH_FILTER,
    payload: { column, value },
});

export const getIssuesPrioritiesListWithOrder = (column) => ({
    type: ISSUES_PRIORITIES_GET_LIST_WITH_ORDER,
    payload: column,
});

export const getIssuesPrioritiesListSearch = (keyword) => ({
    type: ISSUES_PRIORITIES_GET_LIST_SEARCH,
    payload: keyword,
});

export const selectedIssuesPriorityItemsChange = (selectedItems) => ({
    type: ISSUES_PRIORITIES_SELECTED_ITEMS_CHANGE,
    payload: selectedItems,
});




export const initializeIssuesPrioritiesList = (projectId) => ({
    type: ISSUES_PRIORITIES_INITIALIZE_LIST,
    payload: projectId,
});


export const initializeIssuesPrioritiesListSuccess = (projectId) => ({
    type: ISSUES_PRIORITIES_INITIALIZE_LIST_SUCCESS,
    payload: projectId,
});


export const initializeIssuesPrioritiesListError = (projectId) => ({
    type: ISSUES_PRIORITIES_INITIALIZE_LIST_ERROR,
    payload: projectId,
});



export const deleteIssuesPrioritiesList = (items) => ({
    type: ISSUES_PRIORITIES_DELETE_ITEMS,
    payload: items,
});

export const deleteIssuesPrioritiesListSuccess = (items) => ({
    type: ISSUES_PRIORITIES_DELETE_ITEMS_SUCCESS,
    payload: items,
});

export const deleteIssuesPrioritiesListError = (error) => ({
    type: ISSUES_PRIORITIES_DELETE_ITEMS_ERROR,
    payload: error,
});
