// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import {
    WORK_FETCH_ITEM,
    WORK_FETCH_ITEM_SUCCESS,
    WORK_FETCH_ITEM_ERROR,
    WORK_RESET_ITEM,
    WORK_ADD_ITEM,
    WORK_ADD_ITEM_SUCCESS,
    WORK_ADD_ITEM_ERROR,
    WORK_DELETE_ITEM,
    WORK_DELETE_ITEM_SUCCESS,
    WORK_DELETE_ITEM_ERROR,
    WORK_SAVE_ITEM,
    WORK_SAVE_ITEM_SUCCESS,
    WORK_SAVE_ITEM_ERROR,
} from './actions_def';

export const resetWorkItem = (item) => ({
    type: WORK_RESET_ITEM,
    payload: item,
});

export const getWorkItem = (item) => ({
    type: WORK_FETCH_ITEM,
    payload: item,
});

export const getWorkItemSuccess = (items) => ({
    type: WORK_FETCH_ITEM_SUCCESS,
    payload: items,
});

export const getWorkItemError = (error) => ({
    type: WORK_FETCH_ITEM_ERROR,
    payload: error,
});


export const addWorkItem = (item) => ({
    type: WORK_ADD_ITEM,
    payload: item,
});

export const addWorkItemSuccess = (items) => ({
    type: WORK_ADD_ITEM_SUCCESS,
    payload: items,
});

export const addWorkItemError = (error) => ({
    type: WORK_ADD_ITEM_ERROR,
    payload: error,
});



export const deleteWorkItem = (item) => ({
    type: WORK_DELETE_ITEM,
    payload: item,
});

export const deleteWorkItemSuccess = (items) => ({
    type: WORK_DELETE_ITEM_SUCCESS,
    payload: items,
});

export const deleteWorkItemError = (error) => ({
    type: WORK_DELETE_ITEM_ERROR,
    payload: error,
});




export const saveWorkItem = (item) => ({
    type: WORK_SAVE_ITEM,
    payload: item,
});

export const saveWorkItemSuccess = (items) => ({
    type: WORK_SAVE_ITEM_SUCCESS,
    payload: items,
});

export const saveWorkItemError = (error) => ({
    type: WORK_SAVE_ITEM_ERROR,
    payload: error,
});

