// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import {
    ISSUES_RESOLUTIONS_RESET_ITEM,
    ISSUES_RESOLUTIONS_FETCH_ITEM,
    ISSUES_RESOLUTIONS_FETCH_ITEM_SUCCESS,
    ISSUES_RESOLUTIONS_FETCH_ITEM_ERROR,
    ISSUES_RESOLUTIONS_SAVE_ITEM,
    ISSUES_RESOLUTIONS_SAVE_ITEM_SUCCESS,
    ISSUES_RESOLUTIONS_SAVE_ITEM_ERROR,
    ISSUES_RESOLUTIONS_ADD_ITEM,
    ISSUES_RESOLUTIONS_ADD_ITEM_SUCCESS,
    ISSUES_RESOLUTIONS_ADD_ITEM_ERROR,
    ISSUES_RESOLUTIONS_DELETE_ITEM,
    ISSUES_RESOLUTIONS_DELETE_ITEM_SUCCESS,
    ISSUES_RESOLUTIONS_DELETE_ITEM_ERROR,
} from './actions_def';


export const resetIssuesResolutionItem = (item) => ({
    type: ISSUES_RESOLUTIONS_RESET_ITEM,
    payload: item,
});


export const saveIssuesResolutionItem = (item) => ({
    type: ISSUES_RESOLUTIONS_SAVE_ITEM,
    payload: item,
});


export const saveIssuesResolutionItemSuccess = (items) => ({
    type: ISSUES_RESOLUTIONS_SAVE_ITEM_SUCCESS,
    payload: items,
});

export const saveIssuesResolutionItemError = (error) => ({
    type: ISSUES_RESOLUTIONS_SAVE_ITEM_ERROR,
    payload: error,
});


export const getIssuesResolutionItem = (item) => ({
    type: ISSUES_RESOLUTIONS_FETCH_ITEM,
    payload: item,
});

export const getIssuesResolutionItemSuccess = (items) => ({
    type: ISSUES_RESOLUTIONS_FETCH_ITEM_SUCCESS,
    payload: items,
});

export const getIssuesResolutionItemError = (error) => ({
    type: ISSUES_RESOLUTIONS_FETCH_ITEM_ERROR,
    payload: error,
});


export const addIssuesResolutionItem = (item) => ({
    type: ISSUES_RESOLUTIONS_ADD_ITEM,
    payload: item,
});

export const addIssuesResolutionItemSuccess = (items) => ({
    type: ISSUES_RESOLUTIONS_ADD_ITEM_SUCCESS,
    payload: items,
});

export const addIssuesResolutionItemError = (error) => ({
    type: ISSUES_RESOLUTIONS_ADD_ITEM_ERROR,
    payload: error,
});



export const deleteIssuesResolutionItem = (item) => ({
    type: ISSUES_RESOLUTIONS_DELETE_ITEM,
    payload: item,
});

export const deleteIssuesResolutionItemSuccess = (items) => ({
    type: ISSUES_RESOLUTIONS_DELETE_ITEM_SUCCESS,
    payload: items,
});

export const deleteIssuesResolutionItemError = (error) => ({
    type: ISSUES_RESOLUTIONS_DELETE_ITEM_ERROR,
    payload: error,
});

