import {
    MILESTONES_ADD_ITEM,
    MILESTONES_ADD_ITEM_SUCCESS,
    MILESTONES_ADD_ITEM_ERROR,
    MILESTONES_FETCH_ITEM,
    MILESTONES_FETCH_ITEM_SUCCESS,
    MILESTONES_FETCH_ITEM_ERROR,
    MILESTONES_DELETE_ITEM,
    MILESTONES_DELETE_ITEM_SUCCESS,
    MILESTONES_DELETE_ITEM_ERROR,
} from './actions_def';

import moment from 'moment'
const INIT_STATE = {
    allMilestonesItems: null,
    milestonesItems: [],
    errors: [],
    filter: null,
    searchKeyword: '',
    orderColumn: null,
    isLoading: false,
    saving: false,
    labels: [
        { label: 'EDUCATION', color: 'secondary' },
        { label: 'NEW FRAMEWORK', color: 'primary' },
        { label: 'PERSONAL', color: 'info' },
    ],
    orderColumns: [
        { column: 'title', label: 'Title' },
        { column: 'category', label: 'Category' },
        { column: 'status', label: 'Status' },
        { column: 'label', label: 'Label' },
    ],
    categories: ['Flexbox', 'Sass', 'React'],
    selectedItems: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case MILESTONES_FETCH_ITEM:
            return { ...state, isLoading: false };

        case MILESTONES_FETCH_ITEM_SUCCESS:
            return {
                ...state,
                isLoading: true,
                allMilestonesItems: action.payload,
                milestonesItems: action.payload,
            };

        case MILESTONES_FETCH_ITEM_ERROR:
            return { ...state, isLoading: true, error: action.payload };

        case MILESTONES_ADD_ITEM:
            return { ...state, saving: true };

        case MILESTONES_ADD_ITEM_SUCCESS:
            // console.log("Success", [...state.allMilestonesItems, {
            //   id: action.payload.instance.rfc_id,
            //   title: action.payload.instance.rfc_title,
            //   date: moment(action.payload.instance.date_created).format("Do MMM YYYY"),
            //   change_date: moment(action.payload.instance.date_modified).format("Do MMM YYYY (hh:mm)"),
            //   end_date: moment(action.payload.instance.rfc_implementation_end_date).format("Do MMM YYYY (hh:mm)"),
            //   status: action.payload.instance.rfc_status,
            //   statusColor: 'primary'
            // }])

            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                saving: false,
                errors: [],
                allMilestonesItems: [...state.allMilestonesItems, {
                    id: action.payload.instance.rfc_id,
                    title: action.payload.instance.rfc_title,
                    date: moment(action.payload.instance.date_created).format("Do MMM YYYY"),
                    change_date: moment(action.payload.instance.date_modified).format("Do MMM YYYY (hh:mm)"),
                    end_date: moment(action.payload.instance.rfc_implementation_end_date).format("Do MMM YYYY (hh:mm)"),
                    status: action.payload.instance.rfc_status,
                    statusColor: 'primary'
                }],
                milestonesItems: [...state.milestonesItems,
                {
                    id: action.payload.instance.rfc_id,
                    title: action.payload.instance.rfc_title,
                    date: moment(action.payload.instance.date_created).format("Do MMM YYYY"),
                    change_date: moment(action.payload.instance.date_modified).format("Do MMM YYYY (hh:mm)"),
                    end_date: moment(action.payload.instance.rfc_implementation_end_date).format("Do MMM YYYY (hh:mm)"),
                    status: action.payload.instance.rfc_status,
                    statusColor: 'primary'
                }],
            };

        case MILESTONES_ADD_ITEM_ERROR:
            console.log("Failed", action.payload)
            return {
                ...state,
                isLoading: true,
                saving: false,
                errors: action.payload.errors && action.payload.errors.map(error => (
                    {
                        field: error.field,
                        error_code: error.code
                    }
                ))
            };

        case MILESTONES_DELETE_ITEM:
            return { ...state, saving: true };

        case MILESTONES_DELETE_ITEM_SUCCESS:
            console.log("Success", state.allMilestonesItems.filter(item => item && item.id !== action.payload.rfc_id))

            return {
                ...state,
                isLoading: true,
                saving: false,
                saved: true,
                errors: [],

                allMilestonesItems: state.allMilestonesItems.filter(item => item && item.id !== action.payload.rfc_id),
                milestonesItems: state.milestonesItems.filter(item => item && item.id !== action.payload.rfc_id),
            };

        case MILESTONES_DELETE_ITEM_ERROR:
            console.log("Failed", action.payload)
            return {
                ...state,
                isLoading: false,
                saving: false,
                saved: true,
                errors: action.payload.errors.map(error => (
                    {
                        field: error.field,
                        error_code: error.code
                    }
                ))
            };

        default:
            return { ...state };
    }
};
