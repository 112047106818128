export const DOCUMENTS_FETCH_ITEM = 'DOCUMENTS_FETCH_ITEM';
export const DOCUMENTS_FETCH_ITEM_SUCCESS = 'DOCUMENTS_FETCH_ITEM_SUCCESS';
export const DOCUMENTS_FETCH_ITEM_ERROR = 'DOCUMENTS_FETCH_ITEM_ERROR';

export const DOCUMENTS_ADD_ITEM = 'DOCUMENTS_ADD_ITEM';
export const DOCUMENTS_ADD_ITEM_SUCCESS = 'DOCUMENTS_ADD_ITEM_SUCCESS';
export const DOCUMENTS_ADD_ITEM_ERROR = 'DOCUMENTS_ADD_ITEM_ERROR';

export const DOCUMENTS_DELETE_ITEM = 'DOCUMENTS_DELETE_ITEM';
export const DOCUMENTS_DELETE_ITEM_SUCCESS = 'DOCUMENTS_DELETE_ITEM_SUCCESS';
export const DOCUMENTS_DELETE_ITEM_ERROR = 'DOCUMENTS_DELETE_ITEM_ERROR';

export const DOCUMENTS_SAVE_ITEM = 'DOCUMENTS_SAVE_ITEM';
export const DOCUMENTS_SAVE_ITEM_SUCCESS = 'DOCUMENTS_SAVE_ITEM_SUCCESS';
export const DOCUMENTS_SAVE_ITEM_ERROR = 'DOCUMENTS_SAVE_ITEM_ERROR';

export const DOCUMENT_CHAPTER_ADD = 'DOCUMENT_CHAPTER_ADD';
export const DOCUMENT_CHAPTER_ADD_SUCCESS = 'DOCUMENT_CHAPTER_ADD_SUCCESS';
export const DOCUMENT_CHAPTER_ADD_ERROR = 'DOCUMENT_CHAPTER_ADD_ERROR';
export const DOCUMENT_CHAPTER_DELETE = 'DOCUMENT_CHAPTER_DELETE';
export const DOCUMENT_CHAPTER_DELETE_SUCCESS = 'DOCUMENT_CHAPTER_DELETE_SUCCESS';
export const DOCUMENT_CHAPTER_DELETE_ERROR = 'DOCUMENT_CHAPTER_DELETE_ERROR';
export const DOCUMENT_SET_CURRENT_CHAPTER = 'DOCUMENT_SET_CURRENT_CHAPTER';