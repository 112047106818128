// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import {
    CATEGORIES_RESET_ITEM,
    CATEGORIES_FETCH_ITEM,
    CATEGORIES_FETCH_ITEM_SUCCESS,
    CATEGORIES_FETCH_ITEM_ERROR,
    CATEGORIES_SAVE_ITEM,
    CATEGORIES_SAVE_ITEM_SUCCESS,
    CATEGORIES_SAVE_ITEM_ERROR,
    CATEGORIES_ADD_ITEM,
    CATEGORIES_ADD_ITEM_SUCCESS,
    CATEGORIES_ADD_ITEM_ERROR,
    CATEGORIES_DELETE_ITEM,
    CATEGORIES_DELETE_ITEM_SUCCESS,
    CATEGORIES_DELETE_ITEM_ERROR,
} from './actions_def';


export const resetCategoryItem = (item) => ({
    type: CATEGORIES_RESET_ITEM,
    payload: item,
});


export const saveCategoryItem = (item) => ({
    type: CATEGORIES_SAVE_ITEM,
    payload: item,
});


export const saveCategoryItemSuccess = (items) => ({
    type: CATEGORIES_SAVE_ITEM_SUCCESS,
    payload: items,
});

export const saveCategoryItemError = (error) => ({
    type: CATEGORIES_SAVE_ITEM_ERROR,
    payload: error,
});


export const getCategoryItem = (item) => ({
    type: CATEGORIES_FETCH_ITEM,
    payload: item,
});

export const getCategoryItemSuccess = (items) => ({
    type: CATEGORIES_FETCH_ITEM_SUCCESS,
    payload: items,
});

export const getCategoryItemError = (error) => ({
    type: CATEGORIES_FETCH_ITEM_ERROR,
    payload: error,
});


export const addCategoryItem = (item) => ({
    type: CATEGORIES_ADD_ITEM,
    payload: item,
});

export const addCategoryItemSuccess = (items) => ({
    type: CATEGORIES_ADD_ITEM_SUCCESS,
    payload: items,
});

export const addCategoryItemError = (error) => ({
    type: CATEGORIES_ADD_ITEM_ERROR,
    payload: error,
});



export const deleteCategoryItem = (item) => ({
    type: CATEGORIES_DELETE_ITEM,
    payload: item,
});

export const deleteCategoryItemSuccess = (items) => ({
    type: CATEGORIES_DELETE_ITEM_SUCCESS,
    payload: items,
});

export const deleteCategoryItemError = (error) => ({
    type: CATEGORIES_DELETE_ITEM_ERROR,
    payload: error,
});

