// eslint-disable-next-line import/no-cycle
import {
    ISSUES_DELETE_ITEMS,
    ISSUES_DELETE_ITEMS_ERROR, ISSUES_DELETE_ITEMS_SUCCESS, ISSUES_GET_LIST,
    ISSUES_GET_LIST_ERROR,
    ISSUES_GET_LIST_SEARCH, ISSUES_GET_LIST_SUCCESS,
    ISSUES_GET_LIST_WITH_FILTER,
    ISSUES_GET_LIST_WITH_ORDER,
    ISSUES_SELECTED_ITEMS_CHANGE
} from './actions_def';
export const getIssuesList = (payload) => ({
    type: ISSUES_GET_LIST,
    payload: payload,
});

export const getMyPostsList = (payload) => ({
    type: ISSUES_GET_LIST,
    payload: payload,
});

export const getIssuesListSuccess = (items) => ({
    type: ISSUES_GET_LIST_SUCCESS,
    payload: items,
});

export const getIssuesListError = (error) => ({
    type: ISSUES_GET_LIST_ERROR,
    payload: error,
});

export const getIssuesListWithFilter = (column, value) => ({
    type: ISSUES_GET_LIST_WITH_FILTER,
    payload: { column, value },
});

export const getIssuesListWithOrder = (column) => ({
    type: ISSUES_GET_LIST_WITH_ORDER,
    payload: column,
});

export const getIssuesListSearch = (keyword) => ({
    type: ISSUES_GET_LIST_SEARCH,
    payload: keyword,
});

export const selectedIssuesItemsChange = (selectedItems) => ({
    type: ISSUES_SELECTED_ITEMS_CHANGE,
    payload: selectedItems,
});



export const deleteIssuesList = (items) => ({
    type: ISSUES_DELETE_ITEMS,
    payload: items,
});

export const deleteIssuesListSuccess = (items) => ({
    type: ISSUES_DELETE_ITEMS_SUCCESS,
    payload: items,
});

export const deleteIssuesListError = (error) => ({
    type: ISSUES_DELETE_ITEMS_ERROR,
    payload: error,
});
