import axios from "axios";
import { servicePath } from 'constants/defaultValues';
import { getAccessToken } from 'helpers/Firebase';
import moment from 'moment';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    deleteMilestonesListError, deleteMilestonesListSuccess,

    getMilestonesListError,
    getMilestonesListSuccess
} from './actions';
import {
    MILESTONES_DELETE_ITEMS, MILESTONES_GET_LIST
} from './actions_def';


const apiUrl = servicePath;

const getMilestonesListRequest = async (payload) => {
    // console.log(payload);
    // eslint-disable-next-line no-return-await
    return await new Promise((success) => {
        const instance = axios.create({
            method: 'get',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });
        instance.get('/projects/' + payload.projectId + '/milestones/',
            { crossdomain: true })
            .then(response => success(response.data ?
                response.data.map(item => item)
                :
                []));
    })
        .then((response) => response)
        .catch((error) => error);
};

function* getMilestonesList({ payload }) {
    // // // console.log(payload);;
    if (!payload) {
        yield put(getMilestonesListError({ error: 'prerequisites' }));
    }
    else {
        try {
            const response = yield call(getMilestonesListRequest, payload);
            yield put(getMilestonesListSuccess(response));
        } catch (error) {
            yield put(getMilestonesListError(error));
        }
    }

}

const deleteMilestonesListRequest = async (items) => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success, fail) => {

        // success(notificationListData.data);
        // var _user = JSON.parse(localStorage.getItem("user"));
        const instance = axios.create({
            method: 'delete',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });

        // console.log('Sending :', {
        //   rfc_title: item.title,
        //   rfc_type: item.type ? item.type : 1,
        //   rfc_summary: item.detail ? item.detail : ' ',
        // })
        let _status = 1;
        switch (items.status) {
            case "PENDING": _status = 1; break;
            default: _status = 1;
        };

        items.map(item => {
            console.log(item)
            instance.delete('/milestones/' + item + '/',
                { crossdomain: true })
                .then(response => {
                    if (response.data) {
                        if (response.data.status === "fail") {
                            fail(response.data);
                        }
                        else if (response.data.status === "success" || response.data.status === "ok") {
                            success(response.data);
                        }
                        else {
                            console.log('Inconsistent behaviour', response.data.status)
                        }
                    }
                }).catch((error) => {
                    console.log(error)
                });
        });
    })
        .then((response) => response)
        .catch((error) => error);
};

function* deleteMilestonesList({ payload }) {
    // // console.log(payload);;

    try {
        const response = yield call(deleteMilestonesListRequest, payload);
        yield put(deleteMilestonesListSuccess(response));
    } catch (error) {
        yield put(deleteMilestonesListError(error));
    }
}



export function* watchGetList() {
    yield takeEvery(MILESTONES_GET_LIST, getMilestonesList);
}

export function* watchDeleteItems() {
    yield takeEvery(MILESTONES_DELETE_ITEMS, deleteMilestonesList);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetList),
        fork(watchDeleteItems),
    ]);
}
