import {
    WORK_GET_STATUSES_LIST,
    WORK_GET_STATUSES_LIST_SUCCESS,
    WORK_GET_STATUSES_LIST_ERROR
} from './actions_def';

const INIT_STATE = {
    workStatuses: [],
    errors: [],
    isLoading: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case WORK_GET_STATUSES_LIST:
            return { ...state, isLoading: false };

        case WORK_GET_STATUSES_LIST_SUCCESS:
            return {
                ...state,
                isLoading: true,
                workStatuses: action.payload,
            };

        case WORK_GET_STATUSES_LIST_ERROR:
            return { ...state, isLoading: true, error: action.payload };


        default:
            return { ...state };
    }
};
