
import { combineReducers } from 'redux';
import settings from './settings/reducer';
import authUser from './auth/reducer';
import chatApp from './chat/reducer';
import contactsApp from './contacts/reducers';
import expertsApp from 'experts/redux/reducers';
// import issuesApp from 'projects/redux/issues/reducers';
import landingPage from './landingPage/reducer';
// import surveysApp from 'projects/redux/surveys/reducers';
import notificationsApp from 'notifications/redux/reducers';
import menu from './menu/reducer';
import projectsApp from 'projects/redux/reducers';
// import surveyListApp from './surveyList/reducer';
// import surveyDetailApp from './surveyDetail/reducer';
import todoApp from './todo/reducers';
import tagsApp from './tags/reducers';
// import wikiApp from 'wiki/redux/reducers';

const reducers = combineReducers({
    authUser,
    chatApp,
    contactsApp,
    expertsApp,
    // issuesApp,
    landingPage,
    menu,
    // milestonesApp,
    notificationsApp,
    projectsApp,
    settings,
    // surveysApp,
    // surveyDetailApp,
    todoApp,
    tagsApp,
    // wikiApp,
});

export default reducers;