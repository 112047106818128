// eslint-disable-next-line import/no-cycle
import {
    ISSUES_RESOLUTIONS_DELETE_ITEMS,
    ISSUES_RESOLUTIONS_DELETE_ITEMS_ERROR, ISSUES_RESOLUTIONS_DELETE_ITEMS_SUCCESS, ISSUES_RESOLUTIONS_GET_LIST,
    ISSUES_RESOLUTIONS_GET_LIST_ERROR,
    ISSUES_RESOLUTIONS_GET_LIST_SEARCH, ISSUES_RESOLUTIONS_GET_LIST_SUCCESS,
    ISSUES_RESOLUTIONS_GET_LIST_WITH_FILTER,
    ISSUES_RESOLUTIONS_GET_LIST_WITH_ORDER,
    ISSUES_RESOLUTIONS_INITIALIZE_LIST,
    ISSUES_RESOLUTIONS_INITIALIZE_LIST_SUCCESS,
    ISSUES_RESOLUTIONS_INITIALIZE_LIST_ERROR,
    ISSUES_RESOLUTIONS_SELECTED_ITEMS_CHANGE
} from './actions_def';
export const getIssuesResolutionsList = (payload) => ({
    type: ISSUES_RESOLUTIONS_GET_LIST,
    payload: payload,
});

export const getIssuesResolutionsListSuccess = (items) => ({
    type: ISSUES_RESOLUTIONS_GET_LIST_SUCCESS,
    payload: items,
});

export const getIssuesResolutionsListError = (error) => ({
    type: ISSUES_RESOLUTIONS_GET_LIST_ERROR,
    payload: error,
});

export const getIssuesResolutionsListWithFilter = (column, value) => ({
    type: ISSUES_RESOLUTIONS_GET_LIST_WITH_FILTER,
    payload: { column, value },
});

export const getIssuesResolutionsListWithOrder = (column) => ({
    type: ISSUES_RESOLUTIONS_GET_LIST_WITH_ORDER,
    payload: column,
});

export const getIssuesResolutionsListSearch = (keyword) => ({
    type: ISSUES_RESOLUTIONS_GET_LIST_SEARCH,
    payload: keyword,
});

export const selectedIssuesResolutionItemsChange = (selectedItems) => ({
    type: ISSUES_RESOLUTIONS_SELECTED_ITEMS_CHANGE,
    payload: selectedItems,
});




export const initializeIssuesResolutionsList = (projectId) => ({
    type: ISSUES_RESOLUTIONS_INITIALIZE_LIST,
    payload: projectId,
});


export const initializeIssuesResolutionsListSuccess = (projectId) => ({
    type: ISSUES_RESOLUTIONS_INITIALIZE_LIST_SUCCESS,
    payload: projectId,
});


export const initializeIssuesResolutionsListError = (projectId) => ({
    type: ISSUES_RESOLUTIONS_INITIALIZE_LIST_ERROR,
    payload: projectId,
});



export const deleteIssuesResolutionsList = (items) => ({
    type: ISSUES_RESOLUTIONS_DELETE_ITEMS,
    payload: items,
});

export const deleteIssuesResolutionsListSuccess = (items) => ({
    type: ISSUES_RESOLUTIONS_DELETE_ITEMS_SUCCESS,
    payload: items,
});

export const deleteIssuesResolutionsListError = (error) => ({
    type: ISSUES_RESOLUTIONS_DELETE_ITEMS_ERROR,
    payload: error,
});
