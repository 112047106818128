// eslint-disable-next-line import/no-cycle
import {
    ISSUES_STATUSES_DELETE_ITEMS,
    ISSUES_STATUSES_DELETE_ITEMS_ERROR, ISSUES_STATUSES_DELETE_ITEMS_SUCCESS, ISSUES_STATUSES_GET_LIST,
    ISSUES_STATUSES_GET_LIST_ERROR,
    ISSUES_STATUSES_GET_LIST_SEARCH, ISSUES_STATUSES_GET_LIST_SUCCESS,
    ISSUES_STATUSES_GET_LIST_WITH_FILTER,
    ISSUES_STATUSES_GET_LIST_WITH_ORDER,
    ISSUES_STATUSES_INITIALIZE_LIST,
    ISSUES_STATUSES_INITIALIZE_LIST_SUCCESS,
    ISSUES_STATUSES_INITIALIZE_LIST_ERROR,
    ISSUES_STATUSES_SELECTED_ITEMS_CHANGE
} from './actions_def';
export const getIssuesStatusesList = (payload) => ({
    type: ISSUES_STATUSES_GET_LIST,
    payload: payload,
});

export const getIssuesStatusesListSuccess = (items) => ({
    type: ISSUES_STATUSES_GET_LIST_SUCCESS,
    payload: items,
});

export const getIssuesStatusesListError = (error) => ({
    type: ISSUES_STATUSES_GET_LIST_ERROR,
    payload: error,
});

export const getIssuesStatusesListWithFilter = (column, value) => ({
    type: ISSUES_STATUSES_GET_LIST_WITH_FILTER,
    payload: { column, value },
});

export const getIssuesStatusesListWithOrder = (column) => ({
    type: ISSUES_STATUSES_GET_LIST_WITH_ORDER,
    payload: column,
});

export const getIssuesStatusesListSearch = (keyword) => ({
    type: ISSUES_STATUSES_GET_LIST_SEARCH,
    payload: keyword,
});

export const selectedIssuesStatusItemsChange = (selectedItems) => ({
    type: ISSUES_STATUSES_SELECTED_ITEMS_CHANGE,
    payload: selectedItems,
});




export const initializeIssuesStatusesList = (projectId) => ({
    type: ISSUES_STATUSES_INITIALIZE_LIST,
    payload: projectId,
});


export const initializeIssuesStatusesListSuccess = (projectId) => ({
    type: ISSUES_STATUSES_INITIALIZE_LIST_SUCCESS,
    payload: projectId,
});


export const initializeIssuesStatusesListError = (projectId) => ({
    type: ISSUES_STATUSES_INITIALIZE_LIST_ERROR,
    payload: projectId,
});



export const deleteIssuesStatusesList = (items) => ({
    type: ISSUES_STATUSES_DELETE_ITEMS,
    payload: items,
});

export const deleteIssuesStatusesListSuccess = (items) => ({
    type: ISSUES_STATUSES_DELETE_ITEMS_SUCCESS,
    payload: items,
});

export const deleteIssuesStatusesListError = (error) => ({
    type: ISSUES_STATUSES_DELETE_ITEMS_ERROR,
    payload: error,
});
