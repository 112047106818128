
import { combineReducers } from 'redux';
import details from './details/reducers';
import list from './list/reducer';
import statuses from './statuses/reducer';
import issues from 'projects/redux/issues/reducers';
import milestones from 'projects/redux/milestones/reducers';

const reducers = combineReducers({
    details,
    list,
    statuses,
    issues,
    milestones
});

export default reducers;