// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import {
    ISSUES_STATUSES_RESET_ITEM,
    ISSUES_STATUSES_FETCH_ITEM,
    ISSUES_STATUSES_FETCH_ITEM_SUCCESS,
    ISSUES_STATUSES_FETCH_ITEM_ERROR,
    ISSUES_STATUSES_SAVE_ITEM,
    ISSUES_STATUSES_SAVE_ITEM_SUCCESS,
    ISSUES_STATUSES_SAVE_ITEM_ERROR,
    ISSUES_STATUSES_ADD_ITEM,
    ISSUES_STATUSES_ADD_ITEM_SUCCESS,
    ISSUES_STATUSES_ADD_ITEM_ERROR,
    ISSUES_STATUSES_DELETE_ITEM,
    ISSUES_STATUSES_DELETE_ITEM_SUCCESS,
    ISSUES_STATUSES_DELETE_ITEM_ERROR,
} from './actions_def';


export const resetIssuesStatusItem = (item) => ({
    type: ISSUES_STATUSES_RESET_ITEM,
    payload: item,
});


export const saveIssuesStatusItem = (item) => ({
    type: ISSUES_STATUSES_SAVE_ITEM,
    payload: item,
});


export const saveIssuesStatusItemSuccess = (items) => ({
    type: ISSUES_STATUSES_SAVE_ITEM_SUCCESS,
    payload: items,
});

export const saveIssuesStatusItemError = (error) => ({
    type: ISSUES_STATUSES_SAVE_ITEM_ERROR,
    payload: error,
});


export const getIssuesStatusItem = (item) => ({
    type: ISSUES_STATUSES_FETCH_ITEM,
    payload: item,
});

export const getIssuesStatusItemSuccess = (items) => ({
    type: ISSUES_STATUSES_FETCH_ITEM_SUCCESS,
    payload: items,
});

export const getIssuesStatusItemError = (error) => ({
    type: ISSUES_STATUSES_FETCH_ITEM_ERROR,
    payload: error,
});


export const addIssuesStatusItem = (item) => ({
    type: ISSUES_STATUSES_ADD_ITEM,
    payload: item,
});

export const addIssuesStatusItemSuccess = (items) => ({
    type: ISSUES_STATUSES_ADD_ITEM_SUCCESS,
    payload: items,
});

export const addIssuesStatusItemError = (error) => ({
    type: ISSUES_STATUSES_ADD_ITEM_ERROR,
    payload: error,
});



export const deleteIssuesStatusItem = (item) => ({
    type: ISSUES_STATUSES_DELETE_ITEM,
    payload: item,
});

export const deleteIssuesStatusItemSuccess = (items) => ({
    type: ISSUES_STATUSES_DELETE_ITEM_SUCCESS,
    payload: items,
});

export const deleteIssuesStatusItemError = (error) => ({
    type: ISSUES_STATUSES_DELETE_ITEM_ERROR,
    payload: error,
});

