
import { combineReducers } from 'redux';
import statuses from 'projects/redux/issues/issue_statuses/reducers';
import issues from 'projects/redux/issues/reducers';
import milestones from 'projects/redux/milestones/reducers';
import project from 'projects/redux/projects/details/reducer';

const reducers = combineReducers({
    statuses,
    issues,
    milestones,
    project
});

export default reducers;