import axios from "axios";
import { servicePath } from 'constants/defaultValues';
import { getAccessToken } from 'helpers/Firebase';
import { getCurrentUser } from 'helpers/Utils';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    addProjectItemError, addProjectItemSuccess,
    deleteProjectItemError, deleteProjectItemSuccess,
    getProjectItemError, getProjectItemSuccess,
    saveProjectItemSuccess, saveProjectItemError
} from './actions';
import {
    PROJECTS_ADD_ITEM,
    PROJECTS_DELETE_ITEM, PROJECTS_FETCH_ITEM, PROJECTS_SAVE_ITEM
} from './actions_def';


const apiUrl = servicePath;

const getProjectItemRequest = async (payload) => {
    return await new Promise((success, fail) => {
        const { projectId } = payload;

        const instance = axios.create({
            method: 'get',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });
        // console.log(payload, projectId);
        instance.get('/projects/' + projectId + '/', { crossdomain: true })
            .then(response => success(response.data));
    })
        .then(response => response)
        .catch(error => {
            console.log(error);
        });
};

function* getProjectItem({ payload }) {

    try {
        const response = yield call(getProjectItemRequest, payload);
        yield put(getProjectItemSuccess(response));
    } catch (error) {
        yield put(getProjectItemError(error));
    }
}


const addProjectItemRequest = async (item) => {
    // eslint-disable-next-line no-return-await
    console.log(item)

    return await new Promise((success, fail) => {
        var _user = getCurrentUser(); // JSON.parse(localStorage.getItem("user"));
        const instance = axios.create({
            method: 'post',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + _user.accessToken
            },
        });

        let _sendingPayload = {
            ...item,
            tags: item.tags && item.tags.map(tag => {
                return ({
                    text: tag.label
                })
            })
        };

        instance.interceptors.response.use(response => {
            return response;
        }, error => {
            if (error.response) {
                if (error.response.status === 400) {
                    console.log(error);
                    fail(error.response);
                }
                if (error.response.status === 401) {
                    console.log(error);
                    fail(error.response);
                }
                if (error.response.status === 403) {
                    console.log(error);
                    fail(error.response);
                }
                if (error.response.status === 409) {
                    console.log(error);
                    fail(error.response);
                }
                if (error.response.status === 500) {
                    console.log(error);
                    fail(error.response);
                }
            }
            fail(error);
        });

        instance.post('/projects/',
            _sendingPayload,
            { crossdomain: true })
            .then(response => {
                if (response) {
                    if (response.status !== 200) {
                        console.log(response);
                        fail(response);
                    }
                    else if (response.status === 200) {
                        console.log(response);
                        success(response);
                    }
                    else {
                        console.log('Inconsistent behaviour', response.status)
                    }
                }
            }).catch((error) => {
                console.log(error)
            });;
    })
        .then((response) => response)
        .catch((error) => error);
};

function* addProjectItem({ payload }) {
    // console.log(payload);

    try {
        const response = yield call(addProjectItemRequest, payload);
        console.log('Response :', response, response.status === "fail")
        if (response.status !== "fail") {
            yield put(addProjectItemSuccess(response));
        }
        else {
            yield put(addProjectItemError(response));
        }
    } catch (error) {
        yield put(addProjectItemError(error));
    }
}

const saveProjectsItemRequest = async (item) => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success, fail) => {
        const instance = axios.create({
            method: 'put',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });

        let _sendingPayload = item;

        // console.log(_sendingPayload, getAccessToken());
        instance.put('/projects/',
            _sendingPayload,
            { crossdomain: true })
            .then(response => {
                if (response.data) {
                    if (response.status === 400 || response.status === 500) {
                        fail(response.data);
                    }
                    else if (response.status === 200) {
                        success(response.data);
                    }
                    else {
                        console.log('Inconsistent behaviour', response)
                    }
                }
            }).catch((error) => {
                console.log(error)
            });;
    })
        .then((response) => response)
        .catch((error) => error);
};

function* saveProjectItem({ payload }) {
    // // console.log(payload);
    try {
        const response = yield call(saveProjectsItemRequest, payload.project);
        console.log(response);
        if (response.id) {
            yield put(saveProjectItemSuccess(response));
        }
        else {
            yield put(saveProjectItemError(response));
        }
    } catch (error) {
        yield put(saveProjectItemError(error));
    }
}


const deleteProjectRequest = async (item) => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success, fail) => {
        console.log(item)
        const instance = axios.create({
            method: 'delete',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });

        instance.delete('/projects/' + item.id + '/',
            { crossdomain: true })
            .then(response => {
                if (response.data) {
                    if (response.data.status === "fail") {
                        fail(response.data);
                    }
                    else if (response.data.status === "success" || response.data.status === "ok") {
                        success(response.data);
                    }
                    else {
                        console.log('Inconsistent behaviour', response.data.status);
                    }
                }
            }).catch((error) => {
                console.log(error);
            });
    })
        .then((response) => response)
        .catch((error) => error);
};

function* deleteProjectItem({ payload }) {
    // console.log(payload);
    try {
        const response = yield call(deleteProjectRequest, payload);
        console.log('Response :', response, response.status === "fail")
        if (response.status !== "fail") {
            yield put(deleteProjectItemSuccess(response));
        }
        else {
            yield put(deleteProjectItemError(response));
        }
    } catch (error) {
        yield put(deleteProjectItemError(error));
    }
}


export function* watchFetchItem() {
    yield takeEvery(PROJECTS_FETCH_ITEM, getProjectItem);
}

export function* watchAddItem() {
    yield takeEvery(PROJECTS_ADD_ITEM, addProjectItem);
}

export function* watchSaveItem() {
    yield takeEvery(PROJECTS_SAVE_ITEM, saveProjectItem);
}
export function* watchDeleteItem() {
    yield takeEvery(PROJECTS_DELETE_ITEM, deleteProjectItem);
}


export default function* rootSaga() {
    yield all([
        fork(watchAddItem),
        fork(watchFetchItem),
        fork(watchDeleteItem),
        fork(watchSaveItem)
    ]);
}
