export const SURVEYS_FETCH_ITEM = 'SURVEYS_FETCH_ITEM';
export const SURVEYS_FETCH_ITEM_SUCCESS = 'SURVEYS_FETCH_ITEM_SUCCESS';
export const SURVEYS_FETCH_ITEM_ERROR = 'SURVEYS_FETCH_ITEM_ERROR';

export const SURVEYS_ADD_ITEM = 'SURVEYS_ADD_ITEM';
export const SURVEYS_ADD_ITEM_SUCCESS = 'SURVEYS_ADD_ITEM_SUCCESS';
export const SURVEYS_ADD_ITEM_ERROR = 'SURVEYS_ADD_ITEM_ERROR';

export const SURVEYS_DELETE_ITEM = 'SURVEYS_DELETE_ITEM';
export const SURVEYS_DELETE_ITEM_SUCCESS = 'SURVEYS_DELETE_ITEM_SUCCESS';
export const SURVEYS_DELETE_ITEM_ERROR = 'SURVEYS_DELETE_ITEM_ERROR';

export const SURVEYS_DELETE_QUESTION = 'SURVEYS_DELETE_QUESTION';
export const SURVEYS_DELETE_QUESTION_SUCCESS = 'SURVEYS_DELETE_QUESTION_SUCCESS';
export const SURVEYS_DELETE_QUESTION_ERROR = 'SURVEYS_DELETE_QUESTION_ERROR';

export const SURVEYS_ADD_QUESTION = 'SURVEYS_ADD_QUESTION';
export const SURVEYS_ADD_QUESTION_SUCCESS = 'SURVEYS_ADD_QUESTION_SUCCESS';
export const SURVEYS_ADD_QUESTION_ERROR = 'SURVEYS_ADD_QUESTION_ERROR';

export const SURVEYS_SAVE_ITEM = 'SURVEYS_SAVE_ITEM';
export const SURVEYS_SAVE_ITEM_SUCCESS = 'SURVEYS_SAVE_ITEM_SUCCESS';
export const SURVEYS_SAVE_ITEM_ERROR = 'SURVEYS_SAVE_ITEM_ERROR';
