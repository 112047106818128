// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import {
  TAGS_FETCH_ITEM,
  TAGS_FETCH_ITEM_SUCCESS,
  TAGS_FETCH_ITEM_ERROR,
  TAGS_ADD_ITEM,
  TAGS_ADD_ITEM_SUCCESS,
  TAGS_ADD_ITEM_ERROR,
  TAGS_DELETE_ITEM,
  TAGS_DELETE_ITEM_SUCCESS,
  TAGS_DELETE_ITEM_ERROR,
} from './actions_def';

export const getTagItem = (item) => ({
  type: TAGS_FETCH_ITEM,
  payload: item,
});

export const getTagItemSuccess = (items) => ({
  type: TAGS_FETCH_ITEM_SUCCESS,
  payload: items,
});

export const getTagItemError = (error) => ({
  type: TAGS_FETCH_ITEM_ERROR,
  payload: error,
});


export const addTagItem = (item) => ({
  type: TAGS_ADD_ITEM,
  payload: item,
});

export const addTagItemSuccess = (items) => ({
  type: TAGS_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addTagItemError = (error) => ({
  type: TAGS_ADD_ITEM_ERROR,
  payload: error,
});



export const deleteTagItem = (item) => ({
  type: TAGS_DELETE_ITEM,
  payload: item,
});

export const deleteTagItemSuccess = (items) => ({
  type: TAGS_DELETE_ITEM_SUCCESS,
  payload: items,
});

export const deleteTagItemError = (error) => ({
  type: TAGS_DELETE_ITEM_ERROR,
  payload: error,
});

