export const WORK_FETCH_ITEM = 'WORK_FETCH_ITEM';
export const WORK_FETCH_ITEM_SUCCESS = 'WORK_FETCH_ITEM_SUCCESS';
export const WORK_FETCH_ITEM_ERROR = 'WORK_FETCH_ITEM_ERROR';

export const WORK_RESET_ITEM = 'WORK_RESET_ITEM';
export const WORK_ADD_ITEM = 'WORK_ADD_ITEM';
export const WORK_ADD_ITEM_SUCCESS = 'WORK_ADD_ITEM_SUCCESS';
export const WORK_ADD_ITEM_ERROR = 'WORK_ADD_ITEM_ERROR';

export const WORK_DELETE_ITEM = 'WORK_DELETE_ITEM';
export const WORK_DELETE_ITEM_SUCCESS = 'WORK_DELETE_ITEM_SUCCESS';
export const WORK_DELETE_ITEM_ERROR = 'WORK_DELETE_ITEM_ERROR';

export const WORK_SAVE_ITEM = 'WORK_SAVE_ITEM';
export const WORK_SAVE_ITEM_SUCCESS = 'WORK_SAVE_ITEM_SUCCESS';
export const WORK_SAVE_ITEM_ERROR = 'WORK_SAVE_ITEM_ERROR';
