import {
    PROJECTS_ADD_ITEM,
    PROJECTS_ADD_ITEM_SUCCESS,
    PROJECTS_ADD_ITEM_ERROR,
    PROJECTS_RESET_ITEM,
    PROJECTS_FETCH_ITEM,
    PROJECTS_FETCH_ITEM_SUCCESS,
    PROJECTS_FETCH_ITEM_ERROR,
    PROJECTS_DELETE_ITEM,
    PROJECTS_DELETE_ITEM_SUCCESS,
    PROJECTS_DELETE_ITEM_ERROR,
    PROJECTS_SAVE_ITEM,
    PROJECTS_SAVE_ITEM_SUCCESS,
    PROJECTS_SAVE_ITEM_ERROR,
} from './actions_def';

const INIT_STATE = {
    project: null,
    errors: [],
    isLoading: false,
    isLoaded: false,
    isSaving: false,
    isSaved: false,
    hasError: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case PROJECTS_RESET_ITEM:
            return INIT_STATE;


        case PROJECTS_FETCH_ITEM:
            // console.log(action.payload, state);
            return { ...state, isLoaded: false, isLoading: true };

        case PROJECTS_FETCH_ITEM_SUCCESS:
            // console.log(action.payload);
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                project: action.payload,
            };

        case PROJECTS_FETCH_ITEM_ERROR:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: action.payload
            };

        case PROJECTS_ADD_ITEM:
            return {
                ...state,
                isLoaded: false,
                isLoading: true,
                isSaving: true,
                isSaved: false,
                hasError: false,
            };

        case PROJECTS_ADD_ITEM_SUCCESS:
            // console.log(action);
            if (action.payload) {
                if (action.payload.status !== 200 && action.payload.status !== 201) {
                    // console.log("Failed", action.payload.status)

                    return {
                        ...state,
                        isLoading: false,
                        isLoaded: false,
                        hasErrors: true,
                        saving: false,
                        errors: action.payload.data
                    };
                }
                else {
                    // console.log(action);
                    return {
                        ...state,
                        isLoaded: true,
                        isLoading: false,
                        isSaving: false,
                        isSaved: false,
                        hasError: false,
                        errors: null,
                        project: { id: action.payload.data },
                    };
                }
            }
            break;
        // else if (action.payload.data) {
        //     console.log(action);
        //     return {
        //         ...state,
        //         isLoading: false,
        //         isLoaded: false,
        //         hasErrors: true,
        //         saving: false,
        //         errors: action.payload.data
        //     };
        // }
        // else {
        //     console.log(action);
        //     return {
        //         ...state,
        //         isLoading: false,
        //         isLoaded: false,
        //         hasErrors: true,
        //         saving: false,
        //         errors: 'error-unknown'
        //     };
        // }


        case PROJECTS_ADD_ITEM_ERROR:
            // console.log("Failed - no-project-profile", action.payload)

            if (action.payload.errors && action.payload.errors[0].error === 'no-project-profile') {
                console.log("Failed - no-project-profile", action.payload)

                return {
                    ...state,
                    isLoading: false,
                    isLoaded: false,
                    isSaving: false,
                    isSaved: false,
                    hasErrors: true,
                    errors: action.payload.errors && action.payload.errors.map(error => (
                        {
                            field: error.field,
                            error_code: error.code
                        }
                    ))
                };
            }


            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                isSaving: false,
                isSaved: false,
                hasErrors: true,
                errors: action.payload.errors && action.payload.errors.map(error => (
                    {
                        field: error.field,
                        error_code: error.code
                    }
                ))
            };

        case PROJECTS_DELETE_ITEM:
            return { ...state, saving: true };

        case PROJECTS_DELETE_ITEM_SUCCESS:
            // console.log("Success", state.allProjectsItems.filter(item => item && item.id !== action.payload.rfc_id))
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                isSaving: false,
                isSaved: true,
                hasErrors: false,
                errors: [],

                allProjectsItems: state.allProjectsItems.filter(item => item && item.id !== action.payload.rfc_id),
                projects: state.projects.filter(item => item && item.id !== action.payload.rfc_id),
            };

        case PROJECTS_DELETE_ITEM_ERROR:
            console.log("Failed", action.payload)
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                isSaving: false,
                isSaved: false,
                hasErrors: true,
                errors: action.payload.errors.map(error => (
                    {
                        field: error.field,
                        error_code: error.code
                    }
                ))
            };


        case PROJECTS_SAVE_ITEM_SUCCESS:
            // console.log("Success", {
            //     ...state,
            //     isLoading: true,
            //     isSaving: false,
            //     isSaved: true,
            //     hasErrors: false,
            //     errors: [],

            //     project: action.payload,
            // })

            return {
                ...state,
                isLoading: true,
                isSaving: false,
                isSaved: true,
                hasErrors: false,
                errors: [],

                project: action.payload,
            };

        case PROJECTS_SAVE_ITEM_ERROR:
            console.log("Failed", action.payload)
            return {
                ...state,
                isLoading: false,
                isSaving: false,
                isSaved: false,
                hasErrors: true,
                errors: action.payload.errors
                    ? action.payload.errors.map(error => (
                        {
                            field: error.field,
                            error_code: error.code
                        }
                    ))
                    : action.payload

            };

        case PROJECTS_SAVE_ITEM:
            console.log("PROJECTS_SAVE_ITEM")
            return {
                ...state,
                isSaving: true,
                isSaved: false,
                hasErrors: false,
            };

        default:
            return { ...state };
    }
};
