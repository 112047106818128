export const UserRole = {
    Admin: 0,
    Editor: 1,
    Guest: 3,
    View: 4,
}

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default'

export const subHiddenBreakpoint = 1440
export const menuHiddenBreakpoint = 768
export const defaultLocale = 'he'
export const localeOptions = [
    { id: 'en', name: 'English', direction: 'ltr' },
    { id: 'he', name: 'עברית', direction: 'rtl' },
]

export const firebaseConfig = {
    apiKey: 'AIzaSyAvnbqfN2xMyFp7kdpD1v8FnGCovZDjRoU',
    authDomain: 'appcoil.firebaseapp.com',
    databaseURL: 'https://appcoil.firebaseio.com',
    projectId: 'appcoil',
    storageBucket: 'appcoil.appspot.com',
    messagingSenderId: '899791235923',
    appId: 'BKXWtCg7j13pSicNY95cxQU7je3wVcIuzZKXB5qJDhN8NPzZ4Qh4AOc4EsG6fxQRx0iUCaA8HMntQiPANi6QVLg',
}

export const currentUser = {
    isLoaded: false,
    id: 0,
    title: '',
    img: '',
    date: '',
    role: UserRole.View,
}

export const adminRoot = '/app'
export const demoRoot = '/demo'
export const buyUrl = 'https://app.co.il/build'
export const loginUrl = '/login'

export const searchPath = `${adminRoot}/pages/miscellaneous/search`
export const servicePath = 'https://api.app.co.il/api'
// export const servicePath = 'https://localhost:5001/api';

export const cdnUsersDataPath = 'https://cdn.app.co.il/u'
export const themeColorStorageKey = '__theme_selected_color'
export const isMultiColorActive = true
export const defaultColor = 'light.purplemonster'
export const isDarkSwitchActive = true
export const defaultDirection = 'rtl'
export const themeRadiusStorageKey = '__theme_radius'
export const isAuthGuardActive = true
export const colors = [
    'bluenavy',
    'blueyale',
    'blueolympic',
    'greenmoss',
    'greenlime',
    'purplemonster',
    'orangecarrot',
    'redruby',
    'yellowgranola',
    'greysteel',
]
