// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import {
  EXPERTS_FETCH_ITEM,
  EXPERTS_FETCH_ITEM_SUCCESS,
  EXPERTS_FETCH_ITEM_ERROR,
  EXPERTS_ADD_ITEM,
  EXPERTS_ADD_ITEM_SUCCESS,
  EXPERTS_ADD_ITEM_ERROR,
  EXPERTS_SAVE_ITEM,
  EXPERTS_SAVE_ITEM_SUCCESS,
  EXPERTS_SAVE_ITEM_ERROR,
  EXPERTS_DELETE_ITEM,
  EXPERTS_DELETE_ITEM_SUCCESS,
  EXPERTS_DELETE_ITEM_ERROR,
  EXPERTS_SELECTED_ITEMS_CHANGE
} from './actions_def';

export const getExpertItem = (item) => ({
  type: EXPERTS_FETCH_ITEM,
  payload: item,
});

export const getExpertItemSuccess = (items) => ({
  type: EXPERTS_FETCH_ITEM_SUCCESS,
  payload: items,
});

export const getExpertItemError = (error) => ({
  type: EXPERTS_FETCH_ITEM_ERROR,
  payload: error,
});


export const addExpertsItem = (item) => ({
  type: EXPERTS_ADD_ITEM,
  payload: item,
});

export const addExpertsItemSuccess = (items) => ({
  type: EXPERTS_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addExpertsItemError = (error) => ({
  type: EXPERTS_ADD_ITEM_ERROR,
  payload: error,
});


export const saveExpertItem = (item) => ({
  type: EXPERTS_SAVE_ITEM,
  payload: item,
});

export const saveExpertItemSuccess = (items) => ({
  type: EXPERTS_SAVE_ITEM_SUCCESS,
  payload: items,
});

export const saveExpertItemError = (error) => ({
  type: EXPERTS_SAVE_ITEM_ERROR,
  payload: error,
});


export const deleteExpertItem = (item) => ({
  type: EXPERTS_DELETE_ITEM,
  payload: item,
});

export const deleteExpertItemSuccess = (items) => ({
  type: EXPERTS_DELETE_ITEM_SUCCESS,
  payload: items,
});

export const deleteExpertItemError = (error) => ({
  type: EXPERTS_DELETE_ITEM_ERROR,
  payload: error,
});



export const selecteExpertsItemsChange = (selectedItems) => ({
  type: EXPERTS_SELECTED_ITEMS_CHANGE,
  payload: selectedItems,
});
