// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import {
  TODOS_FETCH_ITEM,
  TODOS_FETCH_ITEM_SUCCESS,
  TODOS_FETCH_ITEM_ERROR,
  TODOS_ADD_ITEM,
  TODOS_ADD_ITEM_SUCCESS,
  TODOS_ADD_ITEM_ERROR,
  TODOS_DELETE_ITEM,
  TODOS_DELETE_ITEM_SUCCESS,
  TODOS_DELETE_ITEM_ERROR,
} from './actions_def';

export const getToDoItem = (item) => ({
  type: TODOS_FETCH_ITEM,
  payload: item,
});

export const getToDoItemSuccess = (items) => ({
  type: TODOS_FETCH_ITEM_SUCCESS,
  payload: items,
});

export const getToDoItemError = (error) => ({
  type: TODOS_FETCH_ITEM_ERROR,
  payload: error,
});


export const addToDoItem = (item) => ({
  type: TODOS_ADD_ITEM,
  payload: item,
});

export const addToDoItemSuccess = (items) => ({
  type: TODOS_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addToDoItemError = (error) => ({
  type: TODOS_ADD_ITEM_ERROR,
  payload: error,
});



export const deleteToDoItem = (item) => ({
  type: TODOS_DELETE_ITEM,
  payload: item,
});

export const deleteToDoItemSuccess = (items) => ({
  type: TODOS_DELETE_ITEM_SUCCESS,
  payload: items,
});

export const deleteToDoItemError = (error) => ({
  type: TODOS_DELETE_ITEM_ERROR,
  payload: error,
});

