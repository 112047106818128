import {
    TODOS_DELETE_ITEMS,
    TODOS_DELETE_ITEMS_ERROR,
    TODOS_DELETE_ITEMS_SUCCESS,
    TODOS_GET_LIST,
    TODOS_GET_LIST_ERROR,
    TODOS_GET_LIST_SEARCH,
    TODOS_GET_LIST_SUCCESS,
    TODOS_GET_LIST_WITH_FILTER,
    TODOS_GET_LIST_WITH_ORDER,
    TODOS_SELECTED_ITEMS_CHANGE
} from './actions_def';

const INIT_STATE = {
    allToDoItems: [],
    todosItems: [],
    errors: [],
    filter: null,
    searchKeyword: '',
    orderColumn: null,
    isLoaded: false,
    isLoading: false,

    saving: false,
    labels: [
        { label: 'EDUCATION', color: 'secondary' },
        { label: 'NEW FRAMEWORK', color: 'primary' },
        { label: 'PERSONAL', color: 'info' },
    ],
    orderColumns: [
        { column: 'title', label: 'Title' },
        { column: 'category', label: 'Category' },
        { column: 'status', label: 'Status' },
        { column: 'label', label: 'Label' },
    ],
    categories: ['Flexbox', 'Sass', 'React'],
    selectedItems: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case TODOS_GET_LIST:
            console.log(action.payload)

            return {
                ...state,
                isLoaded: false,
                isLoading: true,
            };

        case TODOS_GET_LIST_SUCCESS:
            console.log(action.payload)
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                allToDoItems: action.payload,
                todosItems: action.payload,
            };

        case TODOS_GET_LIST_ERROR:
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                error: action.payload
            };

        case TODOS_GET_LIST_WITH_FILTER:
            if (action.payload.column === '' || action.payload.value === '') {
                return {
                    ...state,
                    isLoaded: true,
                    todosItems: state.allToDoItems,
                    filter: null,
                };
            }
            // eslint-disable-next-line no-case-declarations
            const filteredItems = state.allToDoItems.filter(
                (item) => item[action.payload.column] === action.payload.value
            );
            return {
                ...state,
                isLoaded: true,
                todosItems: filteredItems,
                filter: {
                    column: action.payload.column,
                    value: action.payload.value,
                },
            };

        case TODOS_GET_LIST_WITH_ORDER:
            if (action.payload === '') {
                return {
                    ...state,
                    isLoaded: true,
                    todosItems: state.todosItems,
                    orderColumn: null,
                };
            }
            // eslint-disable-next-line no-case-declarations
            const sortedItems = state.todosItems.sort((a, b) => {
                if (a[action.payload] < b[action.payload]) return -1;
                if (a[action.payload] > b[action.payload]) return 1;
                return 0;
            });
            return {
                ...state,
                isLoaded: true,
                todosItems: sortedItems,
                orderColumn: state.orderColumns.find(
                    (x) => x.column === action.payload
                ),
            };

        case TODOS_GET_LIST_SEARCH:
            if (action.payload === '') {
                return { ...state, todosItems: state.allToDoItems };
            }
            // eslint-disable-next-line no-case-declarations
            const keyword = action.payload.toLowerCase();
            // eslint-disable-next-line no-case-declarations
            const searchItems = state.allToDoItems.filter(
                (item) =>
                    item.title.toLowerCase().indexOf(keyword) > -1 ||
                    item.detail.toLowerCase().indexOf(keyword) > -1 ||
                    item.status.toLowerCase().indexOf(keyword) > -1 ||
                    item.category.toLowerCase().indexOf(keyword) > -1 ||
                    item.label.toLowerCase().indexOf(keyword) > -1
            );
            return {
                ...state,
                isLoaded: true,
                todosItems: searchItems,
                searchKeyword: action.payload,
            };

        case TODOS_SELECTED_ITEMS_CHANGE:
            return { ...state, isLoaded: true, selectedItems: action.payload };

        case TODOS_DELETE_ITEMS:
            return {
                ...state,
                isLoaded: false,
                saving: true
            };

        case TODOS_DELETE_ITEMS_SUCCESS:

            return {
                ...state,
                isLoaded: true,
                saving: false,
                saved: true,
                errors: [],
                allToDoItems: state.allToDoItems.filter(item => item.id !== action.payload.rfc_id),
                todosItems: state.todosItems.filter(item => item.id !== action.payload.rfc_id),
            };

        case TODOS_DELETE_ITEMS_ERROR:
            console.log("Failed", action.payload)
            return {
                ...state,
                isLoaded: true,
                saving: false,
                saved: true,
                errors: action.payload.errors.map(error => (
                    {
                        field: error.field,
                        error_code: error.code
                    }
                ))
            };

        default:
            return { ...state };
    }
};
