// eslint-disable-next-line import/no-cycle
import {
    SURVEYS_DELETE_ITEMS,
    SURVEYS_DELETE_ITEMS_ERROR, SURVEYS_DELETE_ITEMS_SUCCESS, SURVEYS_GET_LIST,
    SURVEYS_GET_LIST_ERROR,
    SURVEYS_GET_LIST_SEARCH, SURVEYS_GET_LIST_SUCCESS,
    SURVEYS_GET_LIST_WITH_FILTER,
    SURVEYS_GET_LIST_WITH_ORDER,
    SURVEYS_SELECTED_ITEMS_CHANGE
} from './actions_def';
export const getSurveysList = (payload) => ({
    type: SURVEYS_GET_LIST,
    payload: payload,
});

export const getMyPostsList = (payload) => ({
    type: SURVEYS_GET_LIST,
    payload: payload,
});

export const getSurveysListSuccess = (items) => ({
    type: SURVEYS_GET_LIST_SUCCESS,
    payload: items,
});

export const getSurveysListError = (error) => ({
    type: SURVEYS_GET_LIST_ERROR,
    payload: error,
});

export const getSurveysListWithFilter = (column, value) => ({
    type: SURVEYS_GET_LIST_WITH_FILTER,
    payload: { column, value },
});

export const getSurveysListWithOrder = (column) => ({
    type: SURVEYS_GET_LIST_WITH_ORDER,
    payload: column,
});

export const getSurveysListSearch = (keyword) => ({
    type: SURVEYS_GET_LIST_SEARCH,
    payload: keyword,
});

export const selectedSurveyItemsChange = (selectedItems) => ({
    type: SURVEYS_SELECTED_ITEMS_CHANGE,
    payload: selectedItems,
});



export const deleteSurveysList = (items) => ({
    type: SURVEYS_DELETE_ITEMS,
    payload: items,
});

export const deleteSurveysListSuccess = (items) => ({
    type: SURVEYS_DELETE_ITEMS_SUCCESS,
    payload: items,
});

export const deleteSurveysListError = (error) => ({
    type: SURVEYS_DELETE_ITEMS_ERROR,
    payload: error,
});
