// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import {
    SURVEYS_FETCH_ITEM,
    SURVEYS_FETCH_ITEM_SUCCESS,
    SURVEYS_FETCH_ITEM_ERROR,
    SURVEYS_ADD_ITEM,
    SURVEYS_ADD_ITEM_SUCCESS,
    SURVEYS_ADD_ITEM_ERROR,
    SURVEYS_DELETE_ITEM,
    SURVEYS_DELETE_ITEM_SUCCESS,
    SURVEYS_DELETE_ITEM_ERROR,
    SURVEYS_DELETE_QUESTION,
    SURVEYS_DELETE_QUESTION_SUCCESS,
    SURVEYS_DELETE_QUESTION_ERROR,
    SURVEYS_SAVE_ITEM,
    SURVEYS_SAVE_ITEM_SUCCESS,
    SURVEYS_SAVE_ITEM_ERROR,
} from './actions_def';

export const getSurveyItem = (item) => ({
    type: SURVEYS_FETCH_ITEM,
    payload: item,
});

export const getSurveyItemSuccess = (items) => ({
    type: SURVEYS_FETCH_ITEM_SUCCESS,
    payload: items,
});

export const getSurveyItemError = (error) => ({
    type: SURVEYS_FETCH_ITEM_ERROR,
    payload: error,
});


export const addSurveyItem = (item) => ({
    type: SURVEYS_ADD_ITEM,
    payload: item,
});

export const addSurveyItemSuccess = (items) => ({
    type: SURVEYS_ADD_ITEM_SUCCESS,
    payload: items,
});

export const addSurveyItemError = (error) => ({
    type: SURVEYS_ADD_ITEM_ERROR,
    payload: error,
});



export const deleteSurveyItem = (item) => ({
    type: SURVEYS_DELETE_ITEM,
    payload: item,
});

export const deleteSurveyItemSuccess = (items) => ({
    type: SURVEYS_DELETE_ITEM_SUCCESS,
    payload: items,
});

export const deleteSurveyItemError = (error) => ({
    type: SURVEYS_DELETE_ITEM_ERROR,
    payload: error,
});



export const deleteSurveyQuestion = (item) => ({
    type: SURVEYS_DELETE_QUESTION,
    payload: item,
});

export const deleteSurveyQuestionSuccess = (items) => ({
    type: SURVEYS_DELETE_QUESTION_SUCCESS,
    payload: items,
});

export const deleteSurveyQuestionError = (error) => ({
    type: SURVEYS_DELETE_QUESTION_ERROR,
    payload: error,
});




export const deleteQuestion = (item) => ({
    type: SURVEYS_DELETE_ITEM,
    payload: item,
});

export const deleteQuestionSuccess = (items) => ({
    type: SURVEYS_DELETE_ITEM_SUCCESS,
    payload: items,
});

export const deleteQuestionError = (error) => ({
    type: SURVEYS_DELETE_ITEM_ERROR,
    payload: error,
});


export const saveSurvey = (item) => ({
    type: SURVEYS_SAVE_ITEM,
    payload: item,
});

export const saveSurveySuccess = (items) => ({
    type: SURVEYS_SAVE_ITEM_SUCCESS,
    payload: items,
});

export const saveSurveyError = (error) => ({
    type: SURVEYS_SAVE_ITEM_ERROR,
    payload: error,
});

