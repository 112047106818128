// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import {
    ISSUES_PRIORITIES_RESET_ITEM,
    ISSUES_PRIORITIES_FETCH_ITEM,
    ISSUES_PRIORITIES_FETCH_ITEM_SUCCESS,
    ISSUES_PRIORITIES_FETCH_ITEM_ERROR,
    ISSUES_PRIORITIES_SAVE_ITEM,
    ISSUES_PRIORITIES_SAVE_ITEM_SUCCESS,
    ISSUES_PRIORITIES_SAVE_ITEM_ERROR,
    ISSUES_PRIORITIES_ADD_ITEM,
    ISSUES_PRIORITIES_ADD_ITEM_SUCCESS,
    ISSUES_PRIORITIES_ADD_ITEM_ERROR,
    ISSUES_PRIORITIES_DELETE_ITEM,
    ISSUES_PRIORITIES_DELETE_ITEM_SUCCESS,
    ISSUES_PRIORITIES_DELETE_ITEM_ERROR,
} from './actions_def';


export const resetIssuesPriorityItem = (item) => ({
    type: ISSUES_PRIORITIES_RESET_ITEM,
    payload: item,
});


export const saveIssuesPriorityItem = (item) => ({
    type: ISSUES_PRIORITIES_SAVE_ITEM,
    payload: item,
});


export const saveIssuesPriorityItemSuccess = (items) => ({
    type: ISSUES_PRIORITIES_SAVE_ITEM_SUCCESS,
    payload: items,
});

export const saveIssuesPriorityItemError = (error) => ({
    type: ISSUES_PRIORITIES_SAVE_ITEM_ERROR,
    payload: error,
});


export const getIssuesPriorityItem = (item) => ({
    type: ISSUES_PRIORITIES_FETCH_ITEM,
    payload: item,
});

export const getIssuesPriorityItemSuccess = (items) => ({
    type: ISSUES_PRIORITIES_FETCH_ITEM_SUCCESS,
    payload: items,
});

export const getIssuesPriorityItemError = (error) => ({
    type: ISSUES_PRIORITIES_FETCH_ITEM_ERROR,
    payload: error,
});


export const addIssuesPriorityItem = (item) => ({
    type: ISSUES_PRIORITIES_ADD_ITEM,
    payload: item,
});

export const addIssuesPriorityItemSuccess = (items) => ({
    type: ISSUES_PRIORITIES_ADD_ITEM_SUCCESS,
    payload: items,
});

export const addIssuesPriorityItemError = (error) => ({
    type: ISSUES_PRIORITIES_ADD_ITEM_ERROR,
    payload: error,
});



export const deleteIssuesPriorityItem = (item) => ({
    type: ISSUES_PRIORITIES_DELETE_ITEM,
    payload: item,
});

export const deleteIssuesPriorityItemSuccess = (items) => ({
    type: ISSUES_PRIORITIES_DELETE_ITEM_SUCCESS,
    payload: items,
});

export const deleteIssuesPriorityItemError = (error) => ({
    type: ISSUES_PRIORITIES_DELETE_ITEM_ERROR,
    payload: error,
});

