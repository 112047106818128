import { firebaseConfig } from 'constants/defaultValues';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
// import 'firebase/messaging';

import { getCurrentUser, setCurrentUser } from 'helpers/Utils';



if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}


const auth = firebase.auth();
const database = firebase.database();
// const messaging = firebase.messaging();

// messaging.onBackgroundMessage(function (payload) {
//   console.log('Received background message ', payload);

//   const notificationTitle = payload.notification.title;
//   const notificationOptions = {
//     body: payload.nostification.body,
//   };

// });

// auth.onIdTokenChanged(user => {
//   console.log('onIdTokenChanged', user);
//   // if (user !== null) {
//   //   setCurrentUser({
//   //     ...getCurrentUser(),
//   //     uid: user.uid,
//   //     accessToken: user._lat,
//   //     refreshToken: user.refreshToken,
//   //     expirationTime: user.b.b.a,
//   //   });
//   // }
//   // else {
//   //   setCurrentUser();
//   // }
// });


// auth.onAuthStateChanged(user => {
//   console.log('onAuthStateChanged', user);
//   // if (user !== null) {
//   //   setCurrentUser({
//   //     ...getCurrentUser(),
//   //     uid: user.uid,
//   //     accessToken: user._lat,
//   //     refreshToken: user.refreshToken,
//   //     expirationTime: user.b.b.a,
//   //   });
//   // }
//   // else {
//   //   setCurrentUser();
//   // }

// });

export const getAccessToken = () => {
    var _user = JSON.parse(localStorage.getItem("_current_user"));
    // console.log(_user);
    if (!_user) {
        console.log('No user')
        refreshCredentials();
    }
    // else if (!_user.expirationTime) {
    //   console.log('No expiration time')
    //   refreshCredentials();
    // }
    // else {
    //   var now = new Date();
    //   var d = new Date(_user.expirationTime);
    //   console.log(d - now);
    //   if (now >= d) {
    //     refreshCredentials();
    //   }
    // }

    return _user.accessToken;
}

export const refreshCredentials = () => {
    // firebase.auth().onAuthStateChanged(user => {

}

export { auth, database };
