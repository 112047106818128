export const MILESTONES_FETCH_ITEM = 'MILESTONES_FETCH_ITEM';
export const MILESTONES_FETCH_ITEM_SUCCESS = 'MILESTONES_FETCH_ITEM_SUCCESS';
export const MILESTONES_FETCH_ITEM_ERROR = 'MILESTONES_FETCH_ITEM_ERROR';

export const MILESTONES_ADD_ITEM = 'MILESTONES_ADD_ITEM';
export const MILESTONES_ADD_ITEM_SUCCESS = 'MILESTONES_ADD_ITEM_SUCCESS';
export const MILESTONES_ADD_ITEM_ERROR = 'MILESTONES_ADD_ITEM_ERROR';

export const MILESTONES_DELETE_ITEM = 'MILESTONES_DELETE_ITEM';
export const MILESTONES_DELETE_ITEM_SUCCESS = 'MILESTONES_DELETE_ITEM_SUCCESS';
export const MILESTONES_DELETE_ITEM_ERROR = 'MILESTONES_DELETE_ITEM_ERROR';
