export const ISSUES_STATUSES_RESET_ITEM = 'STATUSES_RESET_ITEM';

export const ISSUES_STATUSES_FETCH_ITEM = 'STATUSES_FETCH_ITEM';
export const ISSUES_STATUSES_FETCH_ITEM_SUCCESS = 'STATUSES_FETCH_ITEM_SUCCESS';
export const ISSUES_STATUSES_FETCH_ITEM_ERROR = 'STATUSES_FETCH_ITEM_ERROR';

export const ISSUES_STATUSES_ADD_ITEM = 'STATUSES_ADD_ITEM';
export const ISSUES_STATUSES_ADD_ITEM_SUCCESS = 'STATUSES_ADD_ITEM_SUCCESS';
export const ISSUES_STATUSES_ADD_ITEM_ERROR = 'STATUSES_ADD_ITEM_ERROR';

export const ISSUES_STATUSES_DELETE_ITEM = 'STATUSES_DELETE_ITEM';
export const ISSUES_STATUSES_DELETE_ITEM_SUCCESS = 'STATUSES_DELETE_ITEM_SUCCESS';
export const ISSUES_STATUSES_DELETE_ITEM_ERROR = 'STATUSES_DELETE_ITEM_ERROR';

export const ISSUES_STATUSES_SAVE_ITEM = 'STATUSES_DELETE_ITEM_ERROR';
export const ISSUES_STATUSES_SAVE_ITEM_SUCCESS = 'STATUSES_DELETE_ITEM_ERROR';
export const ISSUES_STATUSES_SAVE_ITEM_ERROR = 'STATUSES_DELETE_ITEM_ERROR';