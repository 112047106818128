export const ISSUES_RESOLUTIONS_GET_LIST = 'ISSUES_RESOLUTIONS_GET_LIST';
export const ISSUES_RESOLUTIONS_GET_LIST_SUCCESS = 'ISSUES_RESOLUTIONS_GET_LIST_SUCCESS';
export const ISSUES_RESOLUTIONS_GET_LIST_ERROR = 'ISSUES_RESOLUTIONS_GET_LIST_ERROR';
export const ISSUES_RESOLUTIONS_GET_LIST_WITH_FILTER = 'ISSUES_RESOLUTIONS_GET_LIST_WITH_FILTER';
export const ISSUES_RESOLUTIONS_GET_LIST_WITH_ORDER = 'ISSUES_RESOLUTIONS_GET_LIST_WITH_ORDER';
export const ISSUES_RESOLUTIONS_GET_LIST_SEARCH = 'ISSUES_RESOLUTIONS_GET_LIST_SEARCH';


export const ISSUES_RESOLUTIONS_DELETE_ITEMS = 'ISSUES_RESOLUTIONS_DELETE_ITEMS';
export const ISSUES_RESOLUTIONS_DELETE_ITEMS_SUCCESS = 'ISSUES_RESOLUTIONS_DELETE_ITEMS_SUCCESS';
export const ISSUES_RESOLUTIONS_DELETE_ITEMS_ERROR = 'ISSUES_RESOLUTIONS_DELETE_ITEMS_ERROR';


export const ISSUES_RESOLUTIONS_INITIALIZE_LIST = 'ISSUES_RESOLUTIONS_INITIALIZE_LIST';
export const ISSUES_RESOLUTIONS_INITIALIZE_LIST_SUCCESS = 'ISSUES_RESOLUTIONS_INITIALIZE_LIST_SUCCESS';
export const ISSUES_RESOLUTIONS_INITIALIZE_LIST_ERROR = 'ISSUES_RESOLUTIONS_INITIALIZE_LIST_ERROR';

export const ISSUES_RESOLUTIONS_SELECTED_ITEMS_CHANGE = 'ISSUES_RESOLUTIONS_SELECTED_ITEMS_CHANGE';
