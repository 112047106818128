// eslint-disable-next-line import/no-cycle
import {
    RESET_ACTIONS,
    LOGIN_USER,
    LOGIN_USER_PROVIDER,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    LOGIN_USER_ERROR,
    REGISTER_USER_ERROR,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    FETCH_USER_PROFILE,
    FETCH_USER_PROFILE_SUCCESS,
    FETCH_USER_PROFILE_ERROR,
    FETCH_LOCAL_USER_PROFILE,
    FETCH_LOCAL_USER_PROFILE_SUCCESS,
    FETCH_LOCAL_USER_PROFILE_ERROR,
    SAVE_USER_PROFILE,
    SAVE_USER_PROFILE_SUCCESS,
    SAVE_USER_PROFILE_ERROR,
    CREATE_USER_PROFILE,
    CREATE_USER_PROFILE_SUCCESS,
    CREATE_USER_PROFILE_ERROR,
    SET_USER_PROFILE,
    SET_USER_PROFILE_SUCCESS,
    SET_USER_PROFILE_ERROR,
    DELETE_USER_PROFILE,
    DELETE_USER_PROFILE_SUCCESS,
    DELETE_USER_PROFILE_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
} from './actions_def';

export const resetActions = () => ({
    type: RESET_ACTIONS,
});


export const fetchUserProfile = (user, history) => ({
    type: FETCH_USER_PROFILE,
    payload: { user, history },
});

export const fetchUserProfileSuccess = (user) => ({
    type: FETCH_USER_PROFILE_SUCCESS,
    payload: user,
});
export const fetchUserProfileError = (message) => ({
    type: FETCH_USER_PROFILE_ERROR,
    payload: { message },
});
export const fetchLocalUserProfile = (user, history) => ({
    type: FETCH_LOCAL_USER_PROFILE,
    payload: { user, history },
});
export const fetchLocalUserProfileSuccess = (user) => ({
    type: FETCH_LOCAL_USER_PROFILE_SUCCESS,
    payload: user,
});
export const fetchLocalUserProfileError = (message) => ({
    type: FETCH_LOCAL_USER_PROFILE_ERROR,
    payload: { message },
});



export const saveUserProfile = (user) => ({
    type: SAVE_USER_PROFILE,
    payload: { user },
});

export const saveUserProfileSuccess = (user) => ({
    type: SAVE_USER_PROFILE_SUCCESS,
    payload: user,
});
export const saveUserProfileError = (message) => ({
    type: SAVE_USER_PROFILE_ERROR,
    payload: { message },
});


export const createUserProfile = (user) => ({
    type: CREATE_USER_PROFILE,
    payload: { user },
});

export const createUserProfileSuccess = (user) => ({
    type: CREATE_USER_PROFILE_SUCCESS,
    payload: user,
});
export const createUserProfileError = (message) => ({
    type: CREATE_USER_PROFILE_ERROR,
    payload: { message },
});



export const setUserProfile = (user) => ({
    type: SET_USER_PROFILE,
    payload: { user },
});

export const setUserProfileSuccess = (user) => ({
    type: SET_USER_PROFILE_SUCCESS,
    payload: user,
});
export const setUserProfileError = (message) => ({
    type: SET_USER_PROFILE_ERROR,
    payload: { message },
});


export const deleteUserProfile = (user, history) => ({
    type: DELETE_USER_PROFILE,
    payload: { user, history },
});

export const deleteUserProfileSuccess = (user) => ({
    type: DELETE_USER_PROFILE_SUCCESS,
    payload: user,
});
export const deleteUserProfileError = (message) => ({
    type: DELETE_USER_PROFILE_ERROR,
    payload: { message },
});


export const loginUser = (user, history) => ({
    type: LOGIN_USER,
    payload: { user, history },
});

export const loginUserWithProvider = (user, history) => ({
    type: LOGIN_USER_PROVIDER,
    payload: { user, history }
});
export const loginUserSuccess = (user) => ({
    type: LOGIN_USER_SUCCESS,
    payload: user,
});
export const loginUserError = (message) => ({
    type: LOGIN_USER_ERROR,
    payload: { message },
});


export const forgotPassword = (forgotUserMail, history) => ({
    type: FORGOT_PASSWORD,
    payload: { forgotUserMail, history },
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
    type: FORGOT_PASSWORD_SUCCESS,
    payload: forgotUserMail,
});
export const forgotPasswordError = (message) => ({
    type: FORGOT_PASSWORD_ERROR,
    payload: { message },
});

export const resetPassword = ({ resetPasswordCode, newPassword, history }) => ({
    type: RESET_PASSWORD,
    payload: { resetPasswordCode, newPassword, history },
});
export const resetPasswordSuccess = (newPassword) => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: newPassword,
});
export const resetPasswordError = (message) => ({
    type: RESET_PASSWORD_ERROR,
    payload: { message },
});

export const registerUser = (user, history) => ({
    type: REGISTER_USER,
    payload: { user, history },
});
export const registerUserSuccess = (user) => ({
    type: REGISTER_USER_SUCCESS,
    payload: user,
});
export const registerUserError = (message) => ({
    type: REGISTER_USER_ERROR,
    payload: { message },
});

export const logoutUser = (history) => ({
    type: LOGOUT_USER,
    payload: { history },
});
