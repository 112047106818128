// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import {
  CONTACTS_FETCH_ITEM,
  CONTACTS_FETCH_ITEM_SUCCESS,
  CONTACTS_FETCH_ITEM_ERROR,
  CONTACTS_ADD_ITEM,
  CONTACTS_ADD_ITEM_SUCCESS,
  CONTACTS_ADD_ITEM_ERROR,
  CONTACTS_DELETE_ITEM,
  CONTACTS_DELETE_ITEM_SUCCESS,
  CONTACTS_DELETE_ITEM_ERROR,
} from './actions_def';

export const getContactItem = (item) => ({
  type: CONTACTS_FETCH_ITEM,
  payload: item,
});

export const getContactItemSuccess = (items) => ({
  type: CONTACTS_FETCH_ITEM_SUCCESS,
  payload: items,
});

export const getContactItemError = (error) => ({
  type: CONTACTS_FETCH_ITEM_ERROR,
  payload: error,
});


export const addContactItem = (item) => ({
  type: CONTACTS_ADD_ITEM,
  payload: item,
});

export const addContactItemSuccess = (items) => ({
  type: CONTACTS_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addContactItemError = (error) => ({
  type: CONTACTS_ADD_ITEM_ERROR,
  payload: error,
});



export const deleteContactItem = (item) => ({
  type: CONTACTS_DELETE_ITEM,
  payload: item,
});

export const deleteContactItemSuccess = (items) => ({
  type: CONTACTS_DELETE_ITEM_SUCCESS,
  payload: items,
});

export const deleteContactItemError = (error) => ({
  type: CONTACTS_DELETE_ITEM_ERROR,
  payload: error,
});

