
import { combineReducers } from 'redux';
import issues from 'projects/redux/issues/reducers';
import work from 'projects/redux/work/reducers';
import milestones from 'projects/redux/milestones/reducers';
import surveys from 'projects/redux/surveys/reducers';
import projects from 'projects/redux/projects/reducers';
import documents from 'projects/redux/documents/reducers';

const reducers = combineReducers({
    issues,
    work,
    milestones,
    surveys,
    projects,
    documents,
});

export default reducers;