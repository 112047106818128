import axios from "axios";
import { servicePath } from 'constants/defaultValues';
import { getAccessToken } from 'helpers/Firebase';
import moment from 'moment';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    deleteTagsListError, deleteTagsListSuccess,

    getTagsListError,
    getTagsListSuccess
} from './actions';
import {
    TAGS_DELETE_ITEMS, TAGS_GET_LIST
} from './actions_def';


const apiUrl = servicePath;

const getTagsListRequest = async () => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success) => {
        const instance = axios.create({
            method: 'get',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });
        instance.get('/tags/', { crossdomain: true })
            .then(response => success(response.data ?
                response.data.map(item => (
                    {
                        id: item.id,
                        text: item.text,
                    }
                )
                )
                :
                []));
    })
        .then((response) => response)
        .catch((error) => error);
};

function* getTagsList() {
    // try {
    //     const response = yield call(getTagsListRequest);
    //     yield put(getTagsListSuccess(response));
    // } catch (error) {
    //     yield put(getTagsListError(error));
    // }
}


export function* watchGetList() {
    yield takeEvery(TAGS_GET_LIST, getTagsList);
}


export default function* rootSaga() {
    yield all([
        fork(watchGetList),
    ]);
}
