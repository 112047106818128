export const CATEGORIES_GET_LIST = 'CATEGORIES_GET_LIST';
export const CATEGORIES_GET_LIST_SUCCESS = 'CATEGORIES_GET_LIST_SUCCESS';
export const CATEGORIES_GET_LIST_ERROR = 'CATEGORIES_GET_LIST_ERROR';
export const CATEGORIES_GET_LIST_WITH_FILTER = 'CATEGORIES_GET_LIST_WITH_FILTER';
export const CATEGORIES_GET_LIST_WITH_ORDER = 'CATEGORIES_GET_LIST_WITH_ORDER';
export const CATEGORIES_GET_LIST_SEARCH = 'CATEGORIES_GET_LIST_SEARCH';


export const CATEGORIES_DELETE_ITEMS = 'CATEGORIES_DELETE_ITEMS';
export const CATEGORIES_DELETE_ITEMS_SUCCESS = 'CATEGORIES_DELETE_ITEMS_SUCCESS';
export const CATEGORIES_DELETE_ITEMS_ERROR = 'CATEGORIES_DELETE_ITEMS_ERROR';


export const CATEGORIES_INITIALIZE_LIST = 'CATEGORIES_INITIALIZE_LIST';
export const CATEGORIES_INITIALIZE_LIST_SUCCESS = 'CATEGORIES_INITIALIZE_LIST_SUCCESS';
export const CATEGORIES_INITIALIZE_LIST_ERROR = 'CATEGORIES_INITIALIZE_LIST_ERROR';

export const CATEGORIES_SELECTED_ITEMS_CHANGE = 'CATEGORIES_SELECTED_ITEMS_CHANGE';
