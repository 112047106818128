import React, { Suspense } from 'react';
// import { PersistGate } from 'redux-persist/integration/react'
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './redux/store';
import reportWebVitals from './reportWebVitals';

const App = React.lazy(() => import(/* webpackChunkName: "App" */ './App'));



if ("serviceWorker" in navigator) {

    window.addEventListener("load", () => {
        // see: https://bugs.chromium.org/p/chromium/issues/detail?id=1097616
        // In some rare (<0.1% of cases) this call can return `undefined`
        const maybePromise = window.navigator.serviceWorker.register(
            "service-worker.js",
            {
                scope: "/",
            }
        );

        if (maybePromise && maybePromise.then) {
            maybePromise
                .then((registration) => {
                    console.log("SW registered: ", registration);
                })
                .catch((registrationError) => {
                    console.log("SW registration failed: ", registrationError);
                });
        }
    });



    // console.log("Starting up notification...");
    // navigator.serviceWorker
    //     .register("/serviceWorker.js")
    //     .then(function (registration) {
    //         console.log("Registration successful, scope is:", registration.scope);
    //     })
    //     .catch(function (err) {
    //         console.log("Service worker registration failed, error:", err);
    //     });
}
// const loadFeatures = () =>
//     import("wiki/utils/motion.js").then((res) => res.default);
const theStore = configureStore({}, true);
const Main = () => {
    return (
        <Provider store={theStore.store}  >
            {/* <MobXProvider  {...stores} store={stores}> {...stores}
                <Theme> */}
            {/* <ErrorBoundary>
                        <KBarProvider actions={[]} options={CommandBarOptions}> 
            <LazyMotion features={loadFeatures}>
            <PersistGate loading={null} persistor={theStore.persistor}>*/}
            <Suspense fallback={<div className="loading" />}>
                <App />
            </Suspense>
            {/*</PersistGate>
            </LazyMotion>
            </KBarProvider> 
                    </ErrorBoundary>*/}
            {/* </Theme>
            </MobXProvider> */}
        </Provider >
    );
};

ReactDOM.render(<Main />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

