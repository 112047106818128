// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import {
    ISSUES_FETCH_ITEM,
    ISSUES_FETCH_ITEM_SUCCESS,
    ISSUES_FETCH_ITEM_ERROR,
    ISSUES_ADD_ITEM,
    ISSUES_ADD_ITEM_SUCCESS,
    ISSUES_ADD_ITEM_ERROR,
    ISSUES_DELETE_ITEM,
    ISSUES_DELETE_ITEM_SUCCESS,
    ISSUES_DELETE_ITEM_ERROR,
    ISSUES_SAVE_ITEM,
    ISSUES_SAVE_ITEM_SUCCESS,
    ISSUES_SAVE_ITEM_ERROR,
} from './actions_def';

export const getIssueItem = (item) => ({
    type: ISSUES_FETCH_ITEM,
    payload: item,
});

export const getIssueItemSuccess = (items) => ({
    type: ISSUES_FETCH_ITEM_SUCCESS,
    payload: items,
});

export const getIssueItemError = (error) => ({
    type: ISSUES_FETCH_ITEM_ERROR,
    payload: error,
});


export const addIssuesItem = (item) => ({
    type: ISSUES_ADD_ITEM,
    payload: item,
});

export const addIssuesItemSuccess = (items) => ({
    type: ISSUES_ADD_ITEM_SUCCESS,
    payload: items,
});

export const addIssuesItemError = (error) => ({
    type: ISSUES_ADD_ITEM_ERROR,
    payload: error,
});



export const deleteIssueItem = (item) => ({
    type: ISSUES_DELETE_ITEM,
    payload: item,
});

export const deleteIssueItemSuccess = (items) => ({
    type: ISSUES_DELETE_ITEM_SUCCESS,
    payload: items,
});

export const deleteIssueItemError = (error) => ({
    type: ISSUES_DELETE_ITEM_ERROR,
    payload: error,
});




export const deleteQuestion = (item) => ({
    type: ISSUES_DELETE_ITEM,
    payload: item,
});

export const deleteQuestionSuccess = (items) => ({
    type: ISSUES_DELETE_ITEM_SUCCESS,
    payload: items,
});

export const deleteQuestionError = (error) => ({
    type: ISSUES_DELETE_ITEM_ERROR,
    payload: error,
});


export const saveIssueItem = (item) => ({
    type: ISSUES_SAVE_ITEM,
    payload: item,
});

export const saveIssueItemSuccess = (items) => ({
    type: ISSUES_SAVE_ITEM_SUCCESS,
    payload: items,
});

export const saveIssueItemError = (error) => ({
    type: ISSUES_SAVE_ITEM_ERROR,
    payload: error,
});

