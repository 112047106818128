import axios from "axios";
import { servicePath } from 'constants/defaultValues';
import { getAccessToken } from 'helpers/Firebase';
import moment from 'moment';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    deleteExpertsListError, deleteExpertsListSuccess,

    getExpertsListError,
    getExpertsListSuccess
} from './actions';
import {
    EXPERTS_DELETE_ITEMS, EXPERTS_GET_LIST
} from './actions_def';


const apiUrl = servicePath;

const getExpertsListRequest = async () => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success) => {
        const instance = axios.create({
            method: 'get',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });
        instance.get('/experts/', { crossdomain: true })
            .then(response => success(response.data || []));
    })
        .then((response) => response)
        .catch((error) => error);
};

function* getExpertsList() {
    console.log("Experts");
    try {
        const response = yield call(getExpertsListRequest);
        yield put(getExpertsListSuccess(response));
    } catch (error) {
        yield put(getExpertsListError(error));
    }
}

const deleteExpertsListRequest = async (items) => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success, fail) => {

        // success(notificationListData.data);
        // var _user = JSON.parse(localStorage.getItem("user"));
        const instance = axios.create({
            method: 'delete',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });

        // console.log('Sending :', {
        //   rfc_title: item.title,
        //   rfc_type: item.type ? item.type : 1,
        //   rfc_summary: item.detail ? item.detail : ' ',
        // })
        let _status = 1;
        switch (items.status) {
            case "PENDING": _status = 1; break;
            default: _status = 1;
        };

        items.map(item => {
            console.log(item)
            instance.delete('/experts/' + item + '/',
                { crossdomain: true })
                .then(response => {
                    if (response.data) {
                        if (response.data.status === "fail") {
                            fail(response.data);
                        }
                        else if (response.data.status === "success" || response.data.status === "ok") {
                            success(response.data);
                        }
                        else {
                            console.log('Inconsistent behaviour', response.data.status)
                        }
                    }
                }).catch((error) => {
                    console.log(error)
                });
        });
    })
        .then((response) => response)
        .catch((error) => error);
};

function* deleteExpertsList({ payload }) {
    // // console.log(payload);;

    try {
        const response = yield call(deleteExpertsListRequest, payload);
        yield put(deleteExpertsListSuccess(response));
    } catch (error) {
        yield put(deleteExpertsListError(error));
    }
}



export function* watchGetList() {
    yield takeEvery(EXPERTS_GET_LIST, getExpertsList);
}

export function* watchDeleteItems() {
    yield takeEvery(EXPERTS_DELETE_ITEMS, deleteExpertsList);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetList),
        fork(watchDeleteItems),
    ]);
}
