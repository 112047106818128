import axios from "axios";
import { servicePath } from 'constants/defaultValues';
import { getAccessToken } from 'helpers/Firebase';
import moment from 'moment';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    deleteToDosListError, deleteToDosListSuccess,

    getToDoListError,
    getToDoListSuccess
} from './actions';
import {
    TODOS_DELETE_ITEMS, TODOS_GET_LIST
} from './actions_def';


const apiUrl = servicePath;

const getToDoListRequest = async () => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success) => {
        const instance = axios.create({
            method: 'get',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });
        instance.get('/task/', { crossdomain: true })
            .then(response => success(response.data));
    })
        .then((response) => response)
        .catch((error) => error);
};

function* getToDoList() {
    try {
        const response = yield call(getToDoListRequest);
        yield put(getToDoListSuccess(response));
    } catch (error) {
        yield put(getToDoListError(error));
    }
}

const deleteToDosListRequest = async (items) => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success, fail) => {

        // success(notificationListData.data);
        // var _user = JSON.parse(localStorage.getItem("user"));
        const instance = axios.create({
            method: 'delete',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });

        // console.log('Sending :', {
        //   rfc_title: item.title,
        //   rfc_type: item.type ? item.type : 1,
        //   rfc_summary: item.detail ? item.detail : ' ',
        // })
        let _status = 1;
        switch (items.status) {
            case "PENDING": _status = 1; break;
            default: _status = 1;
        };

        items.map(item => {
            console.log(item)
            instance.delete('/task/' + item + '/',
                { crossdomain: true })
                .then(response => {
                    if (response.data) {
                        if (response.data.status === "fail") {
                            fail(response.data);
                        }
                        else if (response.data.status === "success" || response.data.status === "ok") {
                            success(response.data);
                        }
                        else {
                            console.log('Inconsistent behaviour', response.data.status)
                        }
                    }
                }).catch((error) => {
                    console.log(error)
                });
        });
    })
        .then((response) => response)
        .catch((error) => error);
};

function* deleteToDosList({ payload }) {
    // // console.log(payload);;

    try {
        const response = yield call(deleteToDosListRequest, payload);
        yield put(deleteToDosListSuccess(response));
    } catch (error) {
        yield put(deleteToDosListError(error));
    }
}



export function* watchGetList() {
    yield takeEvery(TODOS_GET_LIST, getToDoList);
}

export function* watchDeleteItems() {
    yield takeEvery(TODOS_DELETE_ITEMS, deleteToDosList);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetList),
        fork(watchDeleteItems),
    ]);
}
