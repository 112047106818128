// eslint-disable-next-line import/no-cycle
import {
    ISSUES_TYPES_DELETE_ITEMS,
    ISSUES_TYPES_DELETE_ITEMS_ERROR, ISSUES_TYPES_DELETE_ITEMS_SUCCESS, ISSUES_TYPES_GET_LIST,
    ISSUES_TYPES_GET_LIST_ERROR,
    ISSUES_TYPES_GET_LIST_SEARCH, ISSUES_TYPES_GET_LIST_SUCCESS,
    ISSUES_TYPES_GET_LIST_WITH_FILTER,
    ISSUES_TYPES_GET_LIST_WITH_ORDER,
    ISSUES_TYPES_INITIALIZE_LIST,
    ISSUES_TYPES_INITIALIZE_LIST_SUCCESS,
    ISSUES_TYPES_INITIALIZE_LIST_ERROR,
    ISSUES_TYPES_SELECTED_ITEMS_CHANGE
} from './actions_def';
export const getIssuesTypesList = (payload) => ({
    type: ISSUES_TYPES_GET_LIST,
    payload: payload,
});

export const getIssuesTypesListSuccess = (items) => ({
    type: ISSUES_TYPES_GET_LIST_SUCCESS,
    payload: items,
});

export const getIssuesTypesListError = (error) => ({
    type: ISSUES_TYPES_GET_LIST_ERROR,
    payload: error,
});

export const getIssuesTypesListWithFilter = (column, value) => ({
    type: ISSUES_TYPES_GET_LIST_WITH_FILTER,
    payload: { column, value },
});

export const getIssuesTypesListWithOrder = (column) => ({
    type: ISSUES_TYPES_GET_LIST_WITH_ORDER,
    payload: column,
});

export const getIssuesTypesListSearch = (keyword) => ({
    type: ISSUES_TYPES_GET_LIST_SEARCH,
    payload: keyword,
});

export const selectedIssuesTypeItemsChange = (selectedItems) => ({
    type: ISSUES_TYPES_SELECTED_ITEMS_CHANGE,
    payload: selectedItems,
});




export const initializeIssuesTypesList = (projectId) => ({
    type: ISSUES_TYPES_INITIALIZE_LIST,
    payload: projectId,
});


export const initializeIssuesTypesListSuccess = (projectId) => ({
    type: ISSUES_TYPES_INITIALIZE_LIST_SUCCESS,
    payload: projectId,
});


export const initializeIssuesTypesListError = (projectId) => ({
    type: ISSUES_TYPES_INITIALIZE_LIST_ERROR,
    payload: projectId,
});



export const deleteIssuesTypesList = (items) => ({
    type: ISSUES_TYPES_DELETE_ITEMS,
    payload: items,
});

export const deleteIssuesTypesListSuccess = (items) => ({
    type: ISSUES_TYPES_DELETE_ITEMS_SUCCESS,
    payload: items,
});

export const deleteIssuesTypesListError = (error) => ({
    type: ISSUES_TYPES_DELETE_ITEMS_ERROR,
    payload: error,
});
