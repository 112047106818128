import {
    TAGS_DELETE_ITEMS,
    TAGS_DELETE_ITEMS_ERROR, TAGS_DELETE_ITEMS_SUCCESS, TAGS_GET_LIST,
    TAGS_GET_LIST_ERROR,
    TAGS_GET_LIST_SEARCH, TAGS_GET_LIST_SUCCESS,
    TAGS_GET_LIST_WITH_FILTER,
    TAGS_GET_LIST_WITH_ORDER,
    TAGS_SELECTED_ITEMS_CHANGE
} from './actions_def';

const INIT_STATE = {
    allTagItems: null,
    tagsItems: null,
    errors: [],
    filter: null,
    searchKeyword: '',
    orderColumn: null,
    isLoaded: false,
    isLoading: false,
    saving: false,
    categories: ['Flexbox', 'Sass', 'React'],
    selectedItems: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case TAGS_GET_LIST:
            return {
                ...state,
                isLoaded: false,
                isLoading: true,
            };

        case TAGS_GET_LIST_SUCCESS:
            // console.log(action.payload)
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                allTagItems: action.payload,
                tagsItems: action.payload,
            };

        case TAGS_GET_LIST_ERROR:
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                error: action.payload
            };

        case TAGS_GET_LIST_WITH_FILTER:
            if (action.payload.column === '' || action.payload.value === '') {
                return {
                    ...state,
                    isLoaded: true,
                    isLoading: false,
                    tagsItems: state.allTagItems,
                    filter: null,
                };
            }
            // eslint-disable-next-line no-case-declarations
            const filteredItems = state.allTagItems.filter(
                (item) => item[action.payload.column] === action.payload.value
            );
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                tagsItems: filteredItems,
                filter: {
                    column: action.payload.column,
                    value: action.payload.value,
                },
            };

        case TAGS_GET_LIST_WITH_ORDER:
            if (action.payload === '') {
                return {
                    ...state,
                    isLoaded: true,
                    isLoading: false,
                    tagsItems: state.tagsItems,
                    orderColumn: null,
                };
            }
            // eslint-disable-next-line no-case-declarations
            const sortedItems = state.tagsItems.sort((a, b) => {
                if (a[action.payload] < b[action.payload]) return -1;
                if (a[action.payload] > b[action.payload]) return 1;
                return 0;
            });
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                tagsItems: sortedItems,
                orderColumn: state.orderColumns.find(
                    (x) => x.column === action.payload
                ),
            };

        case TAGS_GET_LIST_SEARCH:
            if (action.payload === '') {
                return { ...state, tagsItems: state.allTagItems };
            }
            // eslint-disable-next-line no-case-declarations
            const keyword = action.payload.toLowerCase();
            // eslint-disable-next-line no-case-declarations
            const searchItems = state.allTagItems.filter(
                (item) =>
                    item.title.toLowerCase().indexOf(keyword) > -1 ||
                    item.detail.toLowerCase().indexOf(keyword) > -1 ||
                    item.status.toLowerCase().indexOf(keyword) > -1 ||
                    item.category.toLowerCase().indexOf(keyword) > -1 ||
                    item.label.toLowerCase().indexOf(keyword) > -1
            );
            return {
                ...state,
                isLoaded: true,
                tagsItems: searchItems,
                searchKeyword: action.payload,
            };

        case TAGS_SELECTED_ITEMS_CHANGE:
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                selectedItems: action.payload
            };


        default:
            return { ...state };
    }
};
