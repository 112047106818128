// eslint-disable-next-line import/no-cycle
import {
  CONTACTS_DELETE_ITEMS,
  CONTACTS_DELETE_ITEMS_ERROR, CONTACTS_DELETE_ITEMS_SUCCESS, CONTACTS_GET_LIST,
  CONTACTS_GET_LIST_ERROR,
  CONTACTS_GET_LIST_SEARCH, CONTACTS_GET_LIST_SUCCESS,
  CONTACTS_GET_LIST_WITH_FILTER,
  CONTACTS_GET_LIST_WITH_ORDER,
  CONTACTS_SELECTED_ITEMS_CHANGE
} from './actions_def';
export const getContactsList = () => ({
  type: CONTACTS_GET_LIST,
});

export const getContactsListSuccess = (items) => ({
  type: CONTACTS_GET_LIST_SUCCESS,
  payload: items,
});

export const getContactsListError = (error) => ({
  type: CONTACTS_GET_LIST_ERROR,
  payload: error,
});

export const getContactsListWithFilter = (column, value) => ({
  type: CONTACTS_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getContactsListWithOrder = (column) => ({
  type: CONTACTS_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getContactsListSearch = (keyword) => ({
  type: CONTACTS_GET_LIST_SEARCH,
  payload: keyword,
});

export const selectedContactItemsChange = (selectedItems) => ({
  type: CONTACTS_SELECTED_ITEMS_CHANGE,
  payload: selectedItems,
});



export const deleteContactsList = (items) => ({
  type: CONTACTS_DELETE_ITEMS,
  payload: items,
});

export const deleteContactsListSuccess = (items) => ({
  type: CONTACTS_DELETE_ITEMS_SUCCESS,
  payload: items,
});

export const deleteContactsListError = (error) => ({
  type: CONTACTS_DELETE_ITEMS_ERROR,
  payload: error,
});



export const selectedContactsItemsChange = (selectedItems) => ({
  type: CONTACTS_SELECTED_ITEMS_CHANGE,
  payload: selectedItems,
});

