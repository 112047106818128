import {
    ISSUES_ADD_ITEM,
    ISSUES_ADD_ITEM_SUCCESS,
    ISSUES_ADD_ITEM_ERROR,
    ISSUES_FETCH_ITEM,
    ISSUES_FETCH_ITEM_SUCCESS,
    ISSUES_FETCH_ITEM_ERROR,
    ISSUES_DELETE_ITEM,
    ISSUES_DELETE_ITEM_SUCCESS,
    ISSUES_DELETE_ITEM_ERROR,
    ISSUES_SAVE_ITEM,
    ISSUES_SAVE_ITEM_SUCCESS,
    ISSUES_SAVE_ITEM_ERROR,
} from './actions_def';

import moment from 'moment'
const INIT_STATE = {
    issue: null,
    errors: [],
    isLoading: false,
    isLoaded: false,
    isSaving: false,
    isSaved: false,
    hasErrors: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ISSUES_FETCH_ITEM:
            return { ...state, isLoading: false };

        case ISSUES_FETCH_ITEM_SUCCESS:
            return {
                ...state,
                isLoading: true,
                issue: action.payload
            };

        case ISSUES_FETCH_ITEM_ERROR:
            return {
                ...state,
                isLoading: true,
                error: action.payload
            };

        case ISSUES_ADD_ITEM:
            return {
                ...state,
                isSaving: true
            };

        case ISSUES_ADD_ITEM_SUCCESS:

            return {
                ...state,
                isLoading: true,
                isSaving: false,
                errors: [],
                issue: action.payload
            };

        case ISSUES_ADD_ITEM_ERROR:
            console.log("Failed", action.payload)
            return {
                ...state,
                isLoading: true,
                isSaving: false,
                hasErrors: true,
                errors: action.payload.errors && action.payload.errors.map(error => (
                    {
                        field: error.field,
                        error_code: error.code
                    }
                ))
            };

        case ISSUES_DELETE_ITEM:
            return { ...state, saving: true };

        case ISSUES_DELETE_ITEM_SUCCESS:
            console.log("Success", state.allIssuesItems.filter(item => item && item.id !== action.payload.rfc_id))

            return {
                ...state,
                isLoading: true,
                isSaving: false,
                isSaved: true,
                errors: [],
                hasErrors: false,

            };

        case ISSUES_DELETE_ITEM_ERROR:
            console.log("Failed", action.payload)
            return {
                ...state,
                isLoading: false,
                isSaving: false,
                isSaved: true,
                errors: action.payload.errors.map(error => (
                    {
                        field: error.field,
                        error_code: error.code
                    }
                ))
            };


        case ISSUES_SAVE_ITEM_SUCCESS:

            return {
                ...state,
                isLoading: true,
                isSaving: false,
                isSaved: true,
                errors: [],
                issue: action.payload
            };

        case ISSUES_SAVE_ITEM_ERROR:
            console.log("Failed", action.payload)
            return {
                ...state,
                isLoading: true,
                isSaving: false,
                hasErrors: true,
                errors: action.payload.errors && action.payload.errors.map(error => (
                    {
                        field: error.field,
                        error_code: error.code
                    }
                ))
            };

        case ISSUES_SAVE_ITEM:
            return { ...state, isSaving: true };

        default:
            return { ...state };
    }
};
