export const TODOS_GET_LIST = 'TODOS_GET_LIST';
export const TODOS_GET_LIST_SUCCESS = 'TODOS_GET_LIST_SUCCESS';
export const TODOS_GET_LIST_ERROR = 'TODOS_GET_LIST_ERROR';

export const TODOS_GET_LIST_WITH_FILTER = 'TODOS_GET_LIST_WITH_FILTER';
export const TODOS_GET_LIST_WITH_ORDER = 'TODOS_GET_LIST_WITH_ORDER';
export const TODOS_GET_LIST_SEARCH = 'TODOS_GET_LIST_SEARCH';

export const TODOS_DELETE_ITEMS = 'TODOS_DELETE_ITEMS';
export const TODOS_DELETE_ITEMS_SUCCESS = 'TODOS_DELETE_ITEMS_SUCCESS';
export const TODOS_DELETE_ITEMS_ERROR = 'TODOS_DELETE_ITEMS_ERROR';

export const TODOS_SELECTED_ITEMS_CHANGE = 'TODOS_SELECTED_ITEMS_CHANGE';
