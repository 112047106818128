export const WORK_GET_LIST = 'WORK_GET_LIST';
export const WORK_GET_LIST_SUCCESS = 'WORK_GET_LIST_SUCCESS';
export const WORK_GET_LIST_ERROR = 'WORK_GET_LIST_ERROR';
export const WORK_GET_LIST_WITH_FILTER = 'WORK_GET_LIST_WITH_FILTER';
export const WORK_GET_LIST_WITH_ORDER = 'WORK_GET_LIST_WITH_ORDER';
export const WORK_GET_LIST_SEARCH = 'WORK_GET_LIST_SEARCH';


export const WORK_DELETE_ITEMS = 'WORK_DELETE_ITEMS';
export const WORK_DELETE_ITEMS_SUCCESS = 'WORK_DELETE_ITEMS_SUCCESS';
export const WORK_DELETE_ITEMS_ERROR = 'WORK_DELETE_ITEMS_ERROR';


export const WORK_SELECTED_ITEMS_CHANGE = 'WORK_SELECTED_ITEMS_CHANGE';
