/* AUTH */
export const RESET_ACTIONS = 'RESET_ACTIONS';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_PROVIDER = 'LOGIN_USER_PROVIDER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_ERROR = 'FETCH_USER_PROFILE_ERROR';

export const FETCH_LOCAL_USER_PROFILE = 'FETCH_LOCAL_USER_PROFILE';
export const FETCH_LOCAL_USER_PROFILE_SUCCESS = 'FETCH_LOCAL_USER_PROFILE_SUCCESS';
export const FETCH_LOCAL_USER_PROFILE_ERROR = 'FETCH_LOCAL_USER_PROFILE_ERROR';

export const DELETE_USER_PROFILE = 'DELETE_USER_PROFILE';
export const DELETE_USER_PROFILE_SUCCESS = 'DELETE_USER_PROFILE_SUCCESS';
export const DELETE_USER_PROFILE_ERROR = 'DELETE_USER_PROFILE_ERROR';

export const SAVE_USER_PROFILE = 'SAVE_USER_PROFILE';
export const SAVE_USER_PROFILE_SUCCESS = 'SAVE_USER_PROFILE_SUCCESS';
export const SAVE_USER_PROFILE_ERROR = 'SAVE_USER_PROFILE_ERROR';


export const CREATE_USER_PROFILE = 'CREATE_USER_PROFILE';
export const CREATE_USER_PROFILE_SUCCESS = 'CREATE_USER_PROFILE_SUCCESS';
export const CREATE_USER_PROFILE_ERROR = 'CREATE_USER_PROFILE_ERROR';

export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_USER_PROFILE_SUCCESS = 'SET_USER_PROFILE_SUCCESS';
export const SET_USER_PROFILE_ERROR = 'SET_USER_PROFILE_ERROR';
