// eslint-disable-next-line import/no-cycle
import {
  PROJECTS_DELETE_ITEMS,
  PROJECTS_DELETE_ITEMS_ERROR, PROJECTS_DELETE_ITEMS_SUCCESS, PROJECTS_GET_LIST,
  PROJECTS_GET_LIST_ERROR,
  PROJECTS_GET_LIST_SEARCH, PROJECTS_GET_LIST_SUCCESS,
  PROJECTS_GET_LIST_WITH_FILTER,
  PROJECTS_GET_LIST_WITH_ORDER,
  PROJECTS_SELECTED_ITEMS_CHANGE
} from './actions_def';
export const getProjectsList = () => ({
  type: PROJECTS_GET_LIST,
});

export const getProjectsListSuccess = (items) => ({
  type: PROJECTS_GET_LIST_SUCCESS,
  payload: items,
});

export const getProjectsListError = (error) => ({
  type: PROJECTS_GET_LIST_ERROR,
  payload: error,
});

export const getProjectsListWithFilter = (column, value) => ({
  type: PROJECTS_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getProjectsListWithOrder = (column) => ({
  type: PROJECTS_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getProjectsListSearch = (keyword) => ({
  type: PROJECTS_GET_LIST_SEARCH,
  payload: keyword,
});

export const selectedProjectsItemsChange = (selectedItems) => ({
  type: PROJECTS_SELECTED_ITEMS_CHANGE,
  payload: selectedItems,
});



export const deleteProjectsList = (items) => ({
  type: PROJECTS_DELETE_ITEMS,
  payload: items,
});

export const deleteProjectsListSuccess = (items) => ({
  type: PROJECTS_DELETE_ITEMS_SUCCESS,
  payload: items,
});

export const deleteProjectsListError = (error) => ({
  type: PROJECTS_DELETE_ITEMS_ERROR,
  payload: error,
});
