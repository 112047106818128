export const SURVEYS_GET_LIST = 'SURVEYS_GET_LIST';
export const SURVEYS_GET_LIST_SUCCESS = 'SURVEYS_GET_LIST_SUCCESS';
export const SURVEYS_GET_LIST_ERROR = 'SURVEYS_GET_LIST_ERROR';
export const SURVEYS_GET_LIST_WITH_FILTER = 'SURVEYS_GET_LIST_WITH_FILTER';
export const SURVEYS_GET_LIST_WITH_ORDER = 'SURVEYS_GET_LIST_WITH_ORDER';
export const SURVEYS_GET_LIST_SEARCH = 'SURVEYS_GET_LIST_SEARCH';

export const MY_POSTS_GET_LIST = 'MY_POSTS_GET_LIST';


export const SURVEYS_DELETE_ITEMS = 'SURVEYS_DELETE_ITEMS';
export const SURVEYS_DELETE_ITEMS_SUCCESS = 'SURVEYS_DELETE_ITEMS_SUCCESS';
export const SURVEYS_DELETE_ITEMS_ERROR = 'SURVEYS_DELETE_ITEMS_ERROR';


export const SURVEYS_SELECTED_ITEMS_CHANGE = 'SURVEYS_SELECTED_ITEMS_CHANGE';
