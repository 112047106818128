// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import {
    PROJECTS_FETCH_ITEM,
    PROJECTS_FETCH_ITEM_SUCCESS,
    PROJECTS_FETCH_ITEM_ERROR,
    PROJECTS_ADD_ITEM,
    PROJECTS_ADD_ITEM_SUCCESS,
    PROJECTS_ADD_ITEM_ERROR,
    PROJECTS_SAVE_ITEM,
    PROJECTS_SAVE_ITEM_SUCCESS,
    PROJECTS_SAVE_ITEM_ERROR,
    PROJECTS_RESET_ITEM,
    PROJECTS_CREATE_ITEM,
    PROJECTS_CREATE_ITEM_SUCCESS,
    PROJECTS_CREATE_ITEM_ERROR,
    PROJECTS_DELETE_ITEM,
    PROJECTS_DELETE_ITEM_SUCCESS,
    PROJECTS_DELETE_ITEM_ERROR,
} from './actions_def';

export const resetProjectItem = (item) => ({
    type: PROJECTS_RESET_ITEM,
    payload: item,
});

export const getProjectItem = (item) => ({
    type: PROJECTS_FETCH_ITEM,
    payload: item,
});

export const getProjectItemSuccess = (items) => ({
    type: PROJECTS_FETCH_ITEM_SUCCESS,
    payload: items,
});

export const getProjectItemError = (error) => ({
    type: PROJECTS_FETCH_ITEM_ERROR,
    payload: error,
});


export const addProjectItem = (item) => ({
    type: PROJECTS_ADD_ITEM,
    payload: item,
});

export const addProjectItemSuccess = (items) => ({
    type: PROJECTS_ADD_ITEM_SUCCESS,
    payload: items,
});

export const addProjectItemError = (error) => ({
    type: PROJECTS_ADD_ITEM_ERROR,
    payload: error,
});


export const saveProjectItem = (project) => ({
    type: PROJECTS_SAVE_ITEM,
    payload: { project },
});

export const saveProjectItemSuccess = (project) => ({
    type: PROJECTS_SAVE_ITEM_SUCCESS,
    payload: project,
});
export const saveProjectItemError = (message) => ({
    type: PROJECTS_SAVE_ITEM_ERROR,
    payload: { message },
});


export const deleteProjectItem = (item) => ({
    type: PROJECTS_DELETE_ITEM,
    payload: item,
});

export const deleteProjectItemSuccess = (items) => ({
    type: PROJECTS_DELETE_ITEM_SUCCESS,
    payload: items,
});

export const deleteProjectItemError = (error) => ({
    type: PROJECTS_DELETE_ITEM_ERROR,
    payload: error,
});

