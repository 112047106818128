// eslint-disable-next-line import/no-cycle
import {
    MILESTONES_DELETE_ITEMS,
    MILESTONES_DELETE_ITEMS_ERROR, MILESTONES_DELETE_ITEMS_SUCCESS, MILESTONES_GET_LIST,
    MILESTONES_GET_LIST_ERROR,
    MILESTONES_GET_LIST_SEARCH, MILESTONES_GET_LIST_SUCCESS,
    MILESTONES_GET_LIST_WITH_FILTER,
    MILESTONES_GET_LIST_WITH_ORDER,
    MILESTONES_SELECTED_ITEMS_CHANGE
} from './actions_def';
export const getMilestonesList = (payload) => ({
    type: MILESTONES_GET_LIST,
    payload: payload,
});

export const getMilestonesListSuccess = (items) => ({
    type: MILESTONES_GET_LIST_SUCCESS,
    payload: items,
});

export const getMilestonesListError = (error) => ({
    type: MILESTONES_GET_LIST_ERROR,
    payload: error,
});

export const getMilestonesListWithFilter = (column, value) => ({
    type: MILESTONES_GET_LIST_WITH_FILTER,
    payload: { column, value },
});

export const getMilestonesListWithOrder = (column) => ({
    type: MILESTONES_GET_LIST_WITH_ORDER,
    payload: column,
});

export const getMilestonesListSearch = (keyword) => ({
    type: MILESTONES_GET_LIST_SEARCH,
    payload: keyword,
});

export const selectedMilestonesItemsChange = (selectedItems) => ({
    type: MILESTONES_SELECTED_ITEMS_CHANGE,
    payload: selectedItems,
});



export const deleteMilestonesList = (items) => ({
    type: MILESTONES_DELETE_ITEMS,
    payload: items,
});

export const deleteMilestonesListSuccess = (items) => ({
    type: MILESTONES_DELETE_ITEMS_SUCCESS,
    payload: items,
});

export const deleteMilestonesListError = (error) => ({
    type: MILESTONES_DELETE_ITEMS_ERROR,
    payload: error,
});
