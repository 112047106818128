// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import {
    DOCUMENTS_FETCH_ITEM,
    DOCUMENTS_FETCH_ITEM_SUCCESS,
    DOCUMENTS_FETCH_ITEM_ERROR,
    DOCUMENTS_ADD_ITEM,
    DOCUMENTS_ADD_ITEM_SUCCESS,
    DOCUMENTS_ADD_ITEM_ERROR,
    DOCUMENTS_DELETE_ITEM,
    DOCUMENTS_DELETE_ITEM_SUCCESS,
    DOCUMENTS_DELETE_ITEM_ERROR,
    DOCUMENTS_SAVE_ITEM,
    DOCUMENTS_SAVE_ITEM_SUCCESS,
    DOCUMENTS_SAVE_ITEM_ERROR,
    DOCUMENT_CHAPTER_ADD,
    DOCUMENT_CHAPTER_ADD_SUCCESS,
    DOCUMENT_CHAPTER_ADD_ERROR,
    DOCUMENT_CHAPTER_DELETE,
    DOCUMENT_CHAPTER_DELETE_SUCCESS,
    DOCUMENT_CHAPTER_DELETE_ERROR,
    DOCUMENT_SET_CURRENT_CHAPTER,
} from './actions_def';

export const getDocumentItem = (item) => ({
    type: DOCUMENTS_FETCH_ITEM,
    payload: item,
});

export const getDocumentItemSuccess = (items) => ({
    type: DOCUMENTS_FETCH_ITEM_SUCCESS,
    payload: items,
});

export const getDocumentItemError = (error) => ({
    type: DOCUMENTS_FETCH_ITEM_ERROR,
    payload: error,
});


export const addDocumentsItem = (item) => ({
    type: DOCUMENTS_ADD_ITEM,
    payload: item,
});

export const addDocumentsItemSuccess = (items) => ({
    type: DOCUMENTS_ADD_ITEM_SUCCESS,
    payload: items,
});

export const addDocumentsItemError = (error) => ({
    type: DOCUMENTS_ADD_ITEM_ERROR,
    payload: error,
});



export const deleteDocumentItem = (item) => ({
    type: DOCUMENTS_DELETE_ITEM,
    payload: item,
});

export const deleteDocumentItemSuccess = (items) => ({
    type: DOCUMENTS_DELETE_ITEM_SUCCESS,
    payload: items,
});

export const deleteDocumentItemError = (error) => ({
    type: DOCUMENTS_DELETE_ITEM_ERROR,
    payload: error,
});




export const deleteQuestion = (item) => ({
    type: DOCUMENTS_DELETE_ITEM,
    payload: item,
});

export const deleteQuestionSuccess = (items) => ({
    type: DOCUMENTS_DELETE_ITEM_SUCCESS,
    payload: items,
});

export const deleteQuestionError = (error) => ({
    type: DOCUMENTS_DELETE_ITEM_ERROR,
    payload: error,
});


export const saveDocumentItem = (item) => ({
    type: DOCUMENTS_SAVE_ITEM,
    payload: item,
});

export const saveDocumentItemSuccess = (items) => ({
    type: DOCUMENTS_SAVE_ITEM_SUCCESS,
    payload: items,
});

export const saveDocumentItemError = (error) => ({
    type: DOCUMENTS_SAVE_ITEM_ERROR,
    payload: error,
});








export const addDocumentChapter = (item) => ({
    type: DOCUMENT_CHAPTER_ADD,
    payload: item,
});

export const addDocumentChapterSuccess = (items) => ({
    type: DOCUMENT_CHAPTER_ADD_SUCCESS,
    payload: items,
});

export const addDocumentChapterError = (error) => ({
    type: DOCUMENT_CHAPTER_ADD_ERROR,
    payload: error,
});



export const deleteDocumentChapter = (item) => ({
    type: DOCUMENT_CHAPTER_DELETE,
    payload: item,
});

export const deleteDocumentChapterSuccess = (items) => ({
    type: DOCUMENT_CHAPTER_DELETE_SUCCESS,
    payload: items,
});

export const deleteDocumentChapterError = (error) => ({
    type: DOCUMENT_CHAPTER_DELETE_ERROR,
    payload: error,
});

export const setCurrentChapterIdx = (chapter) => ({
    type: DOCUMENT_SET_CURRENT_CHAPTER,
    payload: chapter,
});
