export const TAGS_GET_LIST = 'TAGS_GET_LIST';
export const TAGS_GET_LIST_SUCCESS = 'TAGS_GET_LIST_SUCCESS';
export const TAGS_GET_LIST_ERROR = 'TAGS_GET_LIST_ERROR';
export const TAGS_GET_LIST_WITH_FILTER = 'TAGS_GET_LIST_WITH_FILTER';
export const TAGS_GET_LIST_WITH_ORDER = 'TAGS_GET_LIST_WITH_ORDER';
export const TAGS_GET_LIST_SEARCH = 'TAGS_GET_LIST_SEARCH';


export const TAGS_DELETE_ITEMS = 'TAGS_DELETE_ITEMS';
export const TAGS_DELETE_ITEMS_SUCCESS = 'TAGS_DELETE_ITEMS_SUCCESS';
export const TAGS_DELETE_ITEMS_ERROR = 'TAGS_DELETE_ITEMS_ERROR';


export const TAGS_SELECTED_ITEMS_CHANGE = 'TAGS_SELECTED_ITEMS_CHANGE';
