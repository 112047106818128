export const MILESTONES_GET_LIST = 'MILESTONES_GET_LIST';
export const MILESTONES_GET_LIST_SUCCESS = 'MILESTONES_GET_LIST_SUCCESS';
export const MILESTONES_GET_LIST_ERROR = 'MILESTONES_GET_LIST_ERROR';
export const MILESTONES_GET_LIST_WITH_FILTER = 'MILESTONES_GET_LIST_WITH_FILTER';
export const MILESTONES_GET_LIST_WITH_ORDER = 'MILESTONES_GET_LIST_WITH_ORDER';
export const MILESTONES_GET_LIST_SEARCH = 'MILESTONES_GET_LIST_SEARCH';


export const MILESTONES_DELETE_ITEMS = 'MILESTONES_DELETE_ITEMS';
export const MILESTONES_DELETE_ITEMS_SUCCESS = 'MILESTONES_DELETE_ITEMS_SUCCESS';
export const MILESTONES_DELETE_ITEMS_ERROR = 'MILESTONES_DELETE_ITEMS_ERROR';


export const MILESTONES_SELECTED_ITEMS_CHANGE = 'MILESTONES_SELECTED_ITEMS_CHANGE';
