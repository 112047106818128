
import { combineReducers } from 'redux';
import todoDetails from './details/reducer';
import todosList from './list/reducer';
// import todosStatuses from './statuses/reducer';

const reducers = combineReducers({
  todoDetails,
  todosList,
  // todosStatuses
});

export default reducers;