import {
    WORK_DELETE_ITEMS,

    WORK_DELETE_ITEMS_ERROR, WORK_DELETE_ITEMS_SUCCESS, WORK_GET_LIST,

    WORK_GET_LIST_ERROR,


    WORK_GET_LIST_SEARCH, WORK_GET_LIST_SUCCESS,

    WORK_GET_LIST_WITH_FILTER,
    WORK_GET_LIST_WITH_ORDER,

    WORK_SELECTED_ITEMS_CHANGE
} from './actions_def';

const INIT_STATE = {
    allWorkItems: [],
    workItems: [],
    errors: [],
    filter: null,
    searchKeyword: '',
    orderColumn: null,
    isLoaded: false,
    isLoading: false,
    saving: false,
    labels: [
        { label: 'EDUCATION', color: 'secondary' },
        { label: 'NEW FRAMEWORK', color: 'primary' },
        { label: 'PERSONAL', color: 'info' },
    ],
    orderColumns: [
        { column: 'title', label: 'Title' },
        { column: 'category', label: 'Category' },
        { column: 'status', label: 'Status' },
        { column: 'label', label: 'Label' },
    ],
    categories: ['Flexbox', 'Sass', 'React'],
    selectedItems: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case WORK_GET_LIST:
            return {
                ...state,
                isLoaded: false,
                isLoading: true,
            };

        case WORK_GET_LIST_SUCCESS:
            console.log(action.payload)
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                allWorkItems: action.payload,
                workItems: action.payload,
            };

        case WORK_GET_LIST_ERROR:
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                error: action.payload
            };

        case WORK_GET_LIST_WITH_FILTER:
            if (action.payload.column === '' || action.payload.value === '') {
                return {
                    ...state,
                    isLoaded: false,
                    isLoading: true,
                    workItems: state.allWorkItems,
                    filter: null,
                };
            }
            // eslint-disable-next-line no-case-declarations
            const filteredItems = state.allWorkItems.filter(
                (item) => item[action.payload.column] === action.payload.value
            );
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                workItems: filteredItems,
                filter: {
                    column: action.payload.column,
                    value: action.payload.value,
                },
            };

        case WORK_GET_LIST_WITH_ORDER:
            if (action.payload === '') {
                return {
                    ...state,
                    isLoaded: false,
                    isLoading: true,
                    workItems: state.workItems,
                    orderColumn: null,
                };
            }
            // eslint-disable-next-line no-case-declarations
            const sortedItems = state.workItems.sort((a, b) => {
                if (a[action.payload] < b[action.payload]) return -1;
                if (a[action.payload] > b[action.payload]) return 1;
                return 0;
            });
            return {
                ...state,
                isLoaded: false,
                isLoading: true,
                workItems: sortedItems,
                orderColumn: state.orderColumns.find(
                    (x) => x.column === action.payload
                ),
            };

        case WORK_GET_LIST_SEARCH:
            if (action.payload === '') {
                return { ...state, workItems: state.allWorkItems };
            }
            // eslint-disable-next-line no-case-declarations
            const keyword = action.payload.toLowerCase();
            // eslint-disable-next-line no-case-declarations
            const searchItems = state.allWorkItems.filter(
                (item) =>
                    item.title.toLowerCase().indexOf(keyword) > -1 ||
                    item.detail.toLowerCase().indexOf(keyword) > -1 ||
                    item.status.toLowerCase().indexOf(keyword) > -1 ||
                    item.category.toLowerCase().indexOf(keyword) > -1 ||
                    item.label.toLowerCase().indexOf(keyword) > -1
            );
            return {
                ...state,
                isLoaded: false,
                isLoading: true,
                workItems: searchItems,
                searchKeyword: action.payload,
            };

        case WORK_SELECTED_ITEMS_CHANGE:
            return {
                ...state, isLoaded: false,
                isLoading: false, selectedItems: action.payload
            };

        case WORK_DELETE_ITEMS:
            return {
                ...state,
                isLoaded: false,
                isLoading: true,
                saving: true
            };

        case WORK_DELETE_ITEMS_SUCCESS:

            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                saving: false,
                saved: true,
                errors: [],
                allWorkItems: state.allWorkItems.filter(item => item.id !== action.payload.rfc_id),
                workItems: state.workItems.filter(item => item.id !== action.payload.rfc_id),
            };

        case WORK_DELETE_ITEMS_ERROR:
            console.log("Failed", action.payload)
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                saving: false,
                saved: true,
                errors: action.payload.errors.map(error => (
                    {
                        field: error.field,
                        error_code: error.code
                    }
                ))
            };

        default:
            return { ...state };
    }
};
