// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import {
  MILESTONES_FETCH_ITEM,
  MILESTONES_FETCH_ITEM_SUCCESS,
  MILESTONES_FETCH_ITEM_ERROR,
  MILESTONES_ADD_ITEM,
  MILESTONES_ADD_ITEM_SUCCESS,
  MILESTONES_ADD_ITEM_ERROR,
  MILESTONES_DELETE_ITEM,
  MILESTONES_DELETE_ITEM_SUCCESS,
  MILESTONES_DELETE_ITEM_ERROR,
} from './actions_def';

export const getMilestoneItem = (item) => ({
  type: MILESTONES_FETCH_ITEM,
  payload: item,
});

export const getMilestoneItemSuccess = (items) => ({
  type: MILESTONES_FETCH_ITEM_SUCCESS,
  payload: items,
});

export const getMilestoneItemError = (error) => ({
  type: MILESTONES_FETCH_ITEM_ERROR,
  payload: error,
});


export const addMilestonesItem = (item) => ({
  type: MILESTONES_ADD_ITEM,
  payload: item,
});

export const addMilestonesItemSuccess = (items) => ({
  type: MILESTONES_ADD_ITEM_SUCCESS,
  payload: items,
});

export const addMilestonesItemError = (error) => ({
  type: MILESTONES_ADD_ITEM_ERROR,
  payload: error,
});



export const deleteMilestoneItem = (item) => ({
  type: MILESTONES_DELETE_ITEM,
  payload: item,
});

export const deleteMilestoneItemSuccess = (items) => ({
  type: MILESTONES_DELETE_ITEM_SUCCESS,
  payload: items,
});

export const deleteMilestoneItemError = (error) => ({
  type: MILESTONES_DELETE_ITEM_ERROR,
  payload: error,
});

