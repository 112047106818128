import {
    MILESTONES_DELETE_ITEMS,

    MILESTONES_DELETE_ITEMS_ERROR, MILESTONES_DELETE_ITEMS_SUCCESS, MILESTONES_GET_LIST,

    MILESTONES_GET_LIST_ERROR,


    MILESTONES_GET_LIST_SEARCH, MILESTONES_GET_LIST_SUCCESS,

    MILESTONES_GET_LIST_WITH_FILTER,
    MILESTONES_GET_LIST_WITH_ORDER,

    MILESTONES_SELECTED_ITEMS_CHANGE
} from './actions_def';

const INIT_STATE = {
    milestones: [],
    errors: [],
    filter: null,
    searchKeyword: '',
    orderColumn: null,
    isLoading: false,
    isLoaded: false,
    saving: false,
    selectedItems: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case MILESTONES_GET_LIST:
            return {
                ...state,
                isLoading: true,
                isLoaded: false
            };

        case MILESTONES_GET_LIST_SUCCESS:
            // console.log(action.payload)
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                milestones: action.payload,
            };

        case MILESTONES_GET_LIST_ERROR:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                error: action.payload
            };

        case MILESTONES_GET_LIST_WITH_FILTER:
            if (action.payload.column === '' || action.payload.value === '') {
                return {
                    ...state,
                    isLoading: false,
                    isLoaded: true,
                    milestones: state.allMilestonesItems,
                    filter: null,
                };
            }
            // eslint-disable-next-line no-case-declarations
            const filteredItems = state.allMilestonesItems.filter(
                (item) => item[action.payload.column] === action.payload.value
            );
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                milestones: filteredItems,
                filter: {
                    column: action.payload.column,
                    value: action.payload.value,
                },
            };

        case MILESTONES_GET_LIST_WITH_ORDER:
            if (action.payload === '') {
                return {
                    ...state,
                    isLoading: false,
                    isLoaded: true,
                    milestones: state.milestones,
                    orderColumn: null,
                };
            }
            // eslint-disable-next-line no-case-declarations
            const sortedItems = state.milestones.sort((a, b) => {
                if (a[action.payload] < b[action.payload]) return -1;
                if (a[action.payload] > b[action.payload]) return 1;
                return 0;
            });
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                milestones: sortedItems,
                orderColumn: state.orderColumns.find(
                    (x) => x.column === action.payload
                ),
            };

        case MILESTONES_GET_LIST_SEARCH:
            if (action.payload === '') {
                return { ...state, milestones: state.allMilestonesItems };
            }
            // eslint-disable-next-line no-case-declarations
            const keyword = action.payload.toLowerCase();
            // eslint-disable-next-line no-case-declarations
            const searchItems = state.allMilestonesItems.filter(
                (item) =>
                    item.title.toLowerCase().indexOf(keyword) > -1 ||
                    item.detail.toLowerCase().indexOf(keyword) > -1 ||
                    item.status.toLowerCase().indexOf(keyword) > -1 ||
                    item.category.toLowerCase().indexOf(keyword) > -1 ||
                    item.label.toLowerCase().indexOf(keyword) > -1
            );
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                milestones: searchItems,
                searchKeyword: action.payload,
            };

        case MILESTONES_SELECTED_ITEMS_CHANGE:
            return {
                ...state, isLoading: false,
                isLoaded: true, selectedItems: action.payload
            };

        case MILESTONES_DELETE_ITEMS:
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
                saving: true
            };

        case MILESTONES_DELETE_ITEMS_SUCCESS:

            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                saving: false,
                saved: true,
                errors: [],
                allMilestonesItems: state.allMilestonesItems.filter(item => item.id !== action.payload.rfc_id),
                milestones: state.milestones.filter(item => item.id !== action.payload.rfc_id),
            };

        case MILESTONES_DELETE_ITEMS_ERROR:
            // console.log("Failed", action.payload)
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                saving: false,
                saved: true,
                errors: action.payload.errors.map(error => (
                    {
                        field: error.field,
                        error_code: error.code
                    }
                ))
            };

        default:
            return { ...state };
    }
};
