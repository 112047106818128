
import { combineReducers } from 'redux';
import contactDetails from './details/reducer';
import contactsList from './list/reducer';
// import contactsStatuses from './statuses/reducer';

const reducers = combineReducers({
  contactDetails,
  contactsList,
  // contactsStatuses
});

export default reducers;