
import { combineReducers } from 'redux';
import details from './details/reducer';
import list from './list/reducer';
import statuses from './statuses/reducer';

const reducers = combineReducers({
    details,
    list,
    statuses
});

export default reducers;