import axios from "axios";
import { servicePath } from 'constants/defaultValues';
import { getAccessToken } from 'helpers/Firebase';
import moment from 'moment';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { getMilestonesStatusesListSuccess, getMilestonesStatusesListError } from './actions';
import {
    MILESTONES_GET_STATUSES_LIST
} from './actions_def';


const apiUrl = servicePath;

const getMilestonesStatusesListRequest = async () => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success) => {
        const instance = axios.create({
            method: 'get',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });
        instance.get('/milestones/statuses/', { crossdomain: true })
            .then(response => success(response.data ?
                response.data.map(item => (
                    {
                        id: item.rfc_id,
                        title: item.rfc_title,
                        date: moment(item.date_created).format("Do MMM YYYY"),
                        change_date: moment(item.date_modified).format("Do MMM YYYY (hh:mm)"),
                        end_date: moment(item.rfc_implementation_end_date).format("Do MMM YYYY (hh:mm)"),
                        status: item.rfc_status,
                        statusColor: 'primary'
                    }
                )
                )
                :
                []));
    })
        .then((response) => response)
        .catch((error) => error);
};

function* getMilestonesStatuses() {
    try {
        const response = yield call(getMilestonesStatusesListRequest);
        yield put(getMilestonesStatusesListSuccess(response));
    } catch (error) {
        yield put(getMilestonesStatusesListError(error));
    }
}


export function* watchGetList() {
    yield takeEvery(MILESTONES_GET_STATUSES_LIST, getMilestonesStatuses);
}


export default function* rootSaga() {
    yield all([
        fork(watchGetList),
    ]);
}
