import axios from "axios";
import { servicePath } from 'constants/defaultValues';
import { getAccessToken } from 'helpers/Firebase';
import { getCurrentUser } from 'helpers/Utils';
import moment from 'moment';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    TAGS_FETCH_ITEM,
    TAGS_ADD_ITEM,
    TAGS_DELETE_ITEM
} from './actions_def';
import {
    getTagItemSuccess,
    getTagItemError,
    addTagItemSuccess,
    addTagItemError,
    deleteTagItemSuccess,
    deleteTagItemError


} from './actions';


const apiUrl = servicePath;


const getTagItemRequest = async () => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success) => {
        const instance = axios.create({
            method: 'get',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });
        instance.get('/tags/', { crossdomain: true })
            .then(response => success(response.data ?
                response.data.map(item => (
                    {
                        id: item.rfc_id,
                        title: item.rfc_title,
                        date: moment(item.date_created).format("Do MMM YYYY"),
                        change_date: moment(item.date_modified).format("Do MMM YYYY (hh:mm)"),
                        end_date: moment(item.rfc_implementation_end_date).format("Do MMM YYYY (hh:mm)"),
                        status: item.rfc_status,
                        statusColor: 'primary'
                    }
                )
                )
                :
                []));
    })
        .then((response) => response)
        .catch((error) => error);
};

function* getTagItem() {
    try {
        const response = yield call(getTagItemRequest);
        yield put(getTagItemSuccess(response));
    } catch (error) {
        yield put(getTagItemError(error));
    }
}


const addTagItemRequest = async (item) => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success, fail) => {

        // success(notificationListData.data);
        var _user = getCurrentUser(); // JSON.parse(localStorage.getItem("user"));
        const instance = axios.create({
            method: 'post',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });

        // console.log('Sending :', {
        //   rfc_title: item.title,
        //   rfc_type: item.type ? item.type : 1,
        //   rfc_summary: item.detail ? item.detail : ' ',
        // })
        let _status = 1;
        switch (item.status) {
            case "PENDING": _status = 1; break;
            default: _status = 1;
        };

        let _sendingPayload = {
            rfc_title: item.title,
            rfc_type: item.type ? item.type : 1,
            rfc_summary: item.detail ? item.detail : ' ',
            rfc_implementation_start_date: item.start_date ? item.start_date : new Date(),
            rfc_status: _status,
            rfc_lead: _user.uid
        };

        // console.log(_sendingPayload, getAccessToken());
        instance.post('/tags/',
            _sendingPayload,
            { crossdomain: true })
            .then(response => {
                if (response.data) {
                    if (response.data.status === "fail") {
                        fail(response.data);
                    }
                    else if (response.data.status === "success") {
                        success(response.data);
                    }
                    else {
                        console.log('Inconsistent behaviour', response.data.status)
                    }
                }
            }).catch((error) => {
                console.log(error)
            });;
    })
        .then((response) => response)
        .catch((error) => error);
};

function* addTagItem({ payload }) {

    try {
        const response = yield call(addTagItemRequest, payload);
        console.log('Response :', response, response.status === "fail")
        if (response.status !== "fail") {
            yield put(addTagItemSuccess(response));
        }
        else {
            yield put(addTagItemError(response));
        }
    } catch (error) {
        yield put(addTagItemError(error));
    }
}


const deleteTagRequest = async (item) => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success, fail) => {
        console.log(item)
        const instance = axios.create({
            method: 'delete',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });

        instance.delete('/tags/' + item + '/',
            { crossdomain: true })
            .then(response => {
                if (response.data) {
                    if (response.data.status === "fail") {
                        fail(response.data);
                    }
                    else if (response.data.status === "success" || response.data.status === "ok") {
                        success(response.data);
                    }
                    else {
                        console.log('Inconsistent behaviour', response.data.status);
                    }
                }
            }).catch((error) => {
                console.log(error);
            });
    })
        .then((response) => response)
        .catch((error) => error);
};

function* deleteTagItem({ payload }) {
    // console.log(payload);
    try {
        const response = yield call(deleteTagRequest, payload);
        console.log('Response :', response, response.status === "fail")
        if (response.status !== "fail") {
            yield put(deleteTagItemSuccess(response));
        }
        else {
            yield put(deleteTagItemError(response));
        }
    } catch (error) {
        yield put(addTagItemError(error));
    }
}


export function* watchFetchItem() {
    yield takeEvery(TAGS_FETCH_ITEM, getTagItem);
}

export function* watchAddItem() {
    yield takeEvery(TAGS_ADD_ITEM, addTagItem);
}
export function* watchDeleteItem() {
    yield takeEvery(TAGS_DELETE_ITEM, deleteTagItem);
}


export default function* rootSaga() {
    yield all([
        fork(watchAddItem),
        fork(watchFetchItem),
        fork(watchDeleteItem),
    ]);
}
