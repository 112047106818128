import { adminRoot } from './defaultValues';
import { isAuthGuardActive, currentUser, UserRole } from 'constants/defaultValues';


const data = [
    {
        id: 'projects',
        icon: 'iconsminds-box-with-folders',
        label: 'menu.work',
        to: `${adminRoot}/projects`,
        roles: [UserRole.Admin, UserRole.Editor],
    },

    {
        id: 'dashboards',
        icon: 'simple-icon-pie-chart',
        label: 'menu.welcome',
        to: `${adminRoot}/welcome`,
        roles: [UserRole.Admin, UserRole.Editor],
    },

    {
        id: 'docs',
        icon: 'iconsminds-library',
        label: 'menu.docs',
        to: 'https://docs.app.co.il/',
        newWindow: true,
        roles: [UserRole.Admin, UserRole.Editor],
    },
];
export default data;
