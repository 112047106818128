// eslint-disable-next-line import/no-cycle
import {
    WORK_DELETE_ITEMS,
    WORK_DELETE_ITEMS_ERROR, WORK_DELETE_ITEMS_SUCCESS, WORK_GET_LIST,
    WORK_GET_LIST_ERROR,
    WORK_GET_LIST_SEARCH, WORK_GET_LIST_SUCCESS,
    WORK_GET_LIST_WITH_FILTER,
    WORK_GET_LIST_WITH_ORDER,
    WORK_SELECTED_ITEMS_CHANGE
} from './actions_def';
export const getWorkList = (payload) => ({
    type: WORK_GET_LIST,
    payload: payload,
});

export const getWorkListSuccess = (items) => ({
    type: WORK_GET_LIST_SUCCESS,
    payload: items,
});

export const getWorkListError = (error) => ({
    type: WORK_GET_LIST_ERROR,
    payload: error,
});

export const getWorkListWithFilter = (column, value) => ({
    type: WORK_GET_LIST_WITH_FILTER,
    payload: { column, value },
});

export const getWorkListWithOrder = (column) => ({
    type: WORK_GET_LIST_WITH_ORDER,
    payload: column,
});

export const getWorkListSearch = (keyword) => ({
    type: WORK_GET_LIST_SEARCH,
    payload: keyword,
});

export const selectedWorkItemsChange = (selectedItems) => ({
    type: WORK_SELECTED_ITEMS_CHANGE,
    payload: selectedItems,
});



export const deleteWorkList = (items) => ({
    type: WORK_DELETE_ITEMS,
    payload: items,
});

export const deleteWorkListSuccess = (items) => ({
    type: WORK_DELETE_ITEMS_SUCCESS,
    payload: items,
});

export const deleteWorkListError = (error) => ({
    type: WORK_DELETE_ITEMS_ERROR,
    payload: error,
});
