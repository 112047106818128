// eslint-disable-next-line import/no-cycle
import {
  EXPERTS_GET_STATUSES_LIST,
  EXPERTS_GET_STATUSES_LIST_SUCCESS,
  EXPERTS_GET_STATUSES_LIST_ERROR,
} from './actions_def';

export const getExpertsStatusesList = () => ({
  type: EXPERTS_GET_STATUSES_LIST,
});

export const getExpertsStatusesListSuccess = (items) => ({
  type: EXPERTS_GET_STATUSES_LIST_SUCCESS,
  payload: items,
});

export const getExpertsStatusesListError = (error) => ({
  type: EXPERTS_GET_STATUSES_LIST_ERROR,
  payload: error,
});
