import {
    ISSUES_DELETE_ITEMS,
    ISSUES_DELETE_ITEMS_ERROR, ISSUES_DELETE_ITEMS_SUCCESS, ISSUES_GET_LIST,
    ISSUES_GET_LIST_ERROR,
    ISSUES_GET_LIST_SEARCH, ISSUES_GET_LIST_SUCCESS,
    ISSUES_GET_LIST_WITH_FILTER,
    ISSUES_GET_LIST_WITH_ORDER,
    ISSUES_SELECTED_ITEMS_CHANGE,
    ISSUES_SAVE_ITEM_SUCCESS,
    ISSUES_ADD_ITEM_SUCCESS
} from 'projects/redux/issues/actions_def';

const INIT_STATE = {
    allIssues: [],
    issues: [],
    errors: [],
    filter: null,
    searchKeyword: '',
    orderColumn: null,
    isLoaded: false,
    isLoading: false,
    totalItems: 0,
    saving: false,
    // labels: [
    //     { label: 'EDUCATION', color: 'secondary' },
    //     { label: 'NEW FRAMEWORK', color: 'primary' },
    //     { label: 'PERSONAL', color: 'info' },
    // ],
    orderColumns: [
        { column: 'title', label: 'Title' },
        { column: 'category', label: 'Category' },
        { column: 'status', label: 'Status' },
        { column: 'label', label: 'Label' },
    ],
    categories: ['Flexbox', 'Sass', 'React'],
    selectedItems: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ISSUES_GET_LIST:
            return {
                ...state,
                isLoaded: false,
                isLoading: true,
            };

        case ISSUES_GET_LIST_SUCCESS:
            // console.log(action.payload)
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                allIssuesItems: action.payload,
                issues: action.payload,
                totalItems: action.payload.length
            };

        case ISSUES_GET_LIST_ERROR:
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                error: action.payload
            };

        case ISSUES_GET_LIST_WITH_FILTER:
            if (action.payload.column === '' || action.payload.value === '') {
                return {
                    ...state,
                    isLoaded: false,
                    isLoading: true,
                    issuesItems: state.allIssuesItems,
                    filter: null,
                };
            }
            // eslint-disable-next-line no-case-declarations
            const filteredItems = state.allIssuesItems.filter(
                (item) => item[action.payload.column] === action.payload.value
            );
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                issuesItems: filteredItems,
                filter: {
                    column: action.payload.column,
                    value: action.payload.value,
                },
            };

        case ISSUES_GET_LIST_WITH_ORDER:
            if (action.payload === '') {
                return {
                    ...state,
                    isLoaded: false,
                    isLoading: true,
                    issuesItems: state.issuesItems,
                    orderColumn: null,
                };
            }
            // eslint-disable-next-line no-case-declarations
            const sortedItems = state.issuesItems.sort((a, b) => {
                if (a[action.payload] < b[action.payload]) return -1;
                if (a[action.payload] > b[action.payload]) return 1;
                return 0;
            });
            return {
                ...state,
                isLoaded: false,
                isLoading: true,
                issuesItems: sortedItems,
                orderColumn: state.orderColumns.find(
                    (x) => x.column === action.payload
                ),
            };

        case ISSUES_GET_LIST_SEARCH:
            if (action.payload === '') {
                return { ...state, issuesItems: state.allIssuesItems };
            }
            // eslint-disable-next-line no-case-declarations
            const keyword = action.payload.searchTerm.toLowerCase();
            // eslint-disable-next-line no-case-declarations
            console.log(keyword, state.allIssuesItems)
            // const searchItems = state.allIssuesItems.filter(
            //     (item) =>
            //         item.title.toLowerCase().indexOf(keyword) > -1 ||
            //         item.detail.toLowerCase().indexOf(keyword) > -1 ||
            //         item.status.toLowerCase().indexOf(keyword) > -1 ||
            //         item.category.toLowerCase().indexOf(keyword) > -1 ||
            //         item.label.toLowerCase().indexOf(keyword) > -1
            // );
            return {
                ...state,
                isLoaded: false,
                isLoading: true,
                // issuesItems: searchItems,
                searchKeyword: keyword,
            };

        case ISSUES_SELECTED_ITEMS_CHANGE:
            return {
                ...state, isLoaded: false,
                isLoading: false, selectedItems: action.payload
            };

        case ISSUES_DELETE_ITEMS:
            return {
                ...state,
                isLoaded: false,
                isLoading: true,
                saving: true
            };

        case ISSUES_DELETE_ITEMS_SUCCESS:

            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                saving: false,
                saved: true,
                errors: [],
                allIssuesItems: state.allIssuesItems.filter(item => item.id !== action.payload.rfc_id),
                issuesItems: state.issuesItems.filter(item => item.id !== action.payload.rfc_id),
            };

        case ISSUES_DELETE_ITEMS_ERROR:
            // console.log("Failed", action.payload)
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                saving: false,
                saved: true,
                errors: action.payload.errors.map(error => (
                    {
                        field: error.field,
                        error_code: error.code
                    }
                ))
            };

        case ISSUES_SAVE_ITEM_SUCCESS:

            return {
                ...state,
                issues: [...state.issues, action.payload]
            };


        case ISSUES_ADD_ITEM_SUCCESS:
            console.log("Issue added. Adding to list.")
            return {
                ...state,
                issues: [...state.issues, action.payload]
            };

        default:
            return { ...state };
    }
};
