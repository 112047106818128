export const ISSUES_PRIORITIES_GET_LIST = 'ISSUES_PRIORITIES_GET_LIST';
export const ISSUES_PRIORITIES_GET_LIST_SUCCESS = 'ISSUES_PRIORITIES_GET_LIST_SUCCESS';
export const ISSUES_PRIORITIES_GET_LIST_ERROR = 'ISSUES_PRIORITIES_GET_LIST_ERROR';
export const ISSUES_PRIORITIES_GET_LIST_WITH_FILTER = 'ISSUES_PRIORITIES_GET_LIST_WITH_FILTER';
export const ISSUES_PRIORITIES_GET_LIST_WITH_ORDER = 'ISSUES_PRIORITIES_GET_LIST_WITH_ORDER';
export const ISSUES_PRIORITIES_GET_LIST_SEARCH = 'ISSUES_PRIORITIES_GET_LIST_SEARCH';


export const ISSUES_PRIORITIES_DELETE_ITEMS = 'ISSUES_PRIORITIES_DELETE_ITEMS';
export const ISSUES_PRIORITIES_DELETE_ITEMS_SUCCESS = 'ISSUES_PRIORITIES_DELETE_ITEMS_SUCCESS';
export const ISSUES_PRIORITIES_DELETE_ITEMS_ERROR = 'ISSUES_PRIORITIES_DELETE_ITEMS_ERROR';


export const ISSUES_PRIORITIES_INITIALIZE_LIST = 'ISSUES_PRIORITIES_INITIALIZE_LIST';
export const ISSUES_PRIORITIES_INITIALIZE_LIST_SUCCESS = 'ISSUES_PRIORITIES_INITIALIZE_LIST_SUCCESS';
export const ISSUES_PRIORITIES_INITIALIZE_LIST_ERROR = 'ISSUES_PRIORITIES_INITIALIZE_LIST_ERROR';

export const ISSUES_PRIORITIES_SELECTED_ITEMS_CHANGE = 'ISSUES_PRIORITIES_SELECTED_ITEMS_CHANGE';
