import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import chatSagas from './chat/saga';
import contactsSagas from './contacts/sagas';
// import projectDetailSagas from './projectDetail/saga';
import expertsSagas from 'experts/redux/sagas';
// import issuesSagas from 'projects/redux/issues/sagas';
// import milestonesSagas from 'projects/redux/milestones/sagas';
import notificationsSagas from 'notifications/redux/sagas';
import projectsSagas from 'projects/redux/sagas';
// import surveyListSagas from './surveyList/saga';
// import surveyDetailSagas from './surveyDetail/saga';
import tagsSagas from './tags/sagas';
import todoSagas from './todo/sagas';
// import wikiSagas from 'wiki/redux/sagas';
export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        chatSagas(),
        contactsSagas(),
        expertsSagas(),
        // issuesSagas(),
        // milestonesSagas(),
        notificationsSagas(),
        projectsSagas(),
        // surveyListSagas(),
        // surveyDetailSagas(),
        todoSagas(),
        tagsSagas(),
        // wikiSagas(),
    ]);
}
