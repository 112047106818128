// eslint-disable-next-line import/no-cycle
import {
  EXPERTS_DELETE_ITEMS,
  EXPERTS_DELETE_ITEMS_ERROR, EXPERTS_DELETE_ITEMS_SUCCESS, EXPERTS_GET_LIST,
  EXPERTS_GET_LIST_ERROR,
  EXPERTS_GET_LIST_SEARCH, EXPERTS_GET_LIST_SUCCESS,
  EXPERTS_GET_LIST_WITH_FILTER,
  EXPERTS_GET_LIST_WITH_ORDER,
  EXPERTS_SELECTED_ITEMS_CHANGE
} from './actions_def';
export const getExpertsList = () => ({
  type: EXPERTS_GET_LIST,
});

export const getExpertsListSuccess = (items) => ({
  type: EXPERTS_GET_LIST_SUCCESS,
  payload: items,
});

export const getExpertsListError = (error) => ({
  type: EXPERTS_GET_LIST_ERROR,
  payload: error,
});

export const getExpertsListWithFilter = (column, value) => ({
  type: EXPERTS_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getExpertsListWithOrder = (column) => ({
  type: EXPERTS_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getExpertsListSearch = (keyword) => ({
  type: EXPERTS_GET_LIST_SEARCH,
  payload: keyword,
});

export const selectedExpertsItemsChange = (selectedItems) => ({
  type: EXPERTS_SELECTED_ITEMS_CHANGE,
  payload: selectedItems,
});



export const deleteExpertsList = (items) => ({
  type: EXPERTS_DELETE_ITEMS,
  payload: items,
});

export const deleteExpertsListSuccess = (items) => ({
  type: EXPERTS_DELETE_ITEMS_SUCCESS,
  payload: items,
});

export const deleteExpertsListError = (error) => ({
  type: EXPERTS_DELETE_ITEMS_ERROR,
  payload: error,
});
