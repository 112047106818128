// eslint-disable-next-line import/no-cycle
import {
    DOCUMENTS_DELETE_ITEMS,
    DOCUMENTS_DELETE_ITEMS_ERROR, DOCUMENTS_DELETE_ITEMS_SUCCESS, DOCUMENTS_GET_LIST,
    DOCUMENTS_GET_LIST_ERROR,
    DOCUMENTS_GET_LIST_SEARCH, DOCUMENTS_GET_LIST_SUCCESS,
    DOCUMENTS_GET_LIST_WITH_FILTER,
    DOCUMENTS_GET_LIST_WITH_ORDER,
    DOCUMENTS_SELECTED_ITEMS_CHANGE
} from './actions_def';
export const getDocumentsList = (payload) => ({
    type: DOCUMENTS_GET_LIST,
    payload: payload,
});

export const getMyPostsList = (payload) => ({
    type: DOCUMENTS_GET_LIST,
    payload: payload,
});

export const getDocumentsListSuccess = (items) => ({
    type: DOCUMENTS_GET_LIST_SUCCESS,
    payload: items,
});

export const getDocumentsListError = (error) => ({
    type: DOCUMENTS_GET_LIST_ERROR,
    payload: error,
});

export const getDocumentsListWithFilter = (column, value) => ({
    type: DOCUMENTS_GET_LIST_WITH_FILTER,
    payload: { column, value },
});

export const getDocumentsListWithOrder = (column) => ({
    type: DOCUMENTS_GET_LIST_WITH_ORDER,
    payload: column,
});

export const getDocumentsListSearch = (keyword) => ({
    type: DOCUMENTS_GET_LIST_SEARCH,
    payload: keyword,
});

export const selectedDocumentsItemsChange = (selectedItems) => ({
    type: DOCUMENTS_SELECTED_ITEMS_CHANGE,
    payload: selectedItems,
});



export const deleteDocumentsList = (items) => ({
    type: DOCUMENTS_DELETE_ITEMS,
    payload: items,
});

export const deleteDocumentsListSuccess = (items) => ({
    type: DOCUMENTS_DELETE_ITEMS_SUCCESS,
    payload: items,
});

export const deleteDocumentsListError = (error) => ({
    type: DOCUMENTS_DELETE_ITEMS_ERROR,
    payload: error,
});
