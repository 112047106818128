export const ISSUES_FETCH_ITEM = 'ISSUES_FETCH_ITEM';
export const ISSUES_FETCH_ITEM_SUCCESS = 'ISSUES_FETCH_ITEM_SUCCESS';
export const ISSUES_FETCH_ITEM_ERROR = 'ISSUES_FETCH_ITEM_ERROR';

export const ISSUES_ADD_ITEM = 'ISSUES_ADD_ITEM';
export const ISSUES_ADD_ITEM_SUCCESS = 'ISSUES_ADD_ITEM_SUCCESS';
export const ISSUES_ADD_ITEM_ERROR = 'ISSUES_ADD_ITEM_ERROR';

export const ISSUES_DELETE_ITEM = 'ISSUES_DELETE_ITEM';
export const ISSUES_DELETE_ITEM_SUCCESS = 'ISSUES_DELETE_ITEM_SUCCESS';
export const ISSUES_DELETE_ITEM_ERROR = 'ISSUES_DELETE_ITEM_ERROR';

export const ISSUES_SAVE_ITEM = 'ISSUES_SAVE_ITEM';
export const ISSUES_SAVE_ITEM_SUCCESS = 'ISSUES_SAVE_ITEM_SUCCESS';
export const ISSUES_SAVE_ITEM_ERROR = 'ISSUES_SAVE_ITEM_ERROR';
