import {
    RESET_ACTIONS,
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_ERROR,
    FETCH_USER_PROFILE,
    FETCH_USER_PROFILE_SUCCESS,
    FETCH_USER_PROFILE_ERROR,
    FETCH_LOCAL_USER_PROFILE,
    FETCH_LOCAL_USER_PROFILE_SUCCESS,
    FETCH_LOCAL_USER_PROFILE_ERROR,
    SET_USER_PROFILE,
    SET_USER_PROFILE_SUCCESS,
    SET_USER_PROFILE_ERROR,
    SAVE_USER_PROFILE,
    SAVE_USER_PROFILE_SUCCESS,
    SAVE_USER_PROFILE_ERROR,
    CREATE_USER_PROFILE,
    CREATE_USER_PROFILE_SUCCESS,
    CREATE_USER_PROFILE_ERROR,
    DELETE_USER_PROFILE,
    DELETE_USER_PROFILE_SUCCESS,
    DELETE_USER_PROFILE_ERROR,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
} from './actions_def';
import { isAuthGuardActive, currentUser, UserRole } from 'constants/defaultValues';
import { getCurrentUser, setCurrentUser } from 'helpers/Utils';


const INIT_STATE = {
    currentUser: isAuthGuardActive ? currentUser : null,//getCurrentUser(),
    notifications: [],
    forgotUserMail: '',
    newPassword: '',
    resetPasswordCode: '',
    // displayName: localStorage.getItem('name'),
    // photoURL: localStorage.getItem('photo'),
    // userObj: JSON.parse(localStorage.getItem('user')),
    isLoading: false,
    isLoaded: false,
    isSaving: false,
    isSaved: false,
    hasError: false,
    profile: null,
    workProfile: null,
    error: '',
    cover: '/assets/photoURL/social/defaultCover.jpg'
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case RESET_ACTIONS:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                isSaving: false,
                isSaved: false,
                hasError: false,

                error: ''
            };


        case LOGIN_USER:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                isSaving: false,
                isSaved: false,
                hasError: false,
                error: ''
            };

        case LOGIN_USER_SUCCESS:

            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                isSaving: false,
                isSaved: true,
                currentUser: action.payload,
                error: '',
            };
        case LOGIN_USER_ERROR:
            return {
                ...state,
                currentUser: null,
                hasError: true,
                error: action.payload.message,
                isLoading: false,
                isLoaded: true,
                isSaving: false,
                isSaved: false,
            };
        case FETCH_USER_PROFILE:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                isSaving: false,
                isSaved: false,
                hasError: false,
                error: ''
            };


        case FETCH_USER_PROFILE_SUCCESS:

            if (action.payload.status === 204) {
                setCurrentUser(
                    {
                        ...getCurrentUser(),
                        role: UserRole.Guest,
                    });

                return {
                    ...state,
                    isLoading: false,
                    isLoaded: true,
                    isSaved: false,
                    isSaving: false,

                    currentUser:
                    {
                        ...currentUser,
                        role: UserRole.Guest,
                    },

                    error: '',
                };
            }
            else {
                // console.log(action.payload);
                setCurrentUser(
                    {
                        ...getCurrentUser(),

                        id: action.payload.userid,
                        // photoURL: action.payload.avatar,
                        displayName: action.payload.displayName,
                        // cover: action.payload.cover,
                        role: UserRole.Editor,
                        profile: action.payload,
                    });

                return {
                    ...state,
                    isLoading: false,
                    isLoaded: true,
                    isSaved: false,
                    isSaving: false,
                    currentUser:
                    {
                        ...currentUser,
                        id: action.payload && action.payload.userid,
                        // photoURL: action.payload && action.payload.avatar,
                        displayName: action.payload && action.payload.displayName,
                        // cover: action.payload && action.payload.cover,
                        role: UserRole.Editor,
                        profile: action.payload
                    },

                    error: '',
                };
            }

        case FETCH_USER_PROFILE_ERROR:
            // console.log(action.payload);
            setCurrentUser(
                {
                    ...getCurrentUser(),
                    isLoaded: true,
                    isSaved: false,
                    isSaving: false,
                    hasError: true
                });

            return {
                ...state,
                isLoading: false,
                isSaved: false,
                isSaving: false,
                hasError: true,
                currentUser: null,
                error: action.payload.message || action.payload,
            };

        case FETCH_LOCAL_USER_PROFILE:
            // console.log(action.payload);
            return {
                ...state,
                isLoading: true,
                error: ''
            };


        case FETCH_LOCAL_USER_PROFILE_SUCCESS:
            // console.log(action.payload);

            return {
                ...state,
                currentUser: action.payload,
                isLoading: false,
                isLoaded: true,
                error: ''
            };


        case FETCH_LOCAL_USER_PROFILE_ERROR:
            // console.log(action.payload);

            return {
                ...state,
                currentUser: action.payload,
                isLoading: true,
                error: ''
            };


        case SET_USER_PROFILE:
            return { ...state, isLoading: true, error: '' };
        case SET_USER_PROFILE_SUCCESS:
            // console.log(action.payload, action.payload.payload.user,)

            return {
                ...state,
                isLoading: false,
                currentUser:
                    action.payload.payload.user
                ,
                error: '',
            };
        case SET_USER_PROFILE_ERROR:
            return {
                ...state,
                isLoading: false,
                currentUser: null,
                error: action.payload.message,
            };
        case SAVE_USER_PROFILE:
            return {
                ...state,
                isLoading: true,
                isSaved: false,
                isSaving: true,

                error: ''
            };
        case SAVE_USER_PROFILE_SUCCESS:
            // console.log(action.payload)
            return {
                ...state,
                isSaving: false,
                isSaved: true,
                isLoading: false,
                isLoaded: true,
                currentUser:
                {
                    ...currentUser,
                    id: action.payload && action.payload[0].id,
                    photoURL: action.payload && action.payload[0].avatar,
                    displayName: action.payload && action.payload[0].displayName,
                    cover: action.payload && action.payload[0].cover,
                    isLoaded: true,
                    role: UserRole.Editor,
                    profile: action.payload[0]

                },

                error: '',
            };
        case SAVE_USER_PROFILE_ERROR:
            return {
                ...state,
                currentUser: {
                    ...currentUser,
                    isLoading: false,
                    isLoaded: true,
                    isSaving: false,
                    isSaved: false,
                    hasError: true,
                    error: action.payload.message,
                }
            }
        case CREATE_USER_PROFILE:
            return {
                ...state,
                // isLoading: true, 
                isSaving: true,
                isSaved: false,
                error: ''
            };
        case CREATE_USER_PROFILE_SUCCESS:
            console.log({
                ...state,
                isLoading: false,
                isLoaded: true,
                isSaving: false,
                isSaved: true,
                error: '',
            })
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                isSaving: false,
                isSaved: true,
                currentUser:
                {
                    ...currentUser,
                    id: action.payload && action.payload.userid,
                    // photoURL: action.payload && action.payload.avatar,
                    displayName: action.payload && action.payload.displayName,
                    // cover: action.payload && action.payload.cover,
                    role: UserRole.Editor,
                    profile: action.payload
                },

                error: '',
            };

        case CREATE_USER_PROFILE_ERROR:
            // console.log(action.payload, {
            //             ...state,
            //             currentUser: {
            //                 ...currentUser,
            //                 isLoading: false,
            //                     isLoaded: true,
            //                         hasError: true,
            //                             error: action.payload.message,
            //             }
            // })

            return {
                ...state,
                currentUser: {
                    ...currentUser,
                    isLoading: false,
                    isLoaded: true,
                    isSaving: false,
                    isSaved: false,
                    hasError: true,
                    error: action.payload.message,
                }
            }

        case DELETE_USER_PROFILE:
            return {
                ...state,
                // isLoading: true, 
                isSaving: true,
                isSaved: false,
                error: ''
            };
        case DELETE_USER_PROFILE_SUCCESS:
            console.log({
                ...state,
                isLoading: false,
                isLoaded: true,
                isSaving: false,
                isSaved: true,
                error: '',
            })
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                isSaving: false,
                isSaved: true,
                currentUser:
                {
                    ...currentUser,
                    id: action.payload && action.payload.userid,
                    // photoURL: action.payload && action.payload.avatar,
                    displayName: action.payload && action.payload.displayName,
                    // cover: action.payload && action.payload.cover,
                    role: UserRole.Editor,
                    profile: action.payload
                },

                error: '',
            };

        case DELETE_USER_PROFILE_ERROR:

            return {
                ...state,
                currentUser: {
                    ...currentUser,
                    isLoading: false,
                    isLoaded: true,
                    isSaving: false,
                    isSaved: false,
                    hasError: true,
                    error: action.payload.message,
                }
            }




        case FORGOT_PASSWORD:
            return { ...state, isLoading: true, error: '' };
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                forgotUserMail: action.payload,
                error: '',
            };
        case FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                isLoading: false,
                forgotUserMail: '',
                error: action.payload.message,
            };
        case RESET_PASSWORD:
            return { ...state, isLoading: true, error: '' };
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                newPassword: action.payload,
                resetPasswordCode: '',
                error: '',
            };
        case RESET_PASSWORD_ERROR:
            return {
                ...state,
                isLoading: false,
                newPassword: '',
                resetPasswordCode: '',
                error: action.payload.message,
            };
        case REGISTER_USER:
            return { ...state, isLoading: true, error: '' };
        case REGISTER_USER_SUCCESS:
            // if (action.payload && action.payload.length > 0) {
            //   setCurrentUser({ ...getCurrentUser(), profile: action.payload[0] });
            // }
            return {
                ...state,
                isLoading: false,
                currentUser: { ...currentUser, profile: action.payload },
                error: '',
            };
        case REGISTER_USER_ERROR:
            return {
                ...state,
                isLoading: false,
                currentUser: null,
                error: action.payload.message,
            };
        case LOGOUT_USER:
            // console.log('Logging out')

            return {
                ...state,
                currentUser: null,
                isLoaded: false,
                isLoading: false,
                error: ''
            };
        default:
            return { ...state };
    }
};
