export const NOTIFICATION_FETCH_ITEM = 'NOTIFICATION_FETCH_ITEM';
export const NOTIFICATION_FETCH_ITEM_SUCCESS = 'NOTIFICATION_FETCH_ITEM_SUCCESS';
export const NOTIFICATION_FETCH_ITEM_ERROR = 'NOTIFICATION_FETCH_ITEM_ERROR';

export const NOTIFICATION_RESET_ITEM = 'NOTIFICATION_RESET_ITEM';
export const NOTIFICATION_ADD_ITEM = 'NOTIFICATION_ADD_ITEM';
export const NOTIFICATION_ADD_ITEM_SUCCESS = 'NOTIFICATION_ADD_ITEM_SUCCESS';
export const NOTIFICATION_ADD_ITEM_ERROR = 'NOTIFICATION_ADD_ITEM_ERROR';

export const NOTIFICATION_DELETE_ITEM = 'NOTIFICATION_DELETE_ITEM';
export const NOTIFICATION_DELETE_ITEM_SUCCESS = 'NOTIFICATION_DELETE_ITEM_SUCCESS';
export const NOTIFICATION_DELETE_ITEM_ERROR = 'NOTIFICATION_DELETE_ITEM_ERROR';

export const NOTIFICATION_SAVE_ITEM = 'NOTIFICATION_SAVE_ITEM';
export const NOTIFICATION_SAVE_ITEM_SUCCESS = 'NOTIFICATION_SAVE_ITEM_SUCCESS';
export const NOTIFICATION_SAVE_ITEM_ERROR = 'NOTIFICATION_SAVE_ITEM_ERROR';
