import axios from "axios";
import { servicePath } from 'constants/defaultValues';
import { getAccessToken } from 'helpers/Firebase';
import moment from 'moment';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    deleteSurveysListError, deleteSurveysListSuccess,

    getSurveysListError,
    getSurveysListSuccess
} from './actions';
import {
    SURVEYS_DELETE_ITEMS, SURVEYS_GET_LIST, SURVEYS_GET_LIST_SEARCH,
    MY_POSTS_GET_LIST
} from './actions_def';


const apiUrl = servicePath;


function* getSurveysList({ payload }) {
    console.log(payload);;
    if (!payload) {
        yield put(getSurveysListError({ error: 'prerequisites' }));
    }

    try {
        const response = yield call(getSurveysListRequest, payload);
        yield put(getSurveysListSuccess(response));
    } catch (error) {
        yield put(getSurveysListError(error));
    }
}




function* getSurveysListSearch({ payload }) {
    // // console.log(payload);;
    if (!payload) {
        yield put(getSurveysListError({ error: 'prerequisites' }));
    }

    try {
        const response = yield call(getSurveysListSearchRequest, payload);
        yield put(getSurveysListSuccess(response));
    } catch (error) {
        yield put(getSurveysListError(error));
    }
}

const getSurveysListRequest = async (payload) => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success) => {
        const instance = axios.create({
            method: 'get',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });

        console.log(payload);;

        instance.get('/projects/' + payload.projectId + '/surveys/')
            .then(response => success(response.data ?
                response.data.map(item => (item))
                :
                [])
            );
    })
        .then((response) => response)
        .catch((error) => error);
};

const getSurveysListSearchRequest = async (payload) => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success) => {
        const instance = axios.create({
            method: 'post',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });

        // // console.log(payload);;

        instance.post('/projects/' + payload.projectId + '/surveys/?srch=' + payload.searchKeyword,
            {
                // crossdomain: true,
                projectId: payload.projectId,
                searchTerm: payload.searchTerm
            })
            .then(response => success(
                response.data ?
                    response.data.map(item => (item))
                    :
                    [])
            );
    })
        .then((response) => response)
        .catch((error) => error);
};
const deleteSurveysListRequest = async (items) => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success, fail) => {

        // success(notificationListData.data);
        // var _user = JSON.parse(localStorage.getItem("user"));
        const instance = axios.create({
            method: 'delete',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });

        // console.log('Sending :', {
        //   rfc_title: item.title,
        //   rfc_type: item.type ? item.type : 1,
        //   rfc_summary: item.detail ? item.detail : ' ',
        // })
        let _status = 1;
        switch (items.status) {
            case "PENDING": _status = 1; break;
            default: _status = 1;
        };

        items.map(item => {
            console.log(item)
            instance.delete('/surveys/' + item + '/',
                { crossdomain: true })
                .then(response => {
                    if (response.data) {
                        if (response.data.status === "fail") {
                            fail(response.data);
                        }
                        else if (response.data.status === "success" || response.data.status === "ok") {
                            success(response.data);
                        }
                        else {
                            console.log('Inconsistent behaviour', response.data.status)
                        }
                    }
                }).catch((error) => {
                    console.log(error)
                });
        });
    })
        .then((response) => response)
        .catch((error) => error);
};

function* deleteSurveysList({ payload }) {
    // // console.log(payload);;

    try {
        const response = yield call(deleteSurveysListRequest, payload);
        yield put(deleteSurveysListSuccess(response));
    } catch (error) {
        yield put(deleteSurveysListError(error));
    }
}



export function* watchGetSurveysList() {
    yield takeEvery(SURVEYS_GET_LIST, getSurveysList);
}


export function* watchGetSearchSurveysList() {
    yield takeEvery(SURVEYS_GET_LIST_SEARCH, getSurveysListSearch);
}

export function* watchDeleteSurveys() {
    yield takeEvery(SURVEYS_DELETE_ITEMS, deleteSurveysList);
}

export default function* rootSaga() {
    yield all([
        fork(watchGetSurveysList),
        fork(watchGetSearchSurveysList),
        fork(watchDeleteSurveys),
    ]);
}
