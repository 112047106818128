// eslint-disable-next-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import {
    NOTIFICATION_FETCH_ITEM,
    NOTIFICATION_FETCH_ITEM_SUCCESS,
    NOTIFICATION_FETCH_ITEM_ERROR,
    NOTIFICATION_RESET_ITEM,
    NOTIFICATION_ADD_ITEM,
    NOTIFICATION_ADD_ITEM_SUCCESS,
    NOTIFICATION_ADD_ITEM_ERROR,
    NOTIFICATION_DELETE_ITEM,
    NOTIFICATION_DELETE_ITEM_SUCCESS,
    NOTIFICATION_DELETE_ITEM_ERROR,
    NOTIFICATION_SAVE_ITEM,
    NOTIFICATION_SAVE_ITEM_SUCCESS,
    NOTIFICATION_SAVE_ITEM_ERROR,
} from './actions_def';

export const resetNotificationItem = (item) => ({
    type: NOTIFICATION_RESET_ITEM,
    payload: item,
});

export const getNotificationItem = (item) => ({
    type: NOTIFICATION_FETCH_ITEM,
    payload: item,
});

export const getNotificationItemSuccess = (items) => ({
    type: NOTIFICATION_FETCH_ITEM_SUCCESS,
    payload: items,
});

export const getNotificationItemError = (error) => ({
    type: NOTIFICATION_FETCH_ITEM_ERROR,
    payload: error,
});


export const addNotificationItem = (item) => ({
    type: NOTIFICATION_ADD_ITEM,
    payload: item,
});

export const addNotificationItemSuccess = (items) => ({
    type: NOTIFICATION_ADD_ITEM_SUCCESS,
    payload: items,
});

export const addNotificationItemError = (error) => ({
    type: NOTIFICATION_ADD_ITEM_ERROR,
    payload: error,
});



export const deleteNotificationItem = (item) => ({
    type: NOTIFICATION_DELETE_ITEM,
    payload: item,
});

export const deleteNotificationItemSuccess = (items) => ({
    type: NOTIFICATION_DELETE_ITEM_SUCCESS,
    payload: items,
});

export const deleteNotificationItemError = (error) => ({
    type: NOTIFICATION_DELETE_ITEM_ERROR,
    payload: error,
});




export const saveNotificationItem = (item) => ({
    type: NOTIFICATION_SAVE_ITEM,
    payload: item,
});

export const saveNotificationItemSuccess = (items) => ({
    type: NOTIFICATION_SAVE_ITEM_SUCCESS,
    payload: items,
});

export const saveNotificationItemError = (error) => ({
    type: NOTIFICATION_SAVE_ITEM_ERROR,
    payload: error,
});

