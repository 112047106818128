/* eslint-disable import/no-cycle */
/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE'

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES'
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME'
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU'
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES'
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = 'MENU_CHANGE_HAS_SUB_ITEM_STATUS'
export const MENU_SET_ITEMS = 'MENU_SET_ITEMS'
export const MENU_SET_SELECTED = 'MENU_SET_SELECTED'

// /* TODOAPP */
// export const TODO_GET_LIST = 'TODO_GET_LIST';
// export const TODO_GET_LIST_SUCCESS = 'TODO_GET_LIST_SUCCESS';
// export const TODO_GET_LIST_ERROR = 'TODO_GET_LIST_ERROR';
// export const TODO_GET_LIST_WITH_FILTER = 'TODO_GET_LIST_WITH_FILTER';
// export const TODO_GET_LIST_WITH_ORDER = 'TODO_GET_LIST_WITH_ORDER';
// export const TODO_GET_LIST_SEARCH = 'TODO_GET_LIST_SEARCH';
// export const TODO_ADD_ITEM = 'TODO_ADD_ITEM';
// export const TODO_ADD_ITEM_SUCCESS = 'TODO_ADD_ITEM_SUCCESS';
// export const TODO_ADD_ITEM_ERROR = 'TODO_ADD_ITEM_ERROR';
// export const TODO_SELECTED_ITEMS_CHANGE = 'TODO_SELECTED_ITEMS_CHANGE';

// /* PROJECTS APP */
// export const PROJECTS_GET_LIST = 'PROJECTS_GET_LIST';
// export const PROJECTS_GET_LIST_SUCCESS = 'PROJECTS_GET_LIST_SUCCESS';
// export const PROJECTS_GET_LIST_ERROR = 'PROJECTS_GET_LIST_ERROR';
// export const PROJECTS_GET_LIST_WITH_FILTER = 'PROJECTS_GET_LIST_WITH_FILTER';
// export const PROJECTS_GET_LIST_WITH_ORDER = 'PROJECTS_GET_LIST_WITH_ORDER';
// export const PROJECTS_GET_LIST_SEARCH = 'PROJECTS_GET_LIST_SEARCH';

// export const PROJECTS_SELECTED_ITEMS_CHANGE = 'PROJECTS_SELECTED_ITEMS_CHANGE';

// export const PROJECTS_ADD_ITEM = 'PROJECTS_ADD_ITEM';
// export const PROJECTS_ADD_ITEM_SUCCESS = 'PROJECTS_ADD_ITEM_SUCCESS';
// export const PROJECTS_ADD_ITEM_ERROR = 'PROJECTS_ADD_ITEM_ERROR';

// export const PROJECTS_DELETE_ITEM = 'PROJECTS_DELETE_ITEM';
// export const PROJECTS_DELETE_ITEM_SUCCESS = 'PROJECTS_DELETE_ITEM_SUCCESS';
// export const PROJECTS_DELETE_ITEM_ERROR = 'PROJECTS_DELETE_ITEM_ERROR';

// export const PROJECTS_DELETE_ITEMS = 'PROJECTS_DELETE_ITEMS';
// export const PROJECTS_DELETE_ITEMS_SUCCESS = 'PROJECTS_DELETE_ITEMS_SUCCESS';
// export const PROJECTS_DELETE_ITEMS_ERROR = 'PROJECTS_DELETE_ITEMS_ERROR';

/* CALENDAR APP */
export const CALENDAR_GET_EVENTS = 'CALENDAR_GET_EVENTS'
export const CALENDAR_GET_EVENTS_SUCCESS = 'CALENDAR_GET_EVENTS_SUCCESS'
export const CALENDAR_GET_EVENTS_ERROR = 'CALENDAR_GET_EVENTS_ERROR'
export const CALENDAR_CREATE_EVENT = 'CALENDAR_CREATE_EVENT'
export const CALENDAR_DELETE_EVENT = 'CALENDAR_DELETE_EVENT'
export const CALENDAR_SEARCH_EVENT = 'CALENDAR_SEARCH_EVENT'

/* CHAT APP */
export const CHAT_GET_CONTACTS = 'CHAT_GET_CONTACTS'
export const CHAT_GET_CONTACTS_SUCCESS = 'CHAT_GET_CONTACTS_SUCCESS'
export const CHAT_GET_CONTACTS_ERROR = 'CHAT_GET_CONTACTS_ERROR'
export const CHAT_GET_CONVERSATIONS = 'CHAT_GET_CONVERSATIONS'
export const CHAT_GET_CONVERSATIONS_SUCCESS = 'CHAT_GET_CONVERSATIONS_SUCCESS'
export const CHAT_GET_CONVERSATIONS_ERROR = 'CHAT_GET_CONVERSATIONS_ERROR'
export const CHAT_ADD_MESSAGE_TO_CONVERSATION =
    'CHAT_ADD_MESSAGE_TO_CONVERSATION'
export const CHAT_CREATE_CONVERSATION = 'CHAT_CREATE_CONVERSATION'
export const CHAT_SEARCH_CONTACT = 'CHAT_SEARCH_CONTACT'
export const CHAT_CHANGE_CONVERSATION = 'CHAT_CHANGE_CONVERSATION'

/* SURVEY LIST APP */
export const SURVEY_LIST_GET_LIST = 'SURVEY_LIST_GET_LIST'
export const SURVEY_LIST_GET_LIST_SUCCESS = 'SURVEY_LIST_GET_LIST_SUCCESS'
export const SURVEY_LIST_GET_LIST_ERROR = 'SURVEY_LIST_GET_LIST_ERROR'
export const SURVEY_LIST_GET_LIST_WITH_FILTER =
    'SURVEY_LIST_GET_LIST_WITH_FILTER'
export const SURVEY_LIST_GET_LIST_WITH_ORDER = 'SURVEY_LIST_GET_LIST_WITH_ORDER'
export const SURVEY_LIST_GET_LIST_SEARCH = 'SURVEY_LIST_GET_LIST_SEARCH'
export const SURVEY_LIST_ADD_ITEM = 'SURVEY_LIST_ADD_ITEM'
export const SURVEY_LIST_ADD_ITEM_SUCCESS = 'SURVEY_LIST_ADD_ITEM_SUCCESS'
export const SURVEY_LIST_ADD_ITEM_ERROR = 'SURVEY_LIST_ADD_ITEM_ERROR'
export const SURVEY_LIST_SELECTED_ITEMS_CHANGE =
    'SURVEY_LIST_SELECTED_ITEMS_CHANGE'

/* SURVEY DETAIL APP */
export const SURVEY_GET_DETAILS = 'SURVEY_GET_DETAILS'
export const SURVEY_GET_DETAILS_SUCCESS = 'SURVEY_GET_DETAILS_SUCCESS'
export const SURVEY_GET_DETAILS_ERROR = 'SURVEY_GET_DETAILS_ERROR'
export const SURVEY_DELETE_QUESTION = 'SURVEY_DELETE_QUESTION'
export const SURVEY_SAVE = 'SURVEY_SAVE'

// export const CONTACTS_GET_LIST = 'CONTACTS_GET_LIST';
// export const CONTACTS_GET_LIST_SUCCESS = 'CONTACTS_GET_LIST_SUCCESS';
// export const CONTACTS_GET_LIST_ERROR = 'CONTACTS_GET_LIST_ERROR';
// export const CONTACTS_GET_LIST_WITH_FILTER = 'CONTACTS_GET_LIST_WITH_FILTER';
// export const CONTACTS_GET_LIST_WITH_ORDER = 'CONTACTS_GET_LIST_WITH_ORDER';
// export const CONTACTS_GET_LIST_SEARCH = 'CONTACTS_GET_LIST_SEARCH';
// export const CONTACTS_ADD_ITEM = 'CONTACTS_ADD_ITEM';
// export const CONTACTS_ADD_ITEM_SUCCESS = 'CONTACTS_ADD_ITEM_SUCCESS';
// export const CONTACTS_ADD_ITEM_ERROR = 'CONTACTS_ADD_ITEM_ERROR';
// export const CONTACTS_DELETE_ITEM = 'CONTACTS_DELETE_ITEM';
// export const CONTACTS_DELETE_ITEM_SUCCESS = 'CONTACTS_DELETE_ITEM_SUCCESS';
// export const CONTACTS_DELETE_ITEM_ERROR = 'CONTACTS_DELETE_ITEM_ERROR';
// export const CONTACTS_DELETE_ITEMS = 'CONTACTS_DELETE_ITEMS';
// export const CONTACTS_DELETE_ITEMS_SUCCESS = 'CONTACTS_DELETE_ITEMS_SUCCESS';
// export const CONTACTS_DELETE_ITEMS_ERROR = 'CONTACTS_DELETE_ITEMS_ERROR';
// export const CONTACTS_SELECTED_ITEMS_CHANGE = 'CONTACTS_SELECTED_ITEMS_CHANGE';

export * from './auth/actions'
export * from './chat/actions'
export * from './contacts/actions'
export * from 'experts/redux/actions'
export * from './menu/actions'
// export * from 'projects/redux/issues/actions';
export * from './landingPage/actions'
// export * from 'projects/redux/milestones/actions';
export * from 'notifications/redux/actions'
export * from 'projects/redux/actions'
export * from './settings/actions'
// export * from './surveyList/actions';
// export * from './surveyDetail/actions';
export * from './todo/actions'
export * from './tags/actions'
// export * from 'wiki/redux/actions';
