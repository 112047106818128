// eslint-disable-next-line import/no-cycle
import {
  TAGS_DELETE_ITEMS,
  TAGS_DELETE_ITEMS_ERROR, TAGS_DELETE_ITEMS_SUCCESS, TAGS_GET_LIST,
  TAGS_GET_LIST_ERROR,
  TAGS_GET_LIST_SEARCH, TAGS_GET_LIST_SUCCESS,
  TAGS_GET_LIST_WITH_FILTER,
  TAGS_GET_LIST_WITH_ORDER,
  TAGS_SELECTED_ITEMS_CHANGE
} from './actions_def';
export const getTagsList = () => ({
  type: TAGS_GET_LIST,
});

export const getTagsListSuccess = (items) => ({
  type: TAGS_GET_LIST_SUCCESS,
  payload: items,
});

export const getTagsListError = (error) => ({
  type: TAGS_GET_LIST_ERROR,
  payload: error,
});

export const getTagsListWithFilter = (column, value) => ({
  type: TAGS_GET_LIST_WITH_FILTER,
  payload: { column, value },
});

export const getTagsListWithOrder = (column) => ({
  type: TAGS_GET_LIST_WITH_ORDER,
  payload: column,
});

export const getTagsListSearch = (keyword) => ({
  type: TAGS_GET_LIST_SEARCH,
  payload: keyword,
});

export const selectedTagItemsChange = (selectedItems) => ({
  type: TAGS_SELECTED_ITEMS_CHANGE,
  payload: selectedItems,
});



export const deleteTagsList = (items) => ({
  type: TAGS_DELETE_ITEMS,
  payload: items,
});

export const deleteTagsListSuccess = (items) => ({
  type: TAGS_DELETE_ITEMS_SUCCESS,
  payload: items,
});

export const deleteTagsListError = (error) => ({
  type: TAGS_DELETE_ITEMS_ERROR,
  payload: error,
});



export const selectedTagsItemsChange = (selectedItems) => ({
  type: TAGS_SELECTED_ITEMS_CHANGE,
  payload: selectedItems,
});

