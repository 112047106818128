export const TODOS_FETCH_ITEM = 'TODOS_FETCH_ITEM';
export const TODOS_FETCH_ITEM_SUCCESS = 'TODOS_FETCH_ITEM_SUCCESS';
export const TODOS_FETCH_ITEM_ERROR = 'TODOS_FETCH_ITEM_ERROR';

export const TODOS_ADD_ITEM = 'TODOS_ADD_ITEM';
export const TODOS_ADD_ITEM_SUCCESS = 'TODOS_ADD_ITEM_SUCCESS';
export const TODOS_ADD_ITEM_ERROR = 'TODOS_ADD_ITEM_ERROR';

export const TODOS_DELETE_ITEM = 'TODOS_DELETE_ITEM';
export const TODOS_DELETE_ITEM_SUCCESS = 'TODOS_DELETE_ITEM_SUCCESS';
export const TODOS_DELETE_ITEM_ERROR = 'TODOS_DELETE_ITEM_ERROR';
