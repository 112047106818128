export const DOCUMENTS_GET_LIST = 'DOCUMENTS_GET_LIST';
export const DOCUMENTS_GET_LIST_SUCCESS = 'DOCUMENTS_GET_LIST_SUCCESS';
export const DOCUMENTS_GET_LIST_ERROR = 'DOCUMENTS_GET_LIST_ERROR';
export const DOCUMENTS_GET_LIST_WITH_FILTER = 'DOCUMENTS_GET_LIST_WITH_FILTER';
export const DOCUMENTS_GET_LIST_WITH_ORDER = 'DOCUMENTS_GET_LIST_WITH_ORDER';
export const DOCUMENTS_GET_LIST_SEARCH = 'DOCUMENTS_GET_LIST_SEARCH';

export const MY_POSTS_GET_LIST = 'MY_POSTS_GET_LIST';


export const DOCUMENTS_DELETE_ITEMS = 'DOCUMENTS_DELETE_ITEMS';
export const DOCUMENTS_DELETE_ITEMS_SUCCESS = 'DOCUMENTS_DELETE_ITEMS_SUCCESS';
export const DOCUMENTS_DELETE_ITEMS_ERROR = 'DOCUMENTS_DELETE_ITEMS_ERROR';


export const DOCUMENTS_SELECTED_ITEMS_CHANGE = 'DOCUMENTS_SELECTED_ITEMS_CHANGE';
