
import { combineReducers } from 'redux';
import workDetails from './details/reducer';
import workList from './list/reducer';
import workStatuses from './statuses/reducer';

const reducers = combineReducers({
    workDetails,
    workList,
    workStatuses
});

export default reducers;