export const ISSUES_PRIORITIES_RESET_ITEM = 'PRIORITIES_RESET_ITEM';

export const ISSUES_PRIORITIES_FETCH_ITEM = 'PRIORITIES_FETCH_ITEM';
export const ISSUES_PRIORITIES_FETCH_ITEM_SUCCESS = 'PRIORITIES_FETCH_ITEM_SUCCESS';
export const ISSUES_PRIORITIES_FETCH_ITEM_ERROR = 'PRIORITIES_FETCH_ITEM_ERROR';

export const ISSUES_PRIORITIES_ADD_ITEM = 'PRIORITIES_ADD_ITEM';
export const ISSUES_PRIORITIES_ADD_ITEM_SUCCESS = 'PRIORITIES_ADD_ITEM_SUCCESS';
export const ISSUES_PRIORITIES_ADD_ITEM_ERROR = 'PRIORITIES_ADD_ITEM_ERROR';

export const ISSUES_PRIORITIES_DELETE_ITEM = 'PRIORITIES_DELETE_ITEM';
export const ISSUES_PRIORITIES_DELETE_ITEM_SUCCESS = 'PRIORITIES_DELETE_ITEM_SUCCESS';
export const ISSUES_PRIORITIES_DELETE_ITEM_ERROR = 'PRIORITIES_DELETE_ITEM_ERROR';

export const ISSUES_PRIORITIES_SAVE_ITEM = 'PRIORITIES_DELETE_ITEM_ERROR';
export const ISSUES_PRIORITIES_SAVE_ITEM_SUCCESS = 'PRIORITIES_DELETE_ITEM_ERROR';
export const ISSUES_PRIORITIES_SAVE_ITEM_ERROR = 'PRIORITIES_DELETE_ITEM_ERROR';