export const CONTACTS_FETCH_ITEM = 'CONTACTS_FETCH_ITEM';
export const CONTACTS_FETCH_ITEM_SUCCESS = 'CONTACTS_FETCH_ITEM_SUCCESS';
export const CONTACTS_FETCH_ITEM_ERROR = 'CONTACTS_FETCH_ITEM_ERROR';

export const CONTACTS_ADD_ITEM = 'CONTACTS_ADD_ITEM';
export const CONTACTS_ADD_ITEM_SUCCESS = 'CONTACTS_ADD_ITEM_SUCCESS';
export const CONTACTS_ADD_ITEM_ERROR = 'CONTACTS_ADD_ITEM_ERROR';

export const CONTACTS_DELETE_ITEM = 'CONTACTS_DELETE_ITEM';
export const CONTACTS_DELETE_ITEM_SUCCESS = 'CONTACTS_DELETE_ITEM_SUCCESS';
export const CONTACTS_DELETE_ITEM_ERROR = 'CONTACTS_DELETE_ITEM_ERROR';
