export const CATEGORIES_RESET_ITEM = 'CATEGORIES_RESET_ITEM';

export const CATEGORIES_FETCH_ITEM = 'CATEGORIES_FETCH_ITEM';
export const CATEGORIES_FETCH_ITEM_SUCCESS = 'CATEGORIES_FETCH_ITEM_SUCCESS';
export const CATEGORIES_FETCH_ITEM_ERROR = 'CATEGORIES_FETCH_ITEM_ERROR';

export const CATEGORIES_ADD_ITEM = 'CATEGORIES_ADD_ITEM';
export const CATEGORIES_ADD_ITEM_SUCCESS = 'CATEGORIES_ADD_ITEM_SUCCESS';
export const CATEGORIES_ADD_ITEM_ERROR = 'CATEGORIES_ADD_ITEM_ERROR';

export const CATEGORIES_DELETE_ITEM = 'CATEGORIES_DELETE_ITEM';
export const CATEGORIES_DELETE_ITEM_SUCCESS = 'CATEGORIES_DELETE_ITEM_SUCCESS';
export const CATEGORIES_DELETE_ITEM_ERROR = 'CATEGORIES_DELETE_ITEM_ERROR';

export const CATEGORIES_SAVE_ITEM = 'CATEGORIES_DELETE_ITEM_ERROR';
export const CATEGORIES_SAVE_ITEM_SUCCESS = 'CATEGORIES_DELETE_ITEM_ERROR';
export const CATEGORIES_SAVE_ITEM_ERROR = 'CATEGORIES_DELETE_ITEM_ERROR';