import {
    DOCUMENTS_ADD_ITEM,
    DOCUMENTS_ADD_ITEM_SUCCESS,
    DOCUMENTS_ADD_ITEM_ERROR,
    DOCUMENTS_FETCH_ITEM,
    DOCUMENTS_FETCH_ITEM_SUCCESS,
    DOCUMENTS_FETCH_ITEM_ERROR,
    DOCUMENTS_DELETE_ITEM,
    DOCUMENTS_DELETE_ITEM_SUCCESS,
    DOCUMENTS_DELETE_ITEM_ERROR,
    DOCUMENTS_SAVE_ITEM,
    DOCUMENTS_SAVE_ITEM_SUCCESS,
    DOCUMENTS_SAVE_ITEM_ERROR,
    DOCUMENT_SET_CURRENT_CHAPTER,
} from './actions_def'

const INIT_STATE = {
    document: {
        id: 1,
        name: 'The document',
        description:
            'This is a demonstration of the text used to descripbe a document',
        accessTypeId: '1',
        accessTypeName: 'Private',
        chapters: [
            {
                id: 1,
                valid: false,
                displayName: 'Introduction',
                content: 'Content',
                paragraphs: [
                    {
                        id: 1,
                        valid: false,
                        key: '1',
                        // title: 'Scope1',
                        content: 'Text2',
                        comments: [
                            {
                                name: 'The name',
                                detail: 'The detail',
                                likes: 4,
                                data: 'This is the data',
                            },
                        ],
                        votes: 3,
                    },
                    {
                        id: 2,
                        valid: false,
                        key: '2',
                        // title: 'Roadmap1',
                        content: 'A 2 of this document',
                        comments: [
                            {
                                name: 'The name',
                                detail: 'The detail',
                                likes: 4,
                                data: 'This is the data',
                            },
                        ],
                        votes: 1,
                    },
                    {
                        id: 3,
                        valid: false,
                        key: '3',
                        // title: 'Summary 4',
                        content: 'Closure for 5 this document',
                        comments: [
                            {
                                name: 'The name',
                                detail: 'The detail',
                                likes: 4,
                                data: 'This is the data',
                            },
                        ],
                        votes: 6,
                    },
                ],
            },
            {
                id: 2,
                valid: false,
                displayName: 'Chapter I',
                content: 'Content',
                paragraphs: [
                    {
                        id: 4,
                        valid: false,
                        key: '1',
                        title: 'Scope5',
                        content: 'This is fdd of this document is',
                        comments: [
                            {
                                name: 'The name',
                                detail: 'The detail',
                                likes: 4,
                                data: 'This is the data',
                            },
                        ],
                        votes: 3,
                    },
                    {
                        id: 5,
                        valid: false,
                        key: '2',
                        title: 'Roadmap',
                        content: 'A frgt document',
                        comments: [
                            {
                                name: 'The name',
                                detail: 'The detail',
                                likes: 4,
                                data: 'This is the data',
                            },
                        ],
                        votes: 1,
                    },
                    {
                        id: 6,
                        valid: false,
                        key: '3',
                        title: 'Summary',
                        content: 'Closure for s rise through this document',
                        comments: [
                            {
                                name: 'The name',
                                detail: 'The detail',
                                likes: 4,
                                data: 'This is the data',
                            },
                        ],
                        votes: 6,
                    },
                ],
            },
            {
                id: 3,
                valid: false,
                displayName: 'Chapter II',
                content: 'Content',
                paragraphs: [],
            },
        ],
    },
    errors: [],
    isLoading: false,
    isLoaded: true,
    isSaving: false,
    isSaved: false,
    hasErrors: false,
    currentChapterIdx: 0,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case DOCUMENTS_FETCH_ITEM:
            return { ...state, isLoading: false }

        case DOCUMENTS_FETCH_ITEM_SUCCESS:
            return {
                ...state,
                isLoading: true,
                document: action.payload,
            }

        case DOCUMENTS_FETCH_ITEM_ERROR:
            return {
                ...state,
                isLoading: true,
                error: action.payload,
            }

        case DOCUMENTS_ADD_ITEM:
            return {
                ...state,
                isSaving: true,
            }

        case DOCUMENTS_ADD_ITEM_SUCCESS:
            return {
                ...state,
                isLoading: true,
                isSaving: false,
                errors: [],
                document: action.payload,
            }

        case DOCUMENTS_ADD_ITEM_ERROR:
            console.log('Failed', action.payload)
            return {
                ...state,
                isLoading: true,
                isSaving: false,
                hasErrors: true,
                errors:
                    action.payload.errors &&
                    action.payload.errors.map((error) => ({
                        field: error.field,
                        error_code: error.code,
                    })),
            }

        case DOCUMENTS_DELETE_ITEM:
            return { ...state, saving: true }

        case DOCUMENTS_DELETE_ITEM_SUCCESS:
            console.log(
                'Success',
                state.allDocumentsItems.filter(
                    (item) => item && item.id !== action.payload.rfc_id,
                ),
            )

            return {
                ...state,
                isLoading: true,
                isSaving: false,
                isSaved: true,
                errors: [],
                hasErrors: false,
            }

        case DOCUMENTS_DELETE_ITEM_ERROR:
            console.log('Failed', action.payload)
            return {
                ...state,
                isLoading: false,
                isSaving: false,
                isSaved: true,
                errors: action.payload.errors.map((error) => ({
                    field: error.field,
                    error_code: error.code,
                })),
            }

        case DOCUMENTS_SAVE_ITEM_SUCCESS:
            return {
                ...state,
                isLoading: true,
                isSaving: false,
                isSaved: true,
                errors: [],
                document: action.payload,
            }

        case DOCUMENTS_SAVE_ITEM_ERROR:
            console.log('Failed', action.payload)
            return {
                ...state,
                isLoading: true,
                isSaving: false,
                hasErrors: true,
                errors:
                    action.payload.errors &&
                    action.payload.errors.map((error) => ({
                        field: error.field,
                        error_code: error.code,
                    })),
            }

        case DOCUMENTS_SAVE_ITEM:
            console.log('DOCUMENTS_SAVE_ITEM', action.payload)
            return {
                ...state,
                isSaving: true,
                document: action.payload,
            }

        case DOCUMENT_SET_CURRENT_CHAPTER:
            return { ...state, currentChapterIdx: action.payload }
        default:
            return { ...state }
    }
}
