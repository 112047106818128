// eslint-disable-next-line import/no-cycle
import {
  MILESTONES_GET_STATUSES_LIST,
  MILESTONES_GET_STATUSES_LIST_SUCCESS,
  MILESTONES_GET_STATUSES_LIST_ERROR,
} from './actions_def';

export const getMilestonesStatusesList = () => ({
  type: MILESTONES_GET_STATUSES_LIST,
});

export const getMilestonesStatusesListSuccess = (items) => ({
  type: MILESTONES_GET_STATUSES_LIST_SUCCESS,
  payload: items,
});

export const getMilestonesStatusesListError = (error) => ({
  type: MILESTONES_GET_STATUSES_LIST_ERROR,
  payload: error,
});
