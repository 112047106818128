export const ISSUES_TYPES_RESET_ITEM = 'TYPES_RESET_ITEM';

export const ISSUES_TYPES_FETCH_ITEM = 'TYPES_FETCH_ITEM';
export const ISSUES_TYPES_FETCH_ITEM_SUCCESS = 'TYPES_FETCH_ITEM_SUCCESS';
export const ISSUES_TYPES_FETCH_ITEM_ERROR = 'TYPES_FETCH_ITEM_ERROR';

export const ISSUES_TYPES_ADD_ITEM = 'TYPES_ADD_ITEM';
export const ISSUES_TYPES_ADD_ITEM_SUCCESS = 'TYPES_ADD_ITEM_SUCCESS';
export const ISSUES_TYPES_ADD_ITEM_ERROR = 'TYPES_ADD_ITEM_ERROR';

export const ISSUES_TYPES_DELETE_ITEM = 'TYPES_DELETE_ITEM';
export const ISSUES_TYPES_DELETE_ITEM_SUCCESS = 'TYPES_DELETE_ITEM_SUCCESS';
export const ISSUES_TYPES_DELETE_ITEM_ERROR = 'TYPES_DELETE_ITEM_ERROR';

export const ISSUES_TYPES_SAVE_ITEM = 'TYPES_DELETE_ITEM_ERROR';
export const ISSUES_TYPES_SAVE_ITEM_SUCCESS = 'TYPES_DELETE_ITEM_ERROR';
export const ISSUES_TYPES_SAVE_ITEM_ERROR = 'TYPES_DELETE_ITEM_ERROR';