/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE'

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER'
export const LOGIN_USER_PROVIDER = 'LOGIN_USER_PROVIDER'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const REGISTER_USER = 'REGISTER_USER'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const LOGOUT_USER = 'LOGOUT_USER'

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES'
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME'
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU'
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES'
export const MENU_SET_ITEMS = 'MENU_SET_ITEMS'
export const MENU_SET_SELECTED = 'MENU_SET_SELECTED'

/* TODO APP*/
export const TODO_GET_LIST = 'TODO_GET_LIST'
export const TODO_GET_LIST_SUCCESS = 'TODO_GET_LIST_SUCCESS'
export const TODO_GET_LIST_ERROR = 'TODO_GET_LIST_ERROR'
export const TODO_GET_LIST_WITH_FILTER = 'TODO_GET_LIST_WITH_FILTER'
export const TODO_GET_LIST_WITH_ORDER = 'TODO_GET_LIST_WITH_ORDER'
export const TODO_GET_LIST_SEARCH = 'TODO_GET_LIST_SEARCH'
export const TODO_ADD_ITEM = 'TODO_ADD_ITEM'
export const TODO_ADD_ITEM_SUCCESS = 'TODO_ADD_ITEM_SUCCESS'
export const TODO_ADD_ITEM_ERROR = 'TODO_ADD_ITEM_ERROR'
export const TODO_SELECTED_ITEMS_CHANGE = 'TODO_SELECTED_ITEMS_CHANGE'

/* Projects APP*/
export const PROJECTS_GET_STATUSES_LIST = 'PROJECTS_GET_STATUSES_LIST'
export const PROJECTS_GET_STATUSES_LIST_SUCCESS =
    'PROJECTS_STATUSES_GET_LIST_SUCCESS'
export const PROJECTS_GET_STATUSES_LIST_ERROR =
    'PROJECTS_GET_STATUSES_LIST_ERROR'
export const PROJECTS_GET_LIST = 'PROJECTS_GET_LIST'
export const PROJECTS_GET_LIST_SUCCESS = 'PROJECTS_GET_LIST_SUCCESS'
export const PROJECTS_GET_LIST_ERROR = 'PROJECTS_GET_LIST_ERROR'
export const PROJECTS_GET_LIST_WITH_FILTER = 'PROJECTS_GET_LIST_WITH_FILTER'
export const PROJECTS_GET_LIST_WITH_ORDER = 'PROJECTS_GET_LIST_WITH_ORDER'
export const PROJECTS_GET_LIST_SEARCH = 'PROJECTS_GET_LIST_SEARCH'
export const PROJECTS_ADD_ITEM = 'PROJECTS_ADD_ITEM'
export const PROJECTS_ADD_ITEM_SUCCESS = 'TPROJECTS_ADD_ITEM_SUCCESS'
export const PROJECTS_ADD_ITEM_ERROR = 'PROJECTS_ADD_ITEM_ERROR'
export const PROJECTS_SELECTED_ITEMS_CHANGE = 'PROJECTS_SELECTED_ITEMS_CHANGE'

export const PROJECT_GET_DETAILS = 'PROJECT_GET_DETAILS'
export const PROJECT_GET_DETAILS_SUCCESS = 'PROJECT_GET_DETAILS_SUCCESS'
export const PROJECT_GET_DETAILS_ERROR = 'PROJECT_GET_DETAILS_ERROR'
export const PROJECT_DELETE_QUESTION = 'PROJECT_DELETE_QUESTION'
export const PROJECT_SAVE = 'PROJECT_SAVE'

/* Experts APP*/
export const EXPERTS_GET_LIST = 'EXPERTS_GET_LIST'
export const EXPERTS_GET_LIST_SUCCESS = 'EXPERTS_GET_LIST_SUCCESS'
export const EXPERTS_GET_LIST_ERROR = 'EXPERTS_GET_LIST_ERROR'
export const EXPERTS_GET_LIST_WITH_FILTER = 'EXPERTS_GET_LIST_WITH_FILTER'
export const EXPERTS_GET_LIST_WITH_ORDER = 'EXPERTS_GET_LIST_WITH_ORDER'
export const EXPERTS_GET_LIST_SEARCH = 'EXPERTS_GET_LIST_SEARCH'

export const EXPERTS_ADD_ITEM = 'EXPERTS_ADD_ITEM'
export const EXPERTS_ADD_ITEM_SUCCESS = 'EXPERTS_ADD_ITEM_SUCCESS'
export const EXPERTS_ADD_ITEM_ERROR = 'EXPERTS_ADD_ITEM_ERROR'

export const EXPERTS_DELETE_ITEM = 'EXPERTS_DELETE_ITEM'
export const EXPERTS_DELETE_ITEM_SUCCESS = 'EXPERTS_DELETE_ITEM_SUCCESS'
export const EXPERTS_DELETE_ITEM_ERROR = 'EXPERTS_DELETE_ITEM_ERROR'

export const EXPERT_GET_DETAILS = 'EXPERT_GET_DETAILS'
export const EXPERT_GET_DETAILS_SUCCESS = 'EXPERT_GET_DETAILS_SUCCESS'
export const EXPERT_GET_DETAILS_ERROR = 'EXPERT_GET_DETAILS_ERROR'

export const EXPERT_SAVE = 'EXPERT_SAVE'

export const EXPERTS_SELECTED_ITEMS_CHANGE = 'EXPERTS_SELECTED_ITEMS_CHANGE'

export const EXPERTS_GET_STATUSES_LIST = 'EXPERTS_GET_STATUSES_LIST'
export const EXPERTS_GET_STATUSES_LIST_SUCCESS =
    'EXPERTS_STATUSES_GET_LIST_SUCCESS'
export const EXPERTS_GET_STATUSES_LIST_ERROR = 'EXPERTS_GET_STATUSES_LIST_ERROR'

/* Experts APP*/
export const ISSUES_GET_LIST = 'ISSUES_GET_LIST'
export const ISSUES_GET_LIST_SUCCESS = 'ISSUES_GET_LIST_SUCCESS'
export const ISSUES_GET_LIST_ERROR = 'ISSUES_GET_LIST_ERROR'
export const ISSUES_GET_LIST_WITH_FILTER = 'ISSUES_GET_LIST_WITH_FILTER'
export const ISSUES_GET_LIST_WITH_ORDER = 'ISSUES_GET_LIST_WITH_ORDER'
export const ISSUES_GET_LIST_SEARCH = 'ISSUES_GET_LIST_SEARCH'

export const ISSUES_ADD_ITEM = 'ISSUES_ADD_ITEM'
export const ISSUES_ADD_ITEM_SUCCESS = 'ISSUES_ADD_ITEM_SUCCESS'
export const ISSUES_ADD_ITEM_ERROR = 'ISSUES_ADD_ITEM_ERROR'

export const ISSUES_DELETE_ITEM = 'ISSUES_DELETE_ITEM'
export const ISSUES_DELETE_ITEM_SUCCESS = 'ISSUES_DELETE_ITEM_SUCCESS'
export const ISSUES_DELETE_ITEM_ERROR = 'ISSUES_DELETE_ITEM_ERROR'

export const ISSUE_GET_DETAILS = 'ISSUE_GET_DETAILS'
export const ISSUE_GET_DETAILS_SUCCESS = 'ISSUE_GET_DETAILS_SUCCESS'
export const ISSUE_GET_DETAILS_ERROR = 'ISSUE_GET_DETAILS_ERROR'

export const ISSUE_SAVE = 'ISSUE_SAVE'

export const ISSUES_SELECTED_ITEMS_CHANGE = 'ISSUES_SELECTED_ITEMS_CHANGE'

export const ISSUES_GET_STATUSES_LIST = 'ISSUES_GET_STATUSES_LIST'
export const ISSUES_GET_STATUSES_LIST_SUCCESS =
    'ISSUES_STATUSES_GET_LIST_SUCCESS'
export const ISSUES_GET_STATUSES_LIST_ERROR = 'ISSUES_GET_STATUSES_LIST_ERROR'

/* CHAT APP*/
export const CHAT_GET_CONTACTS = 'CHAT_GET_CONTACTS'
export const CHAT_GET_CONTACTS_SUCCESS = 'CHAT_GET_CONTACTS_SUCCESS'
export const CHAT_GET_CONTACTS_ERROR = 'CHAT_GET_CONTACTS_ERROR'
export const CHAT_GET_CONVERSATIONS = 'CHAT_GET_CONVERSATIONS'
export const CHAT_GET_CONVERSATIONS_SUCCESS = 'CHAT_GET_CONVERSATIONS_SUCCESS'
export const CHAT_GET_CONVERSATIONS_ERROR = 'CHAT_GET_CONVERSATIONS_ERROR'
export const CHAT_ADD_MESSAGE_TO_CONVERSATION =
    'CHAT_ADD_MESSAGE_TO_CONVERSATION'
export const CHAT_CREATE_CONVERSATION = 'CHAT_CREATE_CONVERSATION'
export const CHAT_SEARCH_CONTACT = 'CHAT_SEARCH_CONTACT'
export const CHAT_CHANGE_CONVERSATION = 'CHAT_CHANGE_CONVERSATION'

/* SURVEY LIST APP*/
export const SURVEY_LIST_GET_LIST = 'SURVEY_LIST_GET_LIST'
export const SURVEY_LIST_GET_LIST_SUCCESS = 'SURVEY_LIST_GET_LIST_SUCCESS'
export const SURVEY_LIST_GET_LIST_ERROR = 'SURVEY_LIST_GET_LIST_ERROR'
export const SURVEY_LIST_GET_LIST_WITH_FILTER =
    'SURVEY_LIST_GET_LIST_WITH_FILTER'
export const SURVEY_LIST_GET_LIST_WITH_ORDER = 'SURVEY_LIST_GET_LIST_WITH_ORDER'
export const SURVEY_LIST_GET_LIST_SEARCH = 'SURVEY_LIST_GET_LIST_SEARCH'
export const SURVEY_LIST_ADD_ITEM = 'SURVEY_LIST_ADD_ITEM'
export const SURVEY_LIST_ADD_ITEM_SUCCESS = 'SURVEY_LIST_ADD_ITEM_SUCCESS'
export const SURVEY_LIST_ADD_ITEM_ERROR = 'SURVEY_LIST_ADD_ITEM_ERROR'
export const SURVEY_LIST_SELECTED_ITEMS_CHANGE =
    'SURVEY_LIST_SELECTED_ITEMS_CHANGE'

/* SURVEY DETAIL APP*/
export const SURVEY_GET_DETAILS = 'SURVEY_GET_DETAILS'
export const SURVEY_GET_DETAILS_SUCCESS = 'SURVEY_GET_DETAILS_SUCCESS'
export const SURVEY_GET_DETAILS_ERROR = 'SURVEY_GET_DETAILS_ERROR'
export const SURVEY_DELETE_QUESTION = 'SURVEY_DELETE_QUESTION'
export const SURVEY_SAVE = 'SURVEY_SAVE'

/* LANDING PAGE */
export const LANDING_PAGE_MOBILE_MENU_TOGGLE = 'LANDING_PAGE_MOBILE_MENU_TOGGLE'
export const LANDING_PAGE_MOBILE_MENU_CLOSE = 'LANDING_PAGE_MOBILE_MENU_CLOSE'
