export const CONTACTS_GET_LIST = 'CONTACTS_GET_LIST';
export const CONTACTS_GET_LIST_SUCCESS = 'CONTACTS_GET_LIST_SUCCESS';
export const CONTACTS_GET_LIST_ERROR = 'CONTACTS_GET_LIST_ERROR';
export const CONTACTS_GET_LIST_WITH_FILTER = 'CONTACTS_GET_LIST_WITH_FILTER';
export const CONTACTS_GET_LIST_WITH_ORDER = 'CONTACTS_GET_LIST_WITH_ORDER';
export const CONTACTS_GET_LIST_SEARCH = 'CONTACTS_GET_LIST_SEARCH';


export const CONTACTS_DELETE_ITEMS = 'CONTACTS_DELETE_ITEMS';
export const CONTACTS_DELETE_ITEMS_SUCCESS = 'CONTACTS_DELETE_ITEMS_SUCCESS';
export const CONTACTS_DELETE_ITEMS_ERROR = 'CONTACTS_DELETE_ITEMS_ERROR';


export const CONTACTS_SELECTED_ITEMS_CHANGE = 'CONTACTS_SELECTED_ITEMS_CHANGE';
