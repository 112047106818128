import { all } from 'redux-saga/effects';
import list from './list/saga';
import details from './details/saga';
import statuses from './statuses/saga';

export default function* rootSaga(getState) {
  yield all([
    list(),
    details(),
    statuses(),
  ]);
}
