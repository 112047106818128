import {
    DOCUMENTS_DELETE_ITEMS,
    DOCUMENTS_DELETE_ITEMS_ERROR, DOCUMENTS_DELETE_ITEMS_SUCCESS, DOCUMENTS_GET_LIST,
    DOCUMENTS_GET_LIST_ERROR,
    DOCUMENTS_GET_LIST_SEARCH, DOCUMENTS_GET_LIST_SUCCESS,
    DOCUMENTS_GET_LIST_WITH_FILTER,
    DOCUMENTS_GET_LIST_WITH_ORDER,
    DOCUMENTS_SELECTED_ITEMS_CHANGE,
    DOCUMENTS_SAVE_ITEM_SUCCESS,
    DOCUMENTS_ADD_ITEM_SUCCESS
} from 'projects/redux/documents/actions_def';

const INIT_STATE = {
    allDocuments: [],
    documents: [
        {
            id: 1,
            name: "The document",
            accessTypeId: "1",
            accessTypeName: "Private",
            chapters: [
                {
                    id: 1,
                    valid: false,
                    displayName: 'Introduction',
                    content: 'Content',
                    paragraphs: [
                        {
                            id: 1,
                            valid: false,
                            key: '1',
                            title: 'Scope1',
                            content: 'Text2',
                            comments: [{ name: 'The name', detail: "The detail", likes: 4, data: "This is the data" }],
                            votes: 3
                        },
                        {
                            id: 2,
                            valid: false,
                            key: '2',
                            title: 'Roadmap1',
                            content: 'A 2 of this document',
                            comments: [{ name: 'The name', detail: "The detail", likes: 4, data: "This is the data" }],
                            votes: 1
                        },
                        {
                            id: 3,
                            valid: false,
                            key: '3',
                            title: 'Summary 4',
                            content: 'Closure for 5 this document',
                            comments: [{ name: 'The name', detail: "The detail", likes: 4, data: "This is the data" }],
                            votes: 6
                        },
                    ]
                },
                {
                    id: 2,
                    valid: false,
                    displayName: 'Chapter I',
                    content: 'Content',
                    paragraphs: [
                        {
                            id: 4,
                            valid: false,
                            key: '1',
                            title: 'Scope5',
                            content: 'This is fdd of this document is',
                            comments: [{ name: 'The name', detail: "The detail", likes: 4, data: "This is the data" }],
                            votes: 3
                        },
                        {
                            id: 5,
                            valid: false,
                            key: '2',
                            title: 'Roadmap',
                            content: 'A frgt document',
                            comments: [{ name: 'The name', detail: "The detail", likes: 4, data: "This is the data" }],
                            votes: 1
                        },
                        {
                            id: 6,
                            valid: false,
                            key: '3',
                            title: 'Summary',
                            content: 'Closure for s rise through this document',
                            comments: [{ name: 'The name', detail: "The detail", likes: 4, data: "This is the data" }],
                            votes: 6
                        },
                    ]
                },
                {
                    id: 3,
                    valid: false,
                    displayName: 'Chapter II',
                    content: 'Content',
                    paragraphs: []
                },
            ]
        }
    ],
    errors: [],
    filter: null,
    searchKeyword: '',
    orderColumn: null,
    isLoaded: false,
    isLoading: false,
    totalItems: 0,
    isSaving: false,

    // labels: [
    //     { label: 'EDUCATION', color: 'secondary' },
    //     { label: 'NEW FRAMEWORK', color: 'primary' },
    //     { label: 'PERSONAL', color: 'info' },
    // ],
    orderColumns: [
        { column: 'title', label: 'Title' },
        { column: 'category', label: 'Category' },
        { column: 'status', label: 'Status' },
        { column: 'label', label: 'Label' },
    ],
    selectedItems: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case DOCUMENTS_GET_LIST:
            return {
                ...state,
                isLoaded: false,
                isLoading: true,
            };

        case DOCUMENTS_GET_LIST_SUCCESS:
            // console.log(action.payload)
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                allDocumentsItems: action.payload,
                documents: action.payload,
                totalItems: action.payload.length
            };

        case DOCUMENTS_GET_LIST_ERROR:
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                error: action.payload
            };

        case DOCUMENTS_GET_LIST_WITH_FILTER:
            if (action.payload.column === '' || action.payload.value === '') {
                return {
                    ...state,
                    isLoaded: false,
                    isLoading: true,
                    documentsItems: state.allDocumentsItems,
                    filter: null,
                };
            }
            // eslint-disable-next-line no-case-declarations
            const filteredItems = state.allDocumentsItems.filter(
                (item) => item[action.payload.column] === action.payload.value
            );
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                documentsItems: filteredItems,
                filter: {
                    column: action.payload.column,
                    value: action.payload.value,
                },
            };

        case DOCUMENTS_GET_LIST_WITH_ORDER:
            if (action.payload === '') {
                return {
                    ...state,
                    isLoaded: false,
                    isLoading: true,
                    documentsItems: state.documentsItems,
                    orderColumn: null,
                };
            }
            // eslint-disable-next-line no-case-declarations
            const sortedItems = state.documentsItems.sort((a, b) => {
                if (a[action.payload] < b[action.payload]) return -1;
                if (a[action.payload] > b[action.payload]) return 1;
                return 0;
            });
            return {
                ...state,
                isLoaded: false,
                isLoading: true,
                documentsItems: sortedItems,
                orderColumn: state.orderColumns.find(
                    (x) => x.column === action.payload
                ),
            };

        case DOCUMENTS_GET_LIST_SEARCH:
            if (action.payload === '') {
                return { ...state, documentsItems: state.allDocumentsItems };
            }
            // eslint-disable-next-line no-case-declarations
            const keyword = action.payload.searchTerm.toLowerCase();
            // eslint-disable-next-line no-case-declarations
            console.log(keyword, state.allDocumentsItems)
            // const searchItems = state.allDocumentsItems.filter(
            //     (item) =>
            //         item.title.toLowerCase().indexOf(keyword) > -1 ||
            //         item.detail.toLowerCase().indexOf(keyword) > -1 ||
            //         item.status.toLowerCase().indexOf(keyword) > -1 ||
            //         item.category.toLowerCase().indexOf(keyword) > -1 ||
            //         item.label.toLowerCase().indexOf(keyword) > -1
            // );
            return {
                ...state,
                isLoaded: false,
                isLoading: true,
                // documentsItems: searchItems,
                searchKeyword: keyword,
            };

        case DOCUMENTS_SELECTED_ITEMS_CHANGE:
            return {
                ...state, isLoaded: false,
                isLoading: false, selectedItems: action.payload
            };

        case DOCUMENTS_DELETE_ITEMS:
            return {
                ...state,
                isLoaded: false,
                isLoading: true,
                saving: true
            };

        case DOCUMENTS_DELETE_ITEMS_SUCCESS:

            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                saving: false,
                saved: true,
                errors: [],
                allDocumentsItems: state.allDocumentsItems.filter(item => item.id !== action.payload.rfc_id),
                documentsItems: state.documentsItems.filter(item => item.id !== action.payload.rfc_id),
            };

        case DOCUMENTS_DELETE_ITEMS_ERROR:
            // console.log("Failed", action.payload)
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                saving: false,
                saved: true,
                errors: action.payload.errors.map(error => (
                    {
                        field: error.field,
                        error_code: error.code
                    }
                ))
            };

        case DOCUMENTS_SAVE_ITEM_SUCCESS:

            return {
                ...state,
                documents: [...state.documents, action.payload]
            };


        case DOCUMENTS_ADD_ITEM_SUCCESS:
            console.log("Document added. Adding to list.")
            return {
                ...state,
                documents: [...state.documents, action.payload]
            };

        default:
            return { ...state };
    }
};
