// eslint-disable-next-line import/no-cycle
import {
    WORK_GET_STATUSES_LIST,
    WORK_GET_STATUSES_LIST_SUCCESS,
    WORK_GET_STATUSES_LIST_ERROR,
} from './actions_def';

export const getWorkStatusesList = () => ({
    type: WORK_GET_STATUSES_LIST,
});

export const getWorkStatusesListSuccess = (items) => ({
    type: WORK_GET_STATUSES_LIST_SUCCESS,
    payload: items,
});

export const getWorkStatusesListError = (error) => ({
    type: WORK_GET_STATUSES_LIST_ERROR,
    payload: error,
});
