// eslint-disable-next-line import/no-cycle
import {
    NOTIFICATION_DELETE_ITEMS,
    NOTIFICATION_DELETE_ITEMS_ERROR, NOTIFICATION_DELETE_ITEMS_SUCCESS, NOTIFICATION_GET_LIST,
    NOTIFICATION_GET_LIST_ERROR,
    NOTIFICATION_GET_LIST_SEARCH, NOTIFICATION_GET_LIST_SUCCESS,
    NOTIFICATION_GET_LIST_WITH_FILTER,
    NOTIFICATION_GET_LIST_WITH_ORDER,
    NOTIFICATION_SELECTED_ITEMS_CHANGE
} from './actions_def';
export const getNotificationsList = () => ({
    type: NOTIFICATION_GET_LIST,
});

export const getNotificationsListSuccess = (items) => ({
    type: NOTIFICATION_GET_LIST_SUCCESS,
    payload: items,
});

export const getNotificationsListError = (error) => ({
    type: NOTIFICATION_GET_LIST_ERROR,
    payload: error,
});

export const getNotificationsListWithFilter = (column, value) => ({
    type: NOTIFICATION_GET_LIST_WITH_FILTER,
    payload: { column, value },
});

export const getNotificationsListWithOrder = (column) => ({
    type: NOTIFICATION_GET_LIST_WITH_ORDER,
    payload: column,
});

export const getNotificationsListSearch = (keyword) => ({
    type: NOTIFICATION_GET_LIST_SEARCH,
    payload: keyword,
});

export const selectedNotificationItemsChange = (selectedItems) => ({
    type: NOTIFICATION_SELECTED_ITEMS_CHANGE,
    payload: selectedItems,
});



export const deleteNotificationsList = (items) => ({
    type: NOTIFICATION_DELETE_ITEMS,
    payload: items,
});

export const deleteNotificationsListSuccess = (items) => ({
    type: NOTIFICATION_DELETE_ITEMS_SUCCESS,
    payload: items,
});

export const deleteNotificationsListError = (error) => ({
    type: NOTIFICATION_DELETE_ITEMS_ERROR,
    payload: error,
});
