import axios from "axios";
import { servicePath } from 'constants/defaultValues';
import { getAccessToken } from 'helpers/Firebase';
import { getCurrentUser } from 'helpers/Utils';
import moment from 'moment';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
    ISSUES_PRIORITIES_FETCH_ITEM,
    ISSUES_PRIORITIES_ADD_ITEM,
    ISSUES_PRIORITIES_DELETE_ITEM
} from './actions_def';
import {
    getIssuesPriorityItemSuccess,
    getIssuesPriorityItemError,
    addIssuesPriorityItemSuccess,
    addIssuesPriorityItemError,
    deleteIssuesPriorityItemSuccess,
    deleteIssuesPriorityItemError


} from './actions';


const apiUrl = servicePath;


const getIssuesPriorityItemRequest = async (payload) => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success) => {
        const instance = axios.create({
            method: 'get',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });
        instance.get('/priorities/' + payload.priorityId, { crossdomain: true })
            .then(response => success(response.data ?
                response.data.map(item => (
                    {
                        id: item.rfc_id,
                        title: item.rfc_title,
                        date: moment(item.date_created).format("Do MMM YYYY"),
                        change_date: moment(item.date_modified).format("Do MMM YYYY (hh:mm)"),
                        end_date: moment(item.rfc_implementation_end_date).format("Do MMM YYYY (hh:mm)"),
                        priority: item.rfc_priority,
                        priorityColor: 'primary'
                    }
                )
                )
                :
                []));
    })
        .then((response) => response)
        .catch((error) => error);
};

function* getIssuesPriorityItem() {
    try {
        const response = yield call(getIssuesPriorityItemRequest);
        yield put(getIssuesPriorityItemSuccess(response));
    } catch (error) {
        yield put(getIssuesPriorityItemError(error));
    }
}


const addIssuesPriorityItemRequest = async (item) => {
    // eslint-disable-next-line no-return-await
    console.log(item);
    return await new Promise((success, fail) => {

        // success(notificationListData.data);
        var _user = getCurrentUser(); // JSON.parse(localStorage.getItem("user"));
        const instance = axios.create({
            method: 'post',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });

        // console.log('Sending :', {
        //   rfc_title: item.title,
        //   rfc_type: item.type ? item.type : 1,
        //   rfc_summary: item.detail ? item.detail : ' ',
        // })
        let _priority = 1;
        switch (item.priority) {
            case "PENDING": _priority = 1; break;
            default: _priority = 1;
        };

        let _sendingPayload = {
            Name: item.name,
            ProjectId: item.projectId,
            Notes: item.description ? item.description : ' ',
            DueDate: item.due_date ? item.due_date : new Date(),
            ReleaseDate: item.due_date ? item.due_date : new Date(),
        };

        // console.log(_sendingPayload, getAccessToken());
        instance.post('/priorities/',
            _sendingPayload,
            { crossdomain: true })
            .then(response => {
                if (response.data) {
                    if (response.data.priority !== 200 && response.data.priority !== 201) {
                        fail(response.data);
                    }
                    else {
                        success(response.data);
                    }
                }
            }).catch((error) => {
                console.log(error)
            });;
    })
        .then((response) => response)
        .catch((error) => error);
};

function* addIssuesPriorityItem({ payload }) {
    // // console.log(payload);;
    try {
        const response = yield call(addIssuesPriorityItemRequest, payload);
        console.log('Response :', response, response.priority === "fail")
        if (response.priority !== "fail") {
            yield put(addIssuesPriorityItemSuccess(response));
        }
        else {
            yield put(addIssuesPriorityItemError(response));
        }
    } catch (error) {
        yield put(addIssuesPriorityItemError(error));
    }
}


const deleteIssuesPriorityRequest = async (item) => {
    // eslint-disable-next-line no-return-await
    return await new Promise((success, fail) => {
        console.log(item)
        const instance = axios.create({
            method: 'delete',
            baseURL: apiUrl,// process.env.REACT_APP_BASE_URL,
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getAccessToken()
            },
        });

        instance.delete('/priorities/' + item.priorityId,
            { crossdomain: true })
            .then(response => {
                if (response.data) {
                    if (response.data.priority === "fail") {
                        fail(response.data);
                    }
                    else if (response.data.priority === "success" || response.data.priority === "ok") {
                        success(response.data);
                    }
                    else {
                        console.log('Inconsistent behaviour', response.data.priority);
                    }
                }
            }).catch((error) => {
                console.log(error);
            });
    })
        .then((response) => response)
        .catch((error) => error);
};

function* deleteIssuesPriorityItem({ payload }) {
    // console.log(payload);
    try {
        const response = yield call(deleteIssuesPriorityRequest, payload);
        console.log('Response :', response, response.priority === "fail")
        if (response.priority !== "fail") {
            yield put(deleteIssuesPriorityItemSuccess(response));
        }
        else {
            yield put(deleteIssuesPriorityItemError(response));
        }
    } catch (error) {
        yield put(addIssuesPriorityItemError(error));
    }
}


export function* watchFetchItem() {
    yield takeEvery(ISSUES_PRIORITIES_FETCH_ITEM, getIssuesPriorityItem);
}

export function* watchAddItem() {
    yield takeEvery(ISSUES_PRIORITIES_ADD_ITEM, addIssuesPriorityItem);
}
export function* watchDeleteItem() {
    yield takeEvery(ISSUES_PRIORITIES_DELETE_ITEM, deleteIssuesPriorityItem);
}


export default function* rootSaga() {
    yield all([
        fork(watchAddItem),
        fork(watchFetchItem),
        fork(watchDeleteItem),
    ]);
}
