export const EXPERTS_FETCH_ITEM = 'EXPERTS_FETCH_ITEM';
export const EXPERTS_FETCH_ITEM_SUCCESS = 'EXPERTS_FETCH_ITEM_SUCCESS';
export const EXPERTS_FETCH_ITEM_ERROR = 'EXPERTS_FETCH_ITEM_ERROR';

export const EXPERTS_ADD_ITEM = 'EXPERTS_ADD_ITEM';
export const EXPERTS_ADD_ITEM_SUCCESS = 'EXPERTS_ADD_ITEM_SUCCESS';
export const EXPERTS_ADD_ITEM_ERROR = 'EXPERTS_ADD_ITEM_ERROR';

export const EXPERTS_SAVE_ITEM = 'EXPERTS_SAVE_ITEM';
export const EXPERTS_SAVE_ITEM_SUCCESS = 'EXPERTS_SAVE_ITEM_SUCCESS';
export const EXPERTS_SAVE_ITEM_ERROR = 'EXPERTS_SAVE_ITEM_ERROR';

export const EXPERTS_DELETE_ITEM = 'EXPERTS_DELETE_ITEM';
export const EXPERTS_DELETE_ITEM_SUCCESS = 'EXPERTS_DELETE_ITEM_SUCCESS';
export const EXPERTS_DELETE_ITEM_ERROR = 'EXPERTS_DELETE_ITEM_ERROR';

export const EXPERTS_SELECTED_ITEMS_CHANGE = 'EXPERTS_SELECTED_ITEMS_CHANGE';

