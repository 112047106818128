export const TAGS_FETCH_ITEM = 'TAGS_FETCH_ITEM';
export const TAGS_FETCH_ITEM_SUCCESS = 'TAGS_FETCH_ITEM_SUCCESS';
export const TAGS_FETCH_ITEM_ERROR = 'TAGS_FETCH_ITEM_ERROR';

export const TAGS_ADD_ITEM = 'TAGS_ADD_ITEM';
export const TAGS_ADD_ITEM_SUCCESS = 'TAGS_ADD_ITEM_SUCCESS';
export const TAGS_ADD_ITEM_ERROR = 'TAGS_ADD_ITEM_ERROR';

export const TAGS_DELETE_ITEM = 'TAGS_DELETE_ITEM';
export const TAGS_DELETE_ITEM_SUCCESS = 'TAGS_DELETE_ITEM_SUCCESS';
export const TAGS_DELETE_ITEM_ERROR = 'TAGS_DELETE_ITEM_ERROR';
