import {
    PROJECTS_DELETE_ITEMS,
    PROJECTS_DELETE_ITEMS_ERROR, PROJECTS_DELETE_ITEMS_SUCCESS, PROJECTS_GET_LIST,
    PROJECTS_GET_LIST_ERROR,
    PROJECTS_GET_LIST_SEARCH, PROJECTS_GET_LIST_SUCCESS,
    PROJECTS_GET_LIST_WITH_FILTER,
    PROJECTS_GET_LIST_WITH_ORDER,
    PROJECTS_SELECTED_ITEMS_CHANGE
} from './actions_def';


const INIT_STATE = {
    allProjectsItems: [],
    projects: null,
    errors: [],
    filter: null,
    searchKeyword: '',
    orderColumn: null,
    isLoading: false,
    isLoaded: false,
    saving: false,
    selectedItems: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case PROJECTS_GET_LIST:
            return {
                ...state,
                isLoaded: false,
                isLoading: true
            };

        case PROJECTS_GET_LIST_SUCCESS:
            // console.log(action);
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                allProjectsItems: action.payload,
                projects: action.payload,
            };

        case PROJECTS_GET_LIST_ERROR:
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                error: action.payload
            };

        case PROJECTS_GET_LIST_WITH_FILTER:
            if (action.payload.column === '' || action.payload.value === '') {
                return {
                    ...state,
                    isLoaded: true,
                    isLoading: false,
                    projects: state.allProjectsItems,
                    filter: null,
                };
            }
            // eslint-disable-next-line no-case-declarations
            const filteredItems = state.allProjectsItems.filter(
                (item) => item[action.payload.column] === action.payload.value
            );
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                projects: filteredItems,
                filter: {
                    column: action.payload.column,
                    value: action.payload.value,
                },
            };

        case PROJECTS_GET_LIST_WITH_ORDER:
            if (action.payload === '') {
                return {
                    ...state,
                    isLoaded: true,
                    isLoading: false,
                    projects: state.projects,
                    orderColumn: null,
                };
            }
            // eslint-disable-next-line no-case-declarations
            const sortedItems = state.projects.sort((a, b) => {
                if (a[action.payload] < b[action.payload]) return -1;
                if (a[action.payload] > b[action.payload]) return 1;
                return 0;
            });
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                projects: sortedItems,
                orderColumn: state.orderColumns.find(
                    (x) => x.column === action.payload
                ),
            };

        case PROJECTS_GET_LIST_SEARCH:
            if (action.payload === '') {
                return { ...state, projects: state.allProjectsItems };
            }
            // eslint-disable-next-line no-case-declarations
            const keyword = action.payload.toLowerCase();
            // eslint-disable-next-line no-case-declarations
            const searchItems = state.allProjectsItems.filter(
                (item) =>
                    item.title.toLowerCase().indexOf(keyword) > -1 ||
                    item.detail.toLowerCase().indexOf(keyword) > -1 ||
                    item.status.toLowerCase().indexOf(keyword) > -1 ||
                    item.category.toLowerCase().indexOf(keyword) > -1 ||
                    item.label.toLowerCase().indexOf(keyword) > -1
            );
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                projects: searchItems,
                searchKeyword: action.payload,
            };

        case PROJECTS_SELECTED_ITEMS_CHANGE:
            return { ...state, isLoaded: true, selectedItems: action.payload };

        case PROJECTS_DELETE_ITEMS:
            return {
                ...state,
                isLoading: true,
                saving: true
            };

        case PROJECTS_DELETE_ITEMS_SUCCESS:

            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                saving: false,
                saved: true,
                errors: [],
                allProjectsItems: state.allProjectsItems.filter(item => item.id !== action.payload.rfc_id),
                projects: state.projects.filter(item => item.id !== action.payload.rfc_id),
            };

        case PROJECTS_DELETE_ITEMS_ERROR:
            // console.log("Failed", action.payload)
            return {
                ...state,
                isLoaded: true,
                isLoading: false,
                saving: false,
                saved: true,
                errors: action.payload.errors.map(error => (
                    {
                        field: error.field,
                        error_code: error.code
                    }
                ))
            };

        default:
            return { ...state };
    }
};
